import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { REACT_APP_BACKEND_URL } from '../../constants';
import Button from '@mui/material/Button';

import { toast } from 'react-toastify';
import CircularProgress from '@mui/material/CircularProgress';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import NewModule from './new_module';
import { DataGrid, GridColDef, GridRenderCellParams, GridToolbar } from '@mui/x-data-grid';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import ScenarioCard from '../scenarioCard';
import AddIcon from '@mui/icons-material/Add';
import DeleteWithConfirmation from '../DeleteWithConfirmation';



interface ProductScenarioProps {
    productID: string;
}


const ProductModules: React.FC<ProductScenarioProps> = ({ productID }) => {
    const [fetchingModules, setFetchingModules] = useState(false);
    const [modules, setModules] = useState<any[]>([]);
    const [singleModule, setSingleModule] = useState<any>([]);
    const [fetchingSingleModule, setFetchingSingleModule] = useState(false);
    const [open, setOpen] = useState(false);
    const [selectedModuleID, setSelectedModuleID] = useState<string>('');
    const handleOpen = () => setOpen(true);
    const [breadcrumbs, setBreadcrumbs] = useState<string[]>(['All Modules']);
    const handleClose = () => setOpen(false);
    const [isRoot, setIsRoot] = useState(true);
    const [moduleLoading, setModuleLoading] = useState<Record<string, boolean>>({});
    const [isModuleScenarioDeleting, setIsModuleScenarioDeleting] = useState<Record<string, boolean>>({});
    const Loader = () => {
        return (
            <div style={{ minHeight: "90vh" }} className="flex justify-center items-center">
                <CircularProgress />
            </div>
        );
    }
    const fetchModules = async () => {
        try {
            setFetchingModules(true);

            const response = await axios.get(`${REACT_APP_BACKEND_URL}/organization/${localStorage.getItem('org_id')}/product/${productID}/module`, {
                headers: { 'Authorization': `Bearer ${localStorage.getItem('google_access_token')}` }
            });
            setModules(response.data.modules)
        } catch (error) {
            console.error('Error fetching modules:', error);
            toast.error('Something went wrong!\n' + error)
        }
        setFetchingModules(false);
    }
    const handleModuleDelete = (module_id: string) => {
        setModuleLoading({ ...moduleLoading, [module_id]: true })
        try{
            axios.delete(`${REACT_APP_BACKEND_URL}/organization/${localStorage.getItem('org_id')}/product/${productID}/module/${module_id}`, {
                headers: { 'Authorization': `Bearer ${localStorage.getItem('google_access_token')}` }
            });
            setModules(modules.filter(module => module.id !== module_id));
            toast.success('Module deleted successfully');
        }
        catch(error){
            console.error('Error deleting module:', error);
            toast.error('Something went wrong!\n' + error)
        }
        setModuleLoading({ ...moduleLoading, [module_id]: false })
    }
    const handleModuleScenarioDelete = async (scenario_id: string) => {
        setIsModuleScenarioDeleting({...isModuleScenarioDeleting, [scenario_id]: true})
        try{
            await axios.post(`${REACT_APP_BACKEND_URL}/organization/${localStorage.getItem('org_id')}/product/${productID}/module/${selectedModuleID}/delete_module_scenarios`, {
                module_scenario_ids: [scenario_id]
            },{
                headers: { 'Authorization': `Bearer ${localStorage.getItem('google_access_token')}` }
            });
            setSingleModule(singleModule.filter((scenario: any) => scenario.id !== scenario_id));
            toast.success('Scenario deleted successfully');
        }
        catch(error){
            console.error('Error deleting scenario:', error);
            toast.error('Something went wrong!\n' + error)
        }
        setIsModuleScenarioDeleting({...isModuleScenarioDeleting, [scenario_id]: false})
    }
    useEffect(() => {
        fetchModules();
    }, []);
    const fetchSingleModule = async (moduleId: string) => {
        setFetchingSingleModule(true);
        try {
            setFetchingModules(true);
            const response = await axios.get(`${REACT_APP_BACKEND_URL}/organization/${localStorage.getItem('org_id')}/product/${productID}/module/${moduleId}`, {
                headers: { 'Authorization': `Bearer ${localStorage.getItem('google_access_token')}` }
            });
            setSingleModule(response.data.scenarios.map((scenario: any) => {
                return { ...scenario, scenarioContext: scenario.previous_interaction.context }
            }));
            setSelectedModuleID(moduleId);
        } catch (error) {
            console.error('Error fetching single module:', error);
            toast.error('Something went wrong!\n' + error)
        }
        setFetchingSingleModule(false);
    }

    const addModule = (newModule: any[]) => {
        setModules([...modules, newModule]);
    }
    const columns: GridColDef[] = [
        {
            field: 'name', flex: 0.8, resizable: true,
            disableColumnMenu: true,
            renderHeader: () => (
                <strong>Name</strong>
            ),
        },
        {
            field: 'description', flex: 1.2, resizable: true,
            disableColumnMenu: true,
            renderHeader: () => (
                <strong>Description</strong>
            ),
        },
        {
            field: 'details',
            flex: 0.4,
            sortable: false,
            resizable: true,
            disableColumnMenu: true,
            renderCell: (params: GridRenderCellParams) => (
                <div style={{display:"flex"}}>
                    <IconButton onClick={() => {
                        const crumbs = [...breadcrumbs, params.row.name];
                        setBreadcrumbs(crumbs);
                        fetchSingleModule(params.row.id);
                        setIsRoot(false);

                    }}>
                        <ChevronRightIcon />
                    </IconButton>
                </div>
            ),
            renderHeader: () => (
                <strong>Scenarios</strong>
            ),
        },
        {
            field: 'delete',
            flex: 0.4,
            sortable: false,
            resizable: true,
            disableColumnMenu: true,
            renderCell: (params: GridRenderCellParams) => (
                <DeleteWithConfirmation 
                    subject='Delete Module'
                    description='Are you sure you want to delete this module?'
                    handleDelete={() => {handleModuleDelete(params.row.id)}}
                />
            ),
            renderHeader: () => (
                <strong>Delete</strong>
            ),
        },
    ];
    const moduleColumns: GridColDef[] = [
        {
            field: 'title', flex: 0.6, renderHeader: () => (
                <strong>Title</strong>
            ), resizable: true,
            disableColumnMenu: true
        },
        {
            field: 'call_type', flex: 0.4, renderHeader: () => (
                <strong>Call type</strong>
            ), resizable: true,
            disableColumnMenu: true
        },
        {
            field: 'scenarioContext', flex: 2, renderHeader: () => (
                <strong>Scenario Context</strong>
            ), resizable: true,
            disableColumnMenu: true
        },
        {
            field: 'details',
            headerName: 'Details',
            flex: 0.3,
            sortable: false,
            renderCell: (params: GridRenderCellParams) => (
                <div style={{display:"flex"}}>
                    <ScenarioCard data={params.row} showCard={false} moduleID={selectedModuleID} allowEdit={false} />
                    {/* #Rohan */}
                </div>
                
            ),
            renderHeader: () => (
                <strong>Details</strong>
            ), resizable: true,
            disableColumnMenu: true
        },
        {
            field: 'delete',
            headerName: 'Delete',
            flex: 0.3,
            sortable: false,
            renderCell: (params: GridRenderCellParams) => (
                <>
                    {
                        isModuleScenarioDeleting[params.row.id] ? <CircularProgress /> : <DeleteWithConfirmation 
                            subject='Delete Scenario'
                            description='Are you sure you want to this scenario from this module?'
                            handleDelete={() => {handleModuleScenarioDelete(params.row.id)}}
                        />
                    }
                </>
            ),
            renderHeader: () => (
                <strong>Delete</strong>
            ), resizable: true,
            disableColumnMenu: true
        },

    ];
    const handleAddScenarios = async (scenario_ids: string[]) => {
        try{
            await axios.post(`${REACT_APP_BACKEND_URL}/organization/${localStorage.getItem('org_id')}/product/${productID}/module/${selectedModuleID}/add_module_scenarios`, {
                scenario_ids
            },{
                headers: { 'Authorization': `Bearer ${localStorage.getItem('google_access_token')}` }
            });
            await fetchSingleModule(selectedModuleID);
            toast.success('Scenarios added successfully');
            return {success: true}
        }
        catch(error){
            console.error('Error adding scenarios:', error);
            toast.error('Something went wrong!\n' + error);
            return {success: false}
        }
    }
    useEffect(() => {
        if (breadcrumbs.length === 1) {
            setIsRoot(true);
        }
    }, [breadcrumbs])
    return (
        <div className="flex flex-col items-center">
            {(fetchingModules && modules.length === 0) ? <Loader /> :
                (
                    <>

                        {
                            modules.length === 0 && !fetchingModules && (
                                <div className="flex justify-center items-center">
                                    <div className="text-xl absolute" style={{ top: "50%", color: "gray" }}>No modules found for this product</div>
                                </div>
                            )
                        }
                        {
                            !isRoot && singleModule.length === 0 && !fetchingModules && (
                                <div className="flex justify-center items-center">
                                    <div className="text-xl absolute" style={{ top: "50%", color: "gray" }}>No scenarios found for this module</div>
                                </div>
                            )
                        }
                        <div style={{ display: "flex", width: "100%", justifyContent: "space-between", marginBottom:"10px", marginTop:"10px" }}>
                            <Breadcrumbs separator="/" >
                                {
                                    breadcrumbs.map((breadcrumb, index) => {
                                        return (
                                            <Typography variant='h5' style={{ fontWeight: (index==breadcrumbs.length-1)?"600":"400", cursor: "pointer" }} onClick={(e) => {
                                                e.preventDefault();
                                                setBreadcrumbs(breadcrumbs.slice(0, index + 1));
                                            }} key={index}>{breadcrumb}</Typography>
                                        );
                                    })
                                }

                            </Breadcrumbs>
                            <div className="flex relative w-full justify-end items-center" style={{maxWidth:"20%"}}>
                                <Button variant='contained' style={{ background: "#393E46", borderRadius: "100px" }} onClick={handleOpen}>
                                    {
                                        isRoot ? (
                                            <><AddIcon /> &nbsp;Add Module</>
                                        ) : (
                                            <><AddIcon /> &nbsp;Add Scenarios</>
                                        )
                                    }
                                </Button>
                            </div>
                        </div>
                        {
                            fetchingSingleModule ? <Loader /> :
                                <>
                                {(isRoot && modules.length > 0) || (!isRoot && singleModule.length > 0) ? (
                                    <div style={{ width: '100%', marginTop: "10px" }}>
                                        <DataGrid

                                            rows={isRoot ? modules : singleModule}
                                            columns={isRoot ? columns : moduleColumns}
                                            initialState={{
                                                pagination: {
                                                    paginationModel: { page: 0, pageSize: 5 },
                                                },
                                            }}
                                            rowSelection={false}
                                            style={{ backgroundColor: "white", textAlign: "center" }}
                                            // columnh
                                            pageSizeOptions={[5, 10, 20, 50]}
                                            components={{ Toolbar: GridToolbar }}
                                            slotProps={{
                                                toolbar: {
                                                  showQuickFilter: true,
                                                },
                                              }}
                                            disableDensitySelector
                                            disableColumnSelector
                                            disableColumnFilter
                                        />
                                    </div>)
                                    : <></>}
                                </>
                        }
                        <Modal
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box sx={{ position: 'absolute', top: "50%", left: "50%", transform: 'translate(-50%, -50%)', width: 800, maxHeight: "90vh", overflow: "scroll", bgcolor: 'background.paper', border: 'none', boxShadow: 24, p: 4, borderRadius: 5, outline: null }}>
                                <NewModule 
                                    productId={productID} 
                                    closeModal={handleClose} 
                                    addModule={addModule} 
                                    shouldAddScenarios={!isRoot} 
                                    existingScenarioIds={isRoot ? [] : singleModule.map((scenario: any) => scenario.base_scenario_id || scenario.id)} 
                                    handleAddScenarios={handleAddScenarios}
                                />
                            </Box>
                        </Modal>
                    </>
                )
            }
        </div>
    );
};

export default ProductModules;
