import { useState, useEffect, useRef } from 'react';
import { createClient, LiveTranscriptionEvents } from "@deepgram/sdk";
import { toast } from 'react-toastify';

const useSpeechToText = (canRepSpeak: boolean,  productKeywords: string[] = []) => {
  const [transcript, setTranscript] = useState<string>('');
  const [isSpaceBarPressed, setIsSpaceBarPressed] = useState<boolean>(false);
  const [isDGLastMessageEmpty, setIsDGLastMessageEmpty] = useState<boolean>(false);
  const [isListening, setIsListening] = useState<boolean|null>(null);
  const [isWebsocketConnected, setIsWebsocketConnected] = useState<boolean>(false)
  const [deepgramWS, setDeepgramWS] = useState<any>(null)
  const mediaRecorderRef = useRef<any>(null)
  const deepgramKeepAliveInterval = useRef<any>(null)
  const canRepSpeakLocalRef = useRef<boolean>(canRepSpeak)
  const stopListeningInterval = useRef<any>(null)

  useEffect(() => {
    console.log("can rep speak use effect value", canRepSpeak)
    canRepSpeakLocalRef.current = canRepSpeak
  }, [canRepSpeak])

  const stopSpeechToText = () => {
    deepgramWS?.finish()
    setIsListening(false)
    setIsSpaceBarPressed(false)
    mediaRecorderRef.current?.stop()
    mediaRecorderRef.current?.stream.getTracks().forEach((track: any) => track.stop());
    console.log("state of mediaRecorderRef is", mediaRecorderRef.current?.state)
    clearInterval(deepgramKeepAliveInterval.current)
  }

  useEffect(() => {
    // TODO: This useEffect is being called twice. Need to fix this.
    const deepgram = createClient(localStorage.getItem('DEEPGRAM_TEMPORARY_API_KEY') || '');
    const dgConnection = deepgram.listen.live({ model: "nova-2", punctuate: true, keywords: productKeywords});

    dgConnection.on(LiveTranscriptionEvents.Open, () => {
      setIsWebsocketConnected(true)
      console.log("Connected to Deepgram WS")
      dgConnection.on(LiveTranscriptionEvents.Transcript, (data:any) => {
        // console.log("live data", data.channel.alternatives[0].transcript);
        if (data?.channel?.alternatives?.[0]?.words.length === 0) setIsDGLastMessageEmpty(true)
        else setIsDGLastMessageEmpty(false)

        clearInterval(stopListeningInterval.current)

        if(data?.channel?.alternatives?.[0]?.transcript?.trim()?.length > 0) {
          console.log("setting transcript. Adding: ", data?.channel?.alternatives?.[0]?.transcript?.trim())
          setTranscript(prevTranscript => (prevTranscript + " " + data.channel.alternatives[0].transcript.trim()).trim())
        }
        if (data?.speech_final) setIsDGLastMessageEmpty(true)
      });
    });
    dgConnection.on(LiveTranscriptionEvents.Error, (error: any) => {
      console.log("Deepgram WS error", error)
      setIsWebsocketConnected(false)
      toast.error("Session expired. Start a new simulation again.", {autoClose: false})
    });
    navigator.mediaDevices.getUserMedia({ audio: true }).then((stream: any) => {
      const mediaRecorder = new MediaRecorder(stream, {mimeType: 'audio/webm'})
      mediaRecorder.addEventListener('dataavailable', event => {
        dgConnection.send(event.data)
      })
      mediaRecorderRef.current = mediaRecorder
    })
    setDeepgramWS(dgConnection)

    return () => {
      stopSpeechToText()
    }
  }, []);

  useEffect(() => {

    if (isWebsocketConnected){
      deepgramKeepAliveInterval.current = setInterval(() => {
        deepgramWS.keepAlive()
      }, 10000)
      return () => {
        clearInterval(deepgramKeepAliveInterval.current)
      }
    }
  }, [isWebsocketConnected])


  const startListening = () => {
      console.log("can rep speak", canRepSpeakLocalRef.current)
      if (!canRepSpeakLocalRef.current) return
      setIsSpaceBarPressed(true)
      setIsListening(true)
      if(mediaRecorderRef.current.state === "paused")
        mediaRecorderRef.current?.resume()
      else
        mediaRecorderRef.current?.start(250)
  };

  const stopListening = () => {
    if(!isSpaceBarPressed) return;
    setIsSpaceBarPressed(false)
    stopListeningInterval.current = setTimeout(() => {
      console.log("stopping listening from stopListeningInterval timeout.")
      setIsDGLastMessageEmpty(true)
    }, 1000)
  };

  useEffect(() => {
    console.log("is space bar pressed", isSpaceBarPressed, "is dg last message empty", isDGLastMessageEmpty)
    if (!isSpaceBarPressed && isDGLastMessageEmpty) {
        setIsListening(false)
        if(mediaRecorderRef.current.state !== "inactive")
          mediaRecorderRef.current?.pause()
        setIsDGLastMessageEmpty(false)
    }
  }, [isSpaceBarPressed, isDGLastMessageEmpty])

  return {
    isListening,
    transcript,
    setTranscript,
    isWebsocketConnected,
    startListening,
    stopListening,
    stopSpeechToText
  };
};

export default useSpeechToText;
