import { createSlice } from '@reduxjs/toolkit'

export const productSlice = createSlice({
  name: 'products',
  initialState: {
    value: {},
    navbarProducts: []
  },
  reducers: {
    setAllProducts: (state, action) => {
        state.value = action.payload
    },
    setProduct: (state: any, action: any) => {
        state.value[action.payload.id] = action.payload
    },
    setNavbarProducts: (state: any, action: any) => {
        state.navbarProducts = action.payload
    },
    addNavbarProduct: (state: any, action: any) => {
        state.navbarProducts.push(action.payload)
    },
  },
})

// Action creators are generated for each case reducer function
export const { setAllProducts, setProduct, setNavbarProducts, addNavbarProduct } = productSlice.actions

export default productSlice.reducer