import axios from "axios";
import { REACT_APP_BACKEND_URL } from "../constants";
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {toast} from 'react-toastify'

export const handleLogout = () => {
    localStorage.removeItem('google_access_token')
    localStorage.removeItem('google_refresh_token')
    localStorage.removeItem('google_token_expires_at')
    localStorage.removeItem('userProfile')
    localStorage.removeItem('is_manager')
    window.location.href = "/signin"
};

const isTokenValid = ({
  expires_at,
  minimumExpiryTimeInMinutes
}: {
  expires_at: number
  minimumExpiryTimeInMinutes: number
}): boolean => {
  try {

    const currentTimestamp = Date.now()
    const expiryTimeInMs = minimumExpiryTimeInMinutes * 60 * 1000
    console.log("Access token will expire in ", expires_at - currentTimestamp, "miliseconds")
    if (currentTimestamp >= expires_at) {
      // Token has already expired
      return false
    }
    if (expires_at - currentTimestamp <= expiryTimeInMs) {
      // Token is expiring within the specified time frame
      return false
    }
    // Token is valid and not expiring soon
    return true
  } catch (error) {
    // An error occurred while decoding the token
    return false
  }
}

export const getValidAccessToken = async () => {
  const accessToken = localStorage.getItem("google_access_token")
  const refreshToken = localStorage.getItem("google_refresh_token")
  const expires_at = localStorage.getItem("google_token_expires_at")

  if (!accessToken || !refreshToken || !expires_at) {
    console.log("Access token, refresh token or expires_at is missing.")
    return "missing"
  }

  if (!isTokenValid({ expires_at: parseInt(expires_at), minimumExpiryTimeInMinutes: 30 })) {
    console.log(
      "Access token is invalid or expiring soon. Attempting to refresh... 🔄"
    )

    // * to get a new access token we need to make a call to the refresh endpoint
    const data ={
      refresh_token: refreshToken
    }
    try{
        const response = await axios.post(`${REACT_APP_BACKEND_URL}/auth/google/refresh_token`, data, {
          headers: {
            "Content-Type": "application/json"
            }
          })
        // * if status is not 200, then we have an error
        if (response.status !== 200) {
          // * we need to log out user
          handleLogout()
          return null
        }
        if(response.status !== 200) {
            console.log("Error in refresh token API call")
            return null
        }
        const json_response = await response.data
        localStorage.setItem("google_access_token", json_response.access_token)
        localStorage.setItem('google_token_expires_at', (Date.now() + json_response.expires_in * 1000).toString())
        localStorage.setItem('userProfile', JSON.stringify(response.data.user_profile))
        localStorage.setItem('is_manager', response.data.user_profile.role.toLowerCase() === "vp" ? 'true' : 'false')
        console.log("Access token refreshed. ✅")
    }
    catch(error){
      console.log(error)
      handleLogout()
      return null
    }
  } else {
    console.log("Access token is valid. No need to refresh. ✅")
  }

  return localStorage.getItem("google_access_token")
}


const useAuth = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const navigate = useNavigate();
  useEffect(() => {
    const handleAuth = async () => {
      setLoading(true)
      const token = await getValidAccessToken()
      if (token!=="missing" && !token) {
        toast.error("Invalid Credentials. Login again")
        navigate('/signin');
      }
      else if(token === "missing") navigate('/signin')
      setLoading(false)
    }
    handleAuth()
  }, [navigate]);
  return loading;
};

export default useAuth;