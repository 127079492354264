import React, { useState, useEffect, useRef } from 'react'
// import NoPhotographyIcon from '@mui/icons-material/NoPhotography';
// import CameraAltIcon from '@mui/icons-material/CameraAlt';
import { MAX_SIMULATION_DURATION_IN_MINUTES, REACT_APP_BACKEND_URL } from '../constants';
import { toast } from 'react-toastify';
import CircularProgress from '@mui/material/CircularProgress';
import useLlmWebsocket from '../utils/use_llm_websocket';
import useSpeechToText from '../utils/use_speech_to_text';
import ObjectionEvaluationComponent from '../components/objection/ObjectionEvaluationComponent';
import { useDispatch, useSelector } from 'react-redux';
import AudioPlayer from '../components/AudioPlayer';
import { nanoid } from 'nanoid';
import { useParams } from 'react-router-dom';
import { Button, IconButton } from '@mui/material';
import Webcam from 'react-webcam';
import VideocamIcon from '@mui/icons-material/Videocam';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import VideocamOffIcon from '@mui/icons-material/VideocamOff';
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper} from '@mui/material';
import { addSimulationMessage } from '../redux/currentSimulationSlice';
import CallEndIcon from '@mui/icons-material/CallEnd';
import Avatar from '@mui/material/Avatar';
import { stringAvatar } from '../components/navbar/navbar';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import axios from "axios"

export const TranscriptPanel = ({messages, rep_name}: {messages: any[], rep_name: string}) => {
  const messageStyle={
    borderRadius: "10px",
    padding: "10px",
    maxWidth: "80%",
    width: "fit-content",
    wordWrap: "break-word" as const,
  }
  return (
    <div className="bg-white rounded-lg shadow-md mr-5 h-full flex flex-col items-center justify-between p-3" style={{borderRadius: "20px"}}>
      {/* <Typography variant="h5" className="text-center mb-5 w-full">Transcript</Typography> */}
      <div style={{height: "80vh", width: "100%", marginLeft: "0px", overflowY: "scroll", border: "3px solid #393E46", padding: "10px", borderRadius: "20px", display: "flex", flexDirection: "column-reverse"}}>
      {
        messages === undefined || messages?.length === 0 ? (
          <div style={{height: "80vh", color: "gray", display: "flex", alignItems: "center", justifyContent: "center", fontSize: "20px"}}>
            No messages have been exchanged yet
          </div>
        ) : (
          <>
            {
              messages?.slice(0).reverse().map((message: any, index: number) => (
                <div key={index} className="flex flex-col">
                  {
                    message?.role === "user" ? (
                      <div style={{display: "flex", justifyContent: "end", marginTop: "10px"}}>
                        <div style={{...messageStyle, backgroundColor: '#E4E7EB', marginRight: "5px"}}>
                          {message?.content}
                        </div>
                        <Avatar {...stringAvatar(rep_name, '25')} />
                      </div>
                    ) : (
                      <div style={{display: "flex", justifyContent: "start", marginTop: "10px"}}>
                        <Avatar src="https://t3.ftcdn.net/jpg/02/14/39/38/240_F_214393844_8TuJzbiHWQT8tRqamM2WZYg2M1XM2fh0.jpg" sx={{border: "2px solid #393E46"}} />
                        <div style={{...messageStyle, backgroundColor: '#BEDBFE', marginLeft: "5px"}}>
                          {message?.content}
                        </div>
                      </div>
                    )
                  }
                </div>
              ))
            }
          </>
        )
      }
      </div>
    </div>
  )

}

export const ScenarioDetails = ({scenarioDetails}: {scenarioDetails: any}) => {
  return(
    <div className="bg-white p-6 rounded-lg shadow-md flex justify-center space-x-6 mt-6" style={{borderRadius: "20px", overflowY: "scroll"}}>
      <TableContainer component={Paper} style={{border: "2px solid #393E46"}}>
        <Table aria-label="simple table">
            <TableHead style={{ background: "#393E46" }}>
            <TableRow>
                <TableCell align="center" style={{color: "white", width: "25%"}}><strong>Goal(s)</strong></TableCell>
                <TableCell align="center" style={{color: "white", width: "25%"}}><strong>Topics to cover</strong></TableCell>
                {/* <TableCell align="center" style={{color: "white"}}><strong>Call Type</strong></TableCell> */}
                {/* <TableCell align="center" style={{color: "white"}}><strong>Product</strong></TableCell> */}
                {/* <TableCell align="center" style={{color: "white"}}><strong>Goals</strong></TableCell>
                <TableCell align="center" style={{color: "white"}}><strong>Topics</strong></TableCell> */}
                <TableCell align="center" style={{color: "white"}}><strong>About the Customer</strong></TableCell>
                <TableCell align="center" style={{color: "white"}}><strong>Scenario Context</strong></TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
                <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 }, height: "50px", width: "100px !important"}}
                >
                    <TableCell align="center">
                      <ul className="li-disc text-left">
                        {scenarioDetails?.agenda?.goals?.map((goal: string, index: number) => (
                          <li key={index} className="ml-2 mt-1">{goal}</li>
                        ))}
                      </ul>
                    </TableCell>
                    <TableCell align="center">
                      <ul className="li-disc text-left">
                        {scenarioDetails?.agenda?.topics?.map((topic: string, index: number) => (
                          <li key={index} className="ml-2 mt-1">{topic}</li>
                        ))}
                      </ul>
                    </TableCell>
                    {/* <TableCell align="center">{scenarioDetails?.call_type}</TableCell >
                    <TableCell align="center">{scenarioDetails?.product_name}</TableCell> */}
                    {/* <TableCell align="center"></TableCell> */}
                    <TableCell align="center">You are calling {scenarioDetails?.customer_metadata.name}, {scenarioDetails?.customer_metadata.job_title} at {scenarioDetails?.customer_metadata.organization} (a {scenarioDetails?.customer_metadata.org_industry} company).</TableCell>
                    <TableCell align="center">{scenarioDetails?.previous_interaction?.context}</TableCell>
                </TableRow>
            </TableBody>
        </Table>
    </TableContainer>
    </div>
  )
}
function Simulation() {
  const [time, setTime] = useState(0);
  const [userMessageStartTimeInMs, setUserMessageStartTimeInMs] = useState(0)
  const [simulationStartTime, setSimulationStartTime] = useState(Date.now())
  const [hasSimulationEnded, setHasSimulationEnded] = useState(false)
  const [canRepSpeak, setCanRepSpeak] = useState<boolean>(false)
  const [isVideoOn, setIsVideoOn] = useState<boolean>(true)
  const [videoPermissionError, setVideoPermissionError] = useState<boolean>(false)
  const [nextSimulationIn, setNextSimulationIn] = useState<number>(5)
  const [isAutoPlayCancelled, setIsAutoPlayCancelled] = useState<boolean>(false)
  const simulationDetails = useSelector((state: any) => state.simulation.value)
  const { simulationId } = useParams()
  const simulationTimeIntervalRef = useRef<any>(null)
  const nextScenarioTimeIntervalRef = useRef<any>(null)

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const {
    mediaQueue,
    evaluationDetails,
    isWebsocketConnected: isBackendWebsocketConnected,
    handleMessageSend,
    isAwaitingCustomerReply
  } = useLlmWebsocket();

  const {
    isListening,
    transcript,
    setTranscript,
    isWebsocketConnected: isSTTWebsocketConnected,
    startListening,
    stopListening,
    stopSpeechToText
  } = useSpeechToText(canRepSpeak, simulationDetails.productKeywords)

  const handleSimulationEnd = () => {
    clearInterval(simulationTimeIntervalRef.current)
    toast.success('Simulation completed!')
    stopSpeechToText()
    if(simulationDetails.isTraining) { 
      axios.post(`${REACT_APP_BACKEND_URL}/organization/${localStorage.getItem('org_id')}/product/${simulationDetails.productId}/module/${simulationDetails.moduleId}/scenario/${simulationDetails.scenarioId}/simulation/${simulationId}/evaluate`, {
        rep_email: JSON.parse(localStorage.getItem('userProfile') || '{}')?.email
      }, {
        headers: { 'Authorization': `Bearer ${localStorage.getItem('google_access_token')}` }
      })
        .then(() => {
          nextScenarioTimeIntervalRef.current = setInterval(() => {
            setNextSimulationIn((prev) => prev - 1)
          }, 1000)
        })
    }
    else{
      const data = {
        simulation_id: simulationId,
        product_id: simulationDetails.productId,
        org_id: localStorage.getItem('org_id'),
        module_id: simulationDetails.moduleId,
        scenario_id: simulationDetails.scenarioId,
        rep_email: JSON.parse(localStorage.getItem('userProfile') || '{}')?.email || "unknown"
      }
      handleMessageSend("EVALUATION", data)
    }
    setHasSimulationEnded(true)
  }

  useEffect(() => {
    if (nextSimulationIn <= 0) {
      clearInterval(nextScenarioTimeIntervalRef.current)
      if(!simulationDetails.nextScenario){
        const email = JSON.parse(localStorage.getItem('userProfile') || '{}').email
        const url = `/module/${simulationDetails.moduleId}/results` + (email ? `?rep_email=${email}` : '')
        navigate(url)
      }
      else navigate(`/scenario/${simulationDetails.nextScenario.id}`)
    }
  }, [nextSimulationIn])

  const handleSpeechSubmit = (is_first_message = false) => {
    const userMessageEndTimeInMs = Date.now() - simulationStartTime
    const data = {
      is_first_message: is_first_message,
      message: transcript,
      simulation_id: simulationId,
      request_id: nanoid(),
      product_id: simulationDetails.productId,
      org_id: localStorage.getItem('org_id'),
      module_id: simulationDetails.moduleId,
      scenario_id: simulationDetails.scenarioId,
      user_message_start_time_in_ms: userMessageStartTimeInMs,
      user_message_end_time_in_ms: userMessageEndTimeInMs,
      rep_email: JSON.parse(localStorage.getItem('userProfile') || '{}')?.email || "unknown"
    }
    handleMessageSend("SIMULATION", data)
    console.log("sending message", transcript)
    if (!is_first_message)
      dispatch(addSimulationMessage({"role": "user", "content": transcript}))
  }

  useEffect(() => {
    if(isBackendWebsocketConnected && isSTTWebsocketConnected) {
      handleSpeechSubmit(true)
    }
  }, [isBackendWebsocketConnected, isSTTWebsocketConnected])

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.code === 'Space') {
        if (!isListening && !isAwaitingCustomerReply) {
          startListening()
          setUserMessageStartTimeInMs(Date.now() - simulationStartTime)
        }
      }
    };
    const handleKeyUp = (event: KeyboardEvent) => {
      if (event.code === 'Space') {
        console.log("stopped speaking")
        stopListening()
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, [isListening, isAwaitingCustomerReply]);

  useEffect(() => {
    // prevent spacebar from scrolling the page
    const handleSpacebarScroll = (e: any) => {
      if (e.keyCode === 32 && e.target === document.body) {
        e.preventDefault();
      }
    };

    window.addEventListener('keydown', handleSpacebarScroll);

    return () => {
      window.removeEventListener('keydown', handleSpacebarScroll);
    };
  }, []);

  useEffect(() => {
    if (isBackendWebsocketConnected && isSTTWebsocketConnected) {
      simulationTimeIntervalRef.current = setInterval(() => {
        setTime((prevTime) => prevTime + 1);
      }, 1000);
      return () => clearInterval(simulationTimeIntervalRef.current);
    }
  }, [isBackendWebsocketConnected, isSTTWebsocketConnected]);

  useEffect(() => {
    if (time >= MAX_SIMULATION_DURATION_IN_MINUTES * 60) {
      toast.info("Ending Simulation as the time limit has been reached")
      handleSimulationEnd()
    }
  }, [time])

  useEffect(() => {
    setSimulationStartTime(Date.now())
  }, [])

  useEffect(() => {
    if (transcript && !isListening) {
      handleSpeechSubmit()
      // TODO: make a sound indicating that the speech output has been taken
    }
  }, [transcript, isListening])

  useEffect(() => {
    // Ask the user if he is sure he wants to refresh / leave the page
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      e.preventDefault();
      e.returnValue = ''; // This is necessary for some browsers
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  return (
    <div className="bg-white p-6 rounded-lg shadow-md flex justify-center space-x-6" style={{borderRadius: "20px"}}>
      {
        isBackendWebsocketConnected && isSTTWebsocketConnected ? (
          <>
            {
              hasSimulationEnded ? (
                <div className="flex flex-col items-center">
                  {
                    <>
                    {
                      !simulationDetails.isTraining ? (
                        <>
                        {
                          evaluationDetails ? (
                            <ObjectionEvaluationComponent data={evaluationDetails} />
                          ) : (
                            <div className="flex flex-col items-center">
                              <CircularProgress />
                              <div className="mt-2">Fetching Evaluation Details...</div>
                            </div>
                          )
                        }
                        </>
                      ) : (
                        <div className="mt-5 flex justify-center flex-col items-center">
                          {
                            isAutoPlayCancelled ? (
                              <>
                                <Button
                                  variant='outlined'
                                  onClick={() => {
                                    if(!simulationDetails.nextScenario){
                                      const email = JSON.parse(localStorage.getItem('userProfile') || '{}').email
                                      const url = `/module/${simulationDetails.moduleId}/results` + (email ? `?rep_email=${email}` : '')
                                      navigate(url)
                                    }
                                    else navigate(`/scenario/${simulationDetails.nextScenario.id}`)
                                  }}
                                  style={{color: "black", borderColor: "black"}}
                                >
                                  <ArrowForwardIcon /> &nbsp;
                                  {
                                    !simulationDetails.nextScenario ? (
                                      "View Module Results"
                                    ) : (
                                      "Next Scenario"
                                    )
                                  }
                                </Button>
                              </>
                            ) : (
                              <>
                                {
                                  simulationDetails.nextScenario ? (
                                    <div className="flex">Redirecting to the next scenario ({simulationDetails.nextScenario.title}) <strong>&nbsp;in {nextSimulationIn}&nbsp;</strong> seconds</div>
                                  ) : (
                                    <div className="flex">You have simulated all scenarios in this module! Redirecting you to the results <strong>&nbsp;in {nextSimulationIn}&nbsp;</strong> seconds</div>
                                  )
                                }
                                <Button
                                  variant="outlined"
                                  onClick={() => {
                                    clearInterval(nextScenarioTimeIntervalRef.current)
                                    setIsAutoPlayCancelled(true)
                                  }}
                                  style={{marginTop: "10px", color: "black", borderColor: "black"}}
                                >
                                  <CloseIcon /> &nbsp;
                                  Cancel Autoplay
                                </Button>
                              </>
                            )
                          }
                        </div>
                      )
                    }
                    </>
                  }
                </div>
              ) : (
                <div className="flex flex-col space-y-4 w-full">
                  <AudioPlayer
                    mediaQueue={mediaQueue}
                    isAwaitingCustomerReply={isAwaitingCustomerReply}
                    setTranscript={setTranscript}
                    canRepSpeak={canRepSpeak}
                    setCanRepSpeak={setCanRepSpeak}
                  />
                  <div className="flex w-full">
                    <div className="flex flex-col w-1/2 mr-5">
                      <div className="w-full aspect-w-16 aspect-h-9 bg-gray-200 rounded-lg overflow-hidden mr-5">
                        <img
                          alt="Customer's video"
                          className="object-cover w-full h-full"
                          height="300"
                          src="../../customer.jpg"
                          style={{
                            aspectRatio: "640/360",
                            objectFit: "cover",
                            height: "300px",
                          }}
                        />
                      </div>
                    </div>
                    <div className="flex flex-col w-1/2">
                      <div 
                        className="w-full aspect-w-16 aspect-h-9 bg-gray-200 overflow-hidden" 
                      >
                        {
                          isVideoOn ? (
                            <>
                            {
                              videoPermissionError ? (
                                <div className="flex justify-center items-center flex-col text-white" style={{height: "300px", background: "black"}}>
                                  <ErrorOutlineIcon style={{color: "white", fontSize: "48px"}} />
                                  <span>Camera Permission not Given</span>
                                </div>
                              ) : (
                                <Webcam 
                                  onUserMediaError={() => {setVideoPermissionError(true)}}
                                  videoConstraints={{width: 640,height: 300}} 
                                  audio={false}
                                  style={{width: "100%", height: "300px", background: "black"}}
                                />
                              )
                            }
                            </>
                          ) : (
                            <div className="flex justify-center items-center" style={{height: "300px", background: "black"}}>
                              <VideocamOffIcon style={{color: "white", fontSize: "48px"}} />
                            </div>
                          )
                        }
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-200" style={{ borderRadius: "20px", padding: "20px", display: "flex"}}>
                      <div style={{maxHeight: "4rem", overflowY: "scroll", backgroundColor: "white", borderRadius: "20px", padding: "20px", width: "92%", display: "flex", alignItems: "center", fontSize: "18px", color: "gray"}}>
                        {transcript || "Your speech will appear here."}
                      </div>
                      <Button
                        variant="contained"
                        style={{marginLeft: "10px", backgroundColor: isListening ? "#1876D1" : canRepSpeak ? "green" : "#FFA500", borderRadius: "20px", width: "15%", padding: "10px", color: 'white'}}
                        disabled={!canRepSpeak}
                        onClick = {() => {
                          if (isListening) {
                            stopListening()
                          } else {
                            startListening()
                            setUserMessageStartTimeInMs(Date.now() - simulationStartTime)
                          }
                        }}
                      >
                        {
                          isListening ? ( 
                            <>Stop Speaking</>
                          ) : (
                            <>
                            {
                              canRepSpeak ? (
                                <>Start Speaking</>
                              ) : (
                                <>Processing...</>
                              )
                            }
                            </>
                          )
                        }
                      </Button>
                  </div>
                  <div className="flex justify-center items-center">
                    <span className="text-sm font-semibold mr-5"> {
                      `${String(Math.floor(time / 60)).padStart(2, '0')}:${String(time % 60).padStart(2, '0')}`} / {`${String(MAX_SIMULATION_DURATION_IN_MINUTES).padStart(2, '0')}`}:00
                    </span>
                    <div className="mr-5">
                      {
                        isVideoOn ? (
                          <VideocamIcon style={{ cursor: 'pointer' }} onClick={() => setIsVideoOn(false)} />
                        ) : (
                          <VideocamOffIcon style={{ cursor: 'pointer' }} onClick={() => {setIsVideoOn(true)}} />
                        )
                      }
                    </div>
                    {/* <div className="mr-5">
                      {
                        isListening ? (
                          <MicIcon />
                        ) : (
                          <MicOff style={{ cursor: 'pointer' }} onClick={() => toast.info("Hold down the 'Space' key to speak")} />
                        )
                      }
                    </div> */}
                    <IconButton
                      style={{
                        backgroundColor: 'red',
                        color: 'white',
                        marginRight: '20px'
                      }}
                      onClick={handleSimulationEnd}
                    >
                      
                      <CallEndIcon />
                    </IconButton>
                  </div>
                  {/* <div className="flex justify-center">Hold down the SPACE key to speak</div> */}
                </div>
              )
            }
          </>
        ) : (
          <div className="flex flex-col items-center">
            <CircularProgress />
            <div className="mt-2">Connecting...</div>
            {/* <div className="mt-1">Refresh the page if it takes more than 5 seconds to connect</div> */}
          </div>
        )
      }
    </div>
  )
}

export default function SimulationPage(){
    const simulationDetails = useSelector((state: any) => state.simulation.value)
    const messages = useSelector((state: any) => state.currentSimulation.messages)
    return (
      <div className="flex -mt-4" style={{height: "80vh"}}>
        <div className="w-1/3">
          <TranscriptPanel messages={messages} rep_name={JSON.parse(localStorage.getItem('userProfile') || '{}').name} />
        </div>
        <div className="w-2/3 h-full">
            <Simulation />
            <ScenarioDetails scenarioDetails={simulationDetails.scenarioDetails}/>
        </div>
      </div>
    )
}