import {  IconButton, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import {TextField} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { toast } from 'react-toastify';
import AudioFileIcon from '@mui/icons-material/AudioFile';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';

export interface FileWithPreview extends File {
  preview: any;
  repUsername: string;
}

const thumb: React.CSSProperties = {
  display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
  marginBottom: 8,
  marginRight: 8,
  width: 300,
  boxSizing: 'border-box',
  border: '3px solid #393E46',
  borderRadius: 5,
    padding:"10px",
    whiteSpace: "pre-wrap",
    height: "320px"
};

const thumbInner: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  whiteSpace: 'normal', // Add this line to allow text wrapping

};

const img: React.CSSProperties = {
  display: 'block',
  width: 'auto',
  height: '100%',
  border: '3px solid #393E46',
  padding: 4,
  borderRadius: 5,

};

const thumbsContainer: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: "30px",
  width: "100%",
  justifyContent: "center",

};

interface DragAndDropProps {
    files: FileWithPreview[];
    setFiles: any
    uploading: boolean
}

const DragAndDrop: React.FC<DragAndDropProps> = ({files, setFiles, uploading}) => {
  const generateVideoScreenshots = (curfiles: FileWithPreview[]) => {
    curfiles.forEach((file, index) => {
      if (file.type.startsWith('video/')) {
        const video = document.createElement('video');
        video.src = URL.createObjectURL(file);
        video.setAttribute('crossorigin', 'anonymous');
        video.addEventListener('loadedmetadata', () => {
          const canvas = document.createElement('canvas');
          const timeToCapture = video.duration / 2; // Capture frame from half of the video
          video.currentTime = timeToCapture;
          video.addEventListener('seeked', () => {
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
            const ctx = canvas.getContext('2d');
            if(ctx){
                ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
                const thumbnail = canvas.toDataURL('image/jpeg');
                setFiles((prevState: any) => {
                  const updatedFiles = [...prevState];
                  updatedFiles[index].preview = thumbnail;
                  return updatedFiles;
                });
            }
          });
        });
      }
    });
    curfiles.forEach((file, index) => {
        if (file.name.endsWith('.mp3')){
            setFiles((prevState: any) => {
                const updatedFiles = [...prevState];
                updatedFiles[index].preview = <AudioFileIcon style={{width: "300px", fontSize: "150px", color: "#393E46"}}/>
                return updatedFiles;
            });
        }
        if (file.name.endsWith('.txt')){
            setFiles((prevState: any) => {
                const updatedFiles = [...prevState];
                updatedFiles[index].preview = <InsertDriveFileIcon style={{width: "300px", fontSize: "150px", color: "#393E46"}}/>
                return updatedFiles;
            });
        }
    });
  };

  const { getRootProps, getInputProps } = useDropzone({
    // accept: 'video/*',
    onDrop: (acceptedFiles: File[]) => {
      for(const file of acceptedFiles) {
        if (!(file.name.endsWith('.mp4') || file.name.endsWith('.mp3') || file.name.endsWith('.txt'))){
            toast.error("Only .mp4, .mp3 and .txt files are allowed")
            return
        }
      }
      if (acceptedFiles.length > 10){
        toast.error("You can only upload a maximum of 10 files at a time")
        return
      }
      const filesWithPreview: FileWithPreview[] = acceptedFiles.map(file =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
          repUsername: ""
        })
      );
      setFiles(filesWithPreview);
      generateVideoScreenshots(filesWithPreview);
      
    },
  });
  const handleRemoveFile = (index: number) => {
    const newFiles = [...files];
    newFiles.splice(index, 1);
    setFiles(newFiles);
  }
  const handleEnterSalespersonName = (name: string, index: number) => {
    setFiles((prevState: any) => {
      const updatedFiles = [...prevState];
      updatedFiles[index].repUsername = name;
      return updatedFiles;
    });
  }
  const thumbs = files.map((file, idx) => (
    <div key={file.name} style={{position: "relative", marginRight: "20px"}}>
        <div style={thumb}>
            <div style={thumbInner}>
                {file.name.endsWith('.mp4') ? (
                    <img src={file.preview} style={img} alt={file.name} />
                    ) : (
                        <>
                            {file.preview}
                        </>
                    )}
            </div>
            <div className='w-full'>
                <div className="mt-3 flex justify-center" style={{ whiteSpace: "pre-wrap", width: "100%", overflow: "hidden", fontSize: "18px"}}>{file.name}</div>
                <TextField
                    style={{backgroundColor: "white", width: "100%", marginTop: '10px'}}
                    placeholder="Enter salesperson's name" 
                    onChange={(e) => {handleEnterSalespersonName(e.target.value, idx)}}
                    disabled={uploading}
                />
            </div>
        </div>
        <IconButton disabled={uploading} onClick={(e) => {e.stopPropagation(); handleRemoveFile(idx);}} style={{color: "black", fontSize: "25px", position: "absolute", cursor: "pointer", top: "-5px", right: "-5px", background: "#EEEEEE", borderRadius: "100%"}}>
            <CancelIcon  
              />
        </IconButton>
    </div>
  ));

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks
    console.log(files)
    return () => {
      files.forEach(file => URL.revokeObjectURL(file.preview));
    };
  }, [files]);

  return (
    <section className="mt-4">
        {
            files.length === 0 && (
                <div
                    {...getRootProps({ className: 'dropzone' })}
                    style={{
                    border: '4px dashed #393E46',
                    borderRadius: '20px',
                    minHeight: "300px",
                    paddingTop: "40px",
                    paddingBottom: "40px",
                    backgroundColor: '#FFFFFF',
                    cursor: "pointer",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    marginBottom: "-20px"
                    }}
                >
                        <input {...getInputProps()} />
                        <CloudUploadIcon style={{color: "#393E46", fontSize: "100px"}} />
                        <Typography variant='h6' style={{color: 'gray'}}>Drag & drop some files here, or click to select files</Typography>
                </div>
            )
        }
        <div style={thumbsContainer}>{thumbs}</div>
    </section>
  );
};

export default DragAndDrop;
