import React, {useState, useEffect} from 'react';
import { useSelector } from 'react-redux'
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setCurrentSimulation } from '../redux/simulationSlice';
// import {scenarios} from "./home"
import { toast } from 'react-toastify';
import axios from 'axios';
import { CircularProgress } from '@mui/material';
import { useParams } from 'react-router-dom';
import { REACT_APP_BACKEND_URL } from '../constants';

const Scenario = () => {
  const [micPermissionGiven, setMicPermissionGiven] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const scenarios = useSelector((state: any) => state.scenarios.value)
  const { scenarioId } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const getMicPermission = async() => {
    const permissionStatus = await navigator.permissions.query({ name: 'microphone' as PermissionName })
    if(permissionStatus.state === "denied"){
        toast.info("Open the browser settings and reset the microphone permission")
        return
    }
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    const mediaRecorder = new MediaRecorder(stream);
    mediaRecorder.stop()
  }
  useEffect(() => {
    navigator.permissions.query(
        { name: 'microphone' as PermissionName }
    ).then(function(permissionStatus){
        if (permissionStatus.state === "granted") setMicPermissionGiven(true)
        console.log("mic permission is", permissionStatus.state); // granted, denied, prompt
        permissionStatus.onchange = function(){
            console.log("Mic Permission changed to " + this.state);
            if(this.state !== "granted") setMicPermissionGiven(false)
            else setMicPermissionGiven(true)
        }
    })
  }, [])

  const handleSimulationStart = () => {
    if (scenarioId === undefined || scenarios[scenarioId] === undefined) return
    setLoading(true)
    // TODO: dont hardcode the organization_id, product_id, module_id
    const email = JSON.parse(localStorage.getItem('userProfile') as string).email
    axios.post(`${REACT_APP_BACKEND_URL}/organization/${localStorage.getItem('org_id')}/product/${scenarios[scenarioId]?.product_id}/module/${scenarios[scenarioId]?.module_id}/scenario/${scenarioId}/simulation`, {
      rep_email: email
    }, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('google_access_token')}`
      }
    })
    .then((response: any) => {
      console.log("Create simulation response", response.data)
      localStorage.setItem('DEEPGRAM_TEMPORARY_API_KEY', response.data.deepgram_api_key)
      const simulationId = response.data.id
      dispatch(setCurrentSimulation({
        productId: scenarios[scenarioId]?.product_id,
        moduleId: scenarios[scenarioId]?.module_id,
        scenarioId: scenarios[scenarioId].id,
        simulationId,
        productKeywords: scenarios[scenarioId]?.product_keywords,
        scenarioDetails: scenarios[scenarioId],
        nextScenario: scenarios[scenarioId]?.next_scenario,
        isTraining: true
      }))
      console.log("navigating to simulation page")
      window.location.href = `/simulation/${simulationId}`
    })
    .catch((error: any) => {
      console.error("Error creating simulation", error)
      toast.error("Something went wrong! Please try again later.")
      setLoading(false)
    })
  }

  return (
    <div className="flex flex-col m-5 mt-10 rounded-lg shadow-md bg-white p-6 items-center">
      {
        scenarioId !== undefined && scenarios[scenarioId] !== undefined && (
          <div>
            <h1 className="flex justify-center mb-2">
                {scenarios[scenarioId]?.title}
            </h1>
            <div>You are calling {scenarios[scenarioId]?.customer_metadata.name}, {scenarios[scenarioId]?.customer_metadata.job_title} at {scenarios[scenarioId]?.customer_metadata.organization} (a {scenarios[scenarioId]?.customer_metadata.org_industry} company). The customer is {scenarios[scenarioId]?.customer_metadata.behaviour}</div>
            {/* <h3 className="text-xl font-semibold text-gray-800 mt-2">Customer&apos;s Profile:</h3> */}

            <div className="mt-3"><strong>Call Type: </strong> {scenarios[scenarioId]?.call_type} </div>
            {scenarios[scenarioId]?.previous_interaction && (
              <div className="mt-3"><strong> Scenario Context: </strong> {scenarios[scenarioId]?.previous_interaction?.context} </div>
            )}
            {
              scenarios[scenarioId].agenda?.goals?.length > 0 && (
                <div>
                  <div className="mt-3"><strong>Goals to be achieved</strong></div>
                  <ul className="li-disc ml-3">
                    {scenarios[scenarioId]?.agenda?.goals?.map((goal: string, index: number) => (
                      <li key={index}>{goal}</li>
                    ))}
                  </ul>
                </div>
              )
            } 
            {
              scenarios[scenarioId].agenda?.topics?.length > 0 && (
                <div>
                  <div className="mt-3"><strong>Topics to cover</strong></div>
                  <ul className="li-disc ml-3">
                    {scenarios[scenarioId]?.agenda?.topics?.map((topic: string, index: number) => (
                      <li key={index}>{topic}</li>
                    ))}
                  </ul>
                </div>
              )
            }
            <div className="mt-3">
              <strong>About the Product: </strong> 
              <div>You are selling {scenarios[scenarioId]?.product_name || "Lyris"}. {scenarios[scenarioId]?.product_description}</div>
            </div>

            <div className="flex justify-center">
            
              {
                loading ? (
                  <CircularProgress />
                ) : (
                  <div className="flex flex-col items-center">
                    <Button 
                        style={{
                            backgroundColor: !micPermissionGiven ? '#87B8EA': '#393E46',
                            color: 'white',
                            width: "fit-content",
                            padding: "10px 20px 10px 20px",
                            marginTop: "20px"
                        }}
                        onClick={() => {
                            if(micPermissionGiven) handleSimulationStart()
                            else getMicPermission()
                        }}
                    >
                        {
                            micPermissionGiven ? (
                                <> Start Simulation</>
                            ) : (
                                <>Click here to give microphone access</>
                            )
                        }
                    </Button>
                    <Button 
                        style={{
                            backgroundColor: '#87B8EA',
                            color: 'white',
                            width: "fit-content",
                            padding: "10px 20px 10px 20px",
                            marginTop: "20px"
                        }}
                        onClick={() => {navigate(`/scenario/${scenarioId}/simulations`)}}
                    >
                        View Previous Simulations
                    </Button>
                  </div>
                )
              }
            </div>
          </div>
        )
      }
    </div>
  );
}

export default Scenario;