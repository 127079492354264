import React, { useState, useEffect } from "react";
import axios from "axios";
import { REACT_APP_BACKEND_URL } from "../../constants";
import { toast } from "react-toastify";
import { setModule } from "../../redux/moduleSlice";
import { Button, CircularProgress, TextField, Typography } from "@mui/material";
import ScenarioCard from "../../components/scenarioCard";
import { DataGrid, GridColDef, GridRenderCellParams, GridToolbar } from '@mui/x-data-grid';

interface NewModuleProps {
    productId: string;
    closeModal: any;
    addModule: (module: any) => void;
    shouldAddScenarios: boolean;
    existingScenarioIds: string[];
    handleAddScenarios: (scenarios: any[]) => Promise<Record<string, boolean>>;
}

const NewModule: React.FC<NewModuleProps> = ({ productId, closeModal, addModule, shouldAddScenarios, existingScenarioIds, handleAddScenarios }) => {
    const [loading, setLoading] = useState<boolean>(true);
    const [createModuleLoading, setCreateModuleLoading] = useState<boolean>(false);
    const [moduleName, setModuleName] = useState<string>("");
    const [moduleDescription, setModuleDescription] = useState<string>("");
    const [productScenarios, setProductScenarios] = useState<any[]>([]);
    const [selectedRows, setSelectedRows] = React.useState<any[]>([]);


    useEffect(() => {
        axios.get(`${REACT_APP_BACKEND_URL}/organization/${localStorage.getItem('org_id')}/product/${productId}/scenarios`, {
            headers: { 'Authorization': `Bearer ${localStorage.getItem('google_access_token')}` }
        })
            .then((response) => {
                if (shouldAddScenarios) {
                    console.log("existing scenarios", existingScenarioIds, response.data.scenarios)
                    setProductScenarios(
                        response.data.scenarios.filter((
                            scenario: any) => !!scenario.call_type
                        )
                        .filter(
                            (scenario: any) => !existingScenarioIds.includes(scenario.id)
                        )
                        .map((scenario: any) => {return {...scenario, scenarioContext: scenario.previous_interaction.context}}))
                }
                else{
                    setProductScenarios(
                        response.data.scenarios.filter((scenario: any) => !!scenario.call_type)
                        .map((scenario: any) => {return {...scenario, scenarioContext: scenario.previous_interaction.context}})
                    )
                }
            })
            .catch((error) => {
                console.error(error)
                toast.error("Error fetching scenarios")
            })
            .finally(() => {
                setLoading(false)
            })
    }, [])
    const handleSubmitScenarios = async () => {
        if (selectedRows.length === 0){
            toast.error("You need to select at least 1 scenario")
            return
        }
        setCreateModuleLoading(true)
        console.log("selected rows are", selectedRows)
        const response = await handleAddScenarios(selectedRows)
        setCreateModuleLoading(false)
        if (response.success){
            closeModal()
        }
    }
    const handleCreateModule = () => {
        console.log("Created module called")
        if (!moduleName || !moduleDescription || selectedRows.length === 0) {
            toast.error("Please fill all the fields and select at least one scenario")
            return
        }
        setCreateModuleLoading(true)
        const data = {
            name: moduleName,
            description: moduleDescription,
            old_scenarios_ids: selectedRows,
            new_scenarios: [] // TODO: remove when backend is updated
        }
        axios.post(`${REACT_APP_BACKEND_URL}/organization/${localStorage.getItem('org_id')}/product/${productId}/module`, data, {
            headers: { 'Authorization': `Bearer ${localStorage.getItem('google_access_token')}` }
        })
            .then((response) => {
                console.log("module created", response.data)
                setModule(response.data)
                toast.success("Module created successfully")
                addModule(response.data)
                closeModal();
                // navigate(`/manager/module/${response.data.id}`)
            })
            .catch((error) => {
                console.error("error creating module", error)
                toast.error("Something went wrong while creating a new module")
            })
            .finally(() => {
                setCreateModuleLoading(false)
            })
    }
    const columns: GridColDef[] = [
        {
            field: 'title', headerName: 'Title', flex: 1, disableColumnMenu: true, renderHeader: () => (
                <strong>Title</strong>
            ),
        },
        {
            field: 'call_type', headerName: 'Call type', flex: 0.9, disableColumnMenu: true, renderHeader: () => (
                <strong>Call type</strong>
            ),
        },
        {
            field: 'scenarioContext', headerName: 'Scenario Context', flex: 2, disableColumnMenu: true, renderHeader: () => (
                <strong>Scenario summary</strong>
            ),
        },
        {
            field: 'details',
            headerName: 'Details',
            flex: 0.5,
            disableColumnMenu: true,
            sortable: false,
            renderCell: (params: GridRenderCellParams) => (
                <ScenarioCard data={params.row} showCard={false} />
            ),
            renderHeader: () => (
                <strong>Details</strong>
            ),
        },
    ];

    const handleSelectionModelChange = (selectionModel: any) => {
        setSelectedRows(selectionModel);
        console.log("selected rows", selectionModel)
    };

    return (
        <div className="p-4">
            {
                loading ? <div style={{ minHeight: "90vh" }} className="flex justify-center items-center"><CircularProgress /></div> :
                    (
                        <div>
                            <div className="flex justify-center w-full">
                                <Typography variant="h4" style={{ marginBottom: "10px" }}>
                                    {
                                        shouldAddScenarios ? "Add scenarios" : "Create new module"
                                    }
                                </Typography>
                            </div>
                            {
                                !shouldAddScenarios && (
                                    <div >
                                        <Typography variant="h6" style={{ marginBottom: "10px" }}>Module details</Typography>
                                        <div className="flex flex-col justify-center">
                                            <TextField
                                                label="Module Name"
                                                variant="outlined"
                                                value={moduleName}
                                                onChange={(e) => { setModuleName(e.target.value) }}
                                                style={{ width: "50%" }}
                                            />
                                            <TextField
                                                label="Module Description"
                                                variant="outlined"
                                                value={moduleDescription}
                                                onChange={(e) => { setModuleDescription(e.target.value) }}
                                                multiline
                                                rows={2}
                                                // fullWidth
                                                style={{ marginTop: "10px", width: "50%" }}
                                            />
                                        </div>
                                    </div>
                                )
                            }
                            <div style={{ width: '100%', marginTop: "10px" }}>
                                {
                                    !shouldAddScenarios && ( 
                                        <Typography variant="h6" style={{ marginBottom: "10px" }}>Select scenarios</Typography>
                                    )
                                }
                                <DataGrid
                                    rows={productScenarios}
                                    columns={columns}
                                    initialState={{
                                        pagination: {
                                            paginationModel: { page: 0, pageSize: 5 },
                                        },
                                    }}
                                    pageSizeOptions={[5, 10, 20, 50]}
                                    checkboxSelection
                                    onRowSelectionModelChange={handleSelectionModelChange}
                                    components={{ Toolbar: GridToolbar }}
                                    slotProps={{
                                        toolbar: {
                                          showQuickFilter: true,
                                        },
                                      }}
                                    disableDensitySelector
                                    disableColumnSelector
                                    disableColumnFilter
                                />
                            </div>
                            <div className="flex" style={{ justifyContent: "space-between", marginTop:"10px" }}>
                                <div style={{ marginRight: "10px", marginTop: "10px", width: "80px", display: "flex", justifyContent: "center" }}>
                                    {
                                        createModuleLoading ?
                                            <CircularProgress />
                                            :
                                            <Button 
                                                onClick={() =>{
                                                    if(shouldAddScenarios) handleSubmitScenarios()
                                                    else handleCreateModule()
                                                }} 
                                                variant="contained" 
                                                style={{backgroundColor:"#45474B"}} 
                                                color="primary"
                                            >
                                                Submit
                                            </Button>
                                    }
                                </div>
                                <Button onClick={closeModal} variant="outlined" style={{ marginTop: "10px", color:"#45474B", borderColor:"#45474B" }}>Cancel</Button>
                            </div>
                        </div>
                    )
            }
        </div>
    );
}

export default NewModule;