import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { REACT_APP_BACKEND_URL} from '../constants';
import { Button, CircularProgress, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import { toast } from 'react-toastify';


const OrgSettings = () => {
    const [orgChangeLoading, setOrgChangeLoading] = useState<boolean>(false)
    const [orgName, setOrgName] = useState<string>('')
    const [callRecordingIngestionFrequency, setCallRecordingIngestionFrequency] = useState<number | null>(null)
    const [callRecordingDefaultLookback, setCallRecordingDefaultLookback] = useState<number | null>(null)
    // const [businessContext, setBusinessContext] = useState<string>('')
    const [salesRules, setSalesRules] = useState<string>('')
    const [fetchingOrgSettings, setFetchingOrgSettings] = useState<boolean>(true)
    const [updatingOrgSettings, setUpdatingOrgSettings] = useState<boolean>(false)


    useEffect(() => {
        axios.get(
            `${REACT_APP_BACKEND_URL}/organization/${localStorage.getItem('org_id')}`,
            { headers: { 'Authorization': `Bearer ${localStorage.getItem('google_access_token')}` } }
        ).then((response) => {
            setOrgName(response.data.name)
            setCallRecordingIngestionFrequency(response.data.call_recording_periodic_ingestion_frequency_in_days)
            // setBusinessContext(response.data.business_context)
            setSalesRules(response.data.sales_rules)
            setCallRecordingDefaultLookback(response.data.call_recording_default_lookback_days)
        }).catch(err => {
            console.log("error", err)
            toast.error("Error fetching organization settings")
        }).finally(() => {
            setFetchingOrgSettings(false)
        })
    }, [])

    // const handleBusinessContextChange = (e: any) => {
    //     if(e.target.value.length > 5000) toast.error("Business Context should be less than 5000 characters")
    //     setBusinessContext(e.target.value.slice(0, 5000))
    // }
    const handleSalesRulesChange = (e: any) => {
        if(e.target.value.length > 10000) toast.error("Sales Rules should be less than 10000 characters")
        setSalesRules(e.target.value.slice(0, 10000))
    }

    const handleOrgChange = (org_id: string) => {
        const email = JSON.parse(localStorage.getItem('userProfile') as string).email
        setOrgChangeLoading(true)
        axios.get(
          `${REACT_APP_BACKEND_URL}/organization/${org_id}/person/${email}`,
          { headers: { 'Authorization': `Bearer ${localStorage.getItem('google_access_token')}` } }
        ).then((response) => {
          localStorage.setItem('org_id', org_id)
          localStorage.setItem('is_manager', response.data.role.toLowerCase() === "vp" ? 'true' : 'false')
          window.location.href = '/'
          console.log("response", response.data)
        }).catch(err => {
          console.log("error", err)
          toast.error("Error fetching user profile")
        }).finally(() => {
          setOrgChangeLoading(false)
        })
      }

    
    const handleUpdateOrgSettings = () => {
        setUpdatingOrgSettings(true)
        axios.patch(
            `${REACT_APP_BACKEND_URL}/organization/${localStorage.getItem('org_id')}`,
            {
                name: orgName,
                call_recording_periodic_ingestion_frequency_in_days: callRecordingIngestionFrequency,
                // business_context: businessContext,
                sales_rules: salesRules,
                call_recording_default_lookback_days: callRecordingDefaultLookback
            },
            { headers: { 'Authorization': `Bearer ${localStorage.getItem('google_access_token')}` } }
        ).then(() => {
            toast.success("Organization settings updated successfully")
            const userProfile = JSON.parse(localStorage.getItem('userProfile') as string)
            userProfile.orgs = userProfile.orgs.map((org: any) => {
                if(org.id === localStorage.getItem('org_id')) {
                    org.name = orgName
                }
                return org
            })
            localStorage.setItem('userProfile', JSON.stringify(userProfile))
        }).catch(err => {
            console.log("error", err)
            toast.error("Error updating organization settings")
        }).finally(() => {
            setUpdatingOrgSettings(false)
        })
    }

    return (
        <div>
             <div className="w-full flex justify-center mb-7">
                  <Typography variant='h3'>Manage Organization</Typography>
              </div>
            <div className="flex justify-center flex-col items-center">
            {
                orgChangeLoading || fetchingOrgSettings ? (
                    <CircularProgress />
                ) : (
                <div>
                    <Typography variant='h5' style={{marginBottom: "20px"}}>Switch Organization</Typography>
                    <FormControl style={{ minWidth: "150px", marginBottom: "10px", width: "fit-content" }}>
                    <div className="flex">

                        <InputLabel>Select Current Org</InputLabel>
                        <Select
                            value={localStorage.getItem('org_id')}
                            onChange={(e) => handleOrgChange(e.target.value as string)}
                            label="Select Organization"
                            style={{width: "300px"}}
                        >
                            {
                            JSON.parse(localStorage.getItem('userProfile') as string).orgs?.map((org: any) => (
                                <MenuItem key={org.id} value={org.id}>{org.name}</MenuItem>
                            ))
                            }
                        </Select>
                        {/* <Button variant='contained' style={{marginLeft: "10px"}}>Rename</Button> */}
                    </div>

                    </FormControl>
                    <h3>
                        <strong>Note: </strong>If you were added to an organization recently, please logout and login again to see the organization in the list.
                    </h3>
                    <Typography variant='h5' style={{marginTop: "20px", marginBottom: "20px"}}>Modify Organization&#39;s Settings</Typography>
                    <TextField
                        label="Organization Name"
                        variant="outlined"
                        style={{width: "200px"}}
                        value={orgName}
                        onChange={(e) => setOrgName(e.target.value)}
                        disabled={localStorage.getItem('is_manager') !== "true"}
                    />
                    <TextField
                        label="Call Recording Ingestion Frequency (days)"
                        variant="outlined"
                        type='number'
                        value={callRecordingIngestionFrequency}
                        onChange={(e) => setCallRecordingIngestionFrequency(parseInt(e.target.value))}
                        style={{marginLeft: "10px", width: "300px"}}
                        disabled={localStorage.getItem('is_manager') !== "true"}
                    />
                    <TextField
                        label="Call Recording Lookback (days)"
                        variant="outlined"
                        type='number'
                        value={callRecordingDefaultLookback}
                        onChange={(e) => setCallRecordingDefaultLookback(parseInt(e.target.value))}
                        style={{marginLeft: "10px", width: "300px"}}
                        disabled={localStorage.getItem('is_manager') !== "true"}
                    />
                    {/* <TextField
                        label="Business Context"
                        variant="outlined"
                        multiline
                        fullWidth
                        rows={8}
                        onChange={handleBusinessContextChange}
                        value={businessContext}
                        sx={{marginTop: "10px"}}
                        disabled={localStorage.getItem('is_manager') !== "true"}
                    /> */}
                    <TextField
                        label="Sales Rules"
                        variant="outlined"
                        multiline
                        fullWidth
                        rows={15}
                        onChange={handleSalesRulesChange}
                        value={salesRules}
                        sx={{marginTop: "10px"}}
                        disabled={localStorage.getItem('is_manager') !== "true"}
                    />
                </div>
                )
            }
            <div className='mt-3'>
            {
                updatingOrgSettings ? <CircularProgress /> : (
                    <Button 
                    variant='contained' 
                    style={{
                        width: "fit-content", 
                        backgroundColor: localStorage.getItem('is_manager') === "true" ? "#45474B": "#EDF1F4"
                    }}
                    onClick={() => handleUpdateOrgSettings()}    
                    disabled={localStorage.getItem('is_manager') !== "true"}
                    >
                        Update
                    </Button>
                )
            }
            </div>
            </div>
        </div>
    );
};

export default OrgSettings;
