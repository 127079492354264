import * as React from 'react';
import Grid from '@mui/material/Grid';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import 'react-toastify/dist/ReactToastify.css';


import GoogleLoginButton from '../google_login/google_login';

const theme = createTheme();

export default function SignIn() {
  return (
    <ThemeProvider theme={theme}>
      <Grid item className="h-screen">
        <GoogleLoginButton/>
      </Grid> 
    </ThemeProvider>
  );
}
