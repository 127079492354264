import React, { useState } from "react";
import { Button, Modal, Box, CircularProgress, FormControl, InputLabel, Select, Tooltip, Link, MenuItem } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import axios from 'axios';
import { CALL_DESCRIPTION, REACT_APP_BACKEND_URL } from "../constants";
import TextField from '@mui/material/TextField';
// import DeleteIcon from '@mui/icons-material/Delete';
// import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import { toast } from 'react-toastify';
import { getDefaultModuleId } from "../constants";
import { setCurrentSimulation } from '../redux/simulationSlice';
import { useSelector, useDispatch } from 'react-redux';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { DataGrid, GridColDef, GridRenderCellParams, GridToolbar } from '@mui/x-data-grid';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AddIcon from '@mui/icons-material/Add';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

interface customerMetadata {
    behaviour: string;
    gender: string;
    job_title: string;
    location: string;
    name: string;
    org_industry: string;
    organization: string;
    way_of_speaking: string;
    current_solution?: {
        name: string;
    }
}

interface objection {
    id: string;
    objection: string;
    objection_type: string;
    possible_contexts: string[];
}

interface PreviousInteraction {
    context: string;
    objection_raised_id: string;
    customer_message: string;
}

interface ScenarioCardProps {
    call_type: string;
    customer_metadata: customerMetadata;
    customers_product_knowledge: string[];
    id: string;
    status: string
    implicit_objections: objection[];
    explicit_objections: objection[];
    previous_interaction?: PreviousInteraction;
    scenario: string;
    title: string;
    product_id: string;
    agenda: any;
    qna: any[];
}
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    p: 4,
    borderRadius: 5,
    outline: null,
    maxHeight: '90vh',
    overflowY: 'auto'
};

const ScenarioCard: React.FC<{ 
    data: ScenarioCardProps, 
    showCard?: boolean, 
    moduleID?: string, 
    allowEdit?: boolean, 
    isSelectionOn?: boolean, 
    onSelect?: () => void, 
    isSelected?: boolean
}> = ({ data, showCard = true, moduleID = undefined, allowEdit = true, isSelectionOn=false, onSelect = undefined, isSelected = false }) => {
    const [open, setOpen] = useState(false);
    const [editScenarioModal, setEditScenarioModal] = useState(false);
    const [scenarioDetails, setScenarioDetails] = useState(data);
    const [editedScenarioDetails, setEditedScenarioDetails] = useState<any>(JSON.parse(JSON.stringify(data)));
    const [simulationStartLoading, setSimulationStartLoading] = useState(false);
    const [fetchingObjections, setFetchingObjections] = useState(false);
    const [explicitObjections, setExplicitObjections] = useState<any[]>([]);
    const [implicitObjections, setImplicitObjections] = useState<any[]>([]);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [selectionModelExplicit, setSelectionModelExplicit] = React.useState(() =>
        data.explicit_objections.map((r) => r.id),
    );
    const [selectionModelImplicit, setSelectionModelImplicit] = React.useState(() =>
        data.implicit_objections.map((r) => r.id),
    );
    const products = useSelector((state: any) => state.products.value)

    const dispatch = useDispatch();

    const Loader = () => {
        return (
            <div style={{ minHeight: "90vh" }} className="flex justify-center items-center">
                <CircularProgress />
            </div>
        );
    }
    const fetchAggregatedObjections = async () => {
        try {
            setFetchingObjections(true);
            const response = await axios.get(`${REACT_APP_BACKEND_URL}/organization/${localStorage.getItem('org_id')}/product/${data.product_id}/objections`, {
                headers: { 'Authorization': `Bearer ${localStorage.getItem('google_access_token')}` }
            });
            const explicit: any = [];
            const implicit: any = [];
            for (const obj of response.data.objections) {
                if (obj['category'] === "Explicit") {
                    explicit.push(obj)
                }
                else {
                    implicit.push(obj)
                }
            }
            
            // Initial sort to keep selectionModelExplicit Ids first in the array, selectionModelExplicit may not contain all IDs in present in explicit
            explicit.sort((a: { id: string; }, b: { id: string; }) => selectionModelExplicit.indexOf(a.id) - selectionModelExplicit.indexOf(b.id)).reverse();
            implicit.sort((a: { id: string; }, b: { id: string; }) => selectionModelImplicit.indexOf(a.id) - selectionModelImplicit.indexOf(b.id)).reverse();
            
            setExplicitObjections(explicit);
            setImplicitObjections(implicit);

        } catch (error) {
            console.error('Error fetching aggregated objections:', error);
            toast.error('Something went wrong while fetching objections')
        } finally {
            setFetchingObjections(false);
        }
    }

    const handleSimulationStart = (productId: string, scenarioId: string) => {
        setSimulationStartLoading(true)
        let moduleId = moduleID;
        if (moduleID == undefined) {
            moduleId = getDefaultModuleId(productId)
        }
        const email = JSON.parse(localStorage.getItem('userProfile') as string).email
        axios.post(`${REACT_APP_BACKEND_URL}/organization/${localStorage.getItem('org_id')}/product/${productId}/module/${moduleId}/scenario/${scenarioId}/simulation`, {
            rep_email: email
        }, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('google_access_token')}`
            }
        })
            .then((response: any) => {
                console.log("Create simulation response", response.data)
                localStorage.setItem('DEEPGRAM_TEMPORARY_API_KEY', response.data.deepgram_api_key)
                const simulationId = response.data.id
                dispatch(setCurrentSimulation({
                    productId,
                    moduleId,
                    scenarioId,
                    simulationId,
                    productKeywords: products?.[productId]?.keywords || [],
                    scenarioDetails: { ...scenarioDetails, product_name: products?.[productId]?.name },
                    isTraining: false
                }))
                console.log("using product keywords", products?.[productId]?.keywords)
                console.log("navigating to simulation page")
                window.location.href = `/simulation/${simulationId}`
            })
            .catch((error: any) => {
                console.error("Error creating simulation", error)
                toast.error("Something went wrong! Please try again later.")
                setSimulationStartLoading(false)
            })
    }

    const handleSubmit = async () => {
        setIsSubmitting(true)
        try {
            const response = await axios.patch(`${REACT_APP_BACKEND_URL}/organization/${localStorage.getItem('org_id')}/product/${scenarioDetails?.product_id}/scenario/${scenarioDetails?.id}`, editedScenarioDetails, {
                headers: { 'Authorization': `Bearer ${localStorage.getItem('google_access_token')}` }
            });
            setScenarioDetails(response.data);
            setEditScenarioModal(false);
            toast.success('Product details updated successfully');
        } catch (error) {
            console.error('Error updating product details:', error);
            toast.error('Error updating product details');
        }
        setIsSubmitting(false);
    };
    function ExpandableCell({ value }: GridRenderCellParams) {
        const [expanded, setExpanded] = React.useState(false);

        return (
            <div>
                {expanded ? value : value.slice(0, 100)}&nbsp;
                {value.length > 100 && (
                    <Link
                        type="button"
                        component="button"
                        sx={{ fontSize: 'inherit' }}
                        onClick={() => setExpanded(!expanded)}
                    >
                        {expanded ? ' shrink' : ' expand'}
                    </Link>
                )}
            </div>
        );
    }
    const objectionColumns: GridColDef[] = [
        {
            field: 'objection_type', flex: 0.5, renderHeader: () => (
                <div>
                    <strong>Objection Type</strong>
                </div>
            ),
        },
        {
            field: 'description', flex: 1, renderHeader: () => (
                <div>
                    <strong>Description</strong>
                    <Tooltip style={{ marginLeft: "10px", fontSize: "20px" }} title={<span style={{ fontSize: "16px" }}>Summary of the objection raised by the customer during sales call</span>}>
                        <HelpOutlineIcon />
                    </Tooltip>
                </div>
            ),
            renderCell: (params: GridRenderCellParams) => <ExpandableCell {...params} />,
        },
        {
            field: 'ideal_answer', flex: 1, renderHeader: () => (
                <div>
                    <strong>Ideal Answer</strong>
                    <Tooltip style={{ marginLeft: "10px", fontSize: "20px" }} title={<span style={{ fontSize: "16px" }}>This shows the ideal way to handle the objection, you can edit this by clicking on {'View Details'}. This will help improve the evalaution of your sales rep.</span>}>
                        <HelpOutlineIcon />
                    </Tooltip>
                </div>
            ),
            renderCell: (params: GridRenderCellParams) => { return params.value != null ? <ExpandableCell {...params} /> : <></> },
        },

    ];
    const handleSelectionExplicit = (updatedRowIds: any) => {
        setSelectionModelExplicit(updatedRowIds)
        const selectedRows = explicitObjections.filter((row: any) => updatedRowIds.includes(row.id)).map((row: any) => {
            return {
                id: row.id,
                objection_type: row.objection_type,
                objection: row.description,
                possible_contexts: row.children.map((child: any) => child.context).sort(() => Math.random() - 0.5).slice(0, Math.min(4, row.children.length)),
            }
        })
        const scenarioDetailsExplicit = { ...editedScenarioDetails, explicit_objections: selectedRows }
        setEditedScenarioDetails(scenarioDetailsExplicit)
    }
    const handleSelectionImplicit = (updatedRowIds: any) => {
        setSelectionModelImplicit(updatedRowIds);
        const selectedRows = implicitObjections.filter((row: any) => updatedRowIds.includes(row.id)).map((row: any) => {
            return {
                id: row.id,
                objection_type: row.objection_type,
                objection: row.description,
                possible_contexts: row.children.map((child: any) => child.context).sort(() => Math.random() - 0.5).slice(0, Math.min(4, row.children.length)),
            }
        })
        const scenarioDetailsImplicit = { ...editedScenarioDetails, implicit_objections: selectedRows }
        setEditedScenarioDetails(scenarioDetailsImplicit)
    }
    console.log("show card", showCard, isSelectionOn, isSelected)
    return (
        <div className={showCard ? "flex flex-col rounded-xl shadow-lg bg-white p-4 items-center" : ""} 
            style={{ backgroundColor: showCard ? isSelectionOn ? (isSelected ? "transparent" : "#D4D4D4") : "transparent" : "transparent", transition: 'background-color 0.3s ease, width 0.3s ease', cursor: showCard ? isSelectionOn ? "pointer" : "default" : "default" }}
            onClick={() => {
                if(showCard && isSelectionOn && onSelect !== undefined){
                    onSelect()
                }
            }}
        >
            <div className="flex items-center">
                <div className="mr-2">
                    {
                        showCard && isSelectionOn && (
                            <>
                                {
                                    isSelected ? (
                                        <CheckCircleIcon style={{width: "1.25rem", height: "1.25rem"}} />
                                    ) : (
                                        <div style={{border: "2px solid grey", borderRadius: "50%", width: "1.25rem", height: "1.25rem"}}></div>
                                    )
                                }
                            </>
                        )
                    }
                </div>
                {showCard ? <p className="text-xl">{scenarioDetails?.title}</p> : <></>}
            </div>
            <Button
                variant="text"
                onClick={(e) => {
                    e.stopPropagation();
                    setOpen(true)
                }}
            >{showCard ? 'View details' : <VisibilityIcon style={{ color: "#45474B" }} />}</Button>
            <Modal
                open={open}
                onClose={() => {
                    setOpen(false)
                }}
                BackdropProps={{
                    onClick: (e) => {
                        e.stopPropagation();
                        setOpen(false)
                    }
                }}
            >
                <Box sx={style}>
                    <div>
                        <div onClick={(e) => { e.stopPropagation() }}>
                            <div className="flex">
                                <h1 className="flex w-full justify-center text-center">{scenarioDetails?.title}</h1>
                                {
                                    scenarioDetails.status === "Available" && allowEdit && (
                                        <IconButton style={{}} onClick={() => { setEditScenarioModal(true); setEditedScenarioDetails(JSON.parse(JSON.stringify(scenarioDetails))); fetchAggregatedObjections() }}>
                                            <EditIcon />
                                        </IconButton>
                                    )
                                }
                            </div>
                            {
                                scenarioDetails.status === "Creating" ? (
                                    <div className="text-xl">Scenario is being created... This can take upto 2-3 minutes</div>
                                ) : (
                                    <>
                                        <div className="flex justify-center mt-4">
                                            {
                                                simulationStartLoading ? (
                                                    <CircularProgress />
                                                ) : (
                                                    <Button
                                                        variant='contained' style={{ background: "#393E46" }}
                                                        onClick={() => handleSimulationStart(scenarioDetails.product_id, scenarioDetails.id)}
                                                    >
                                                        Try Now
                                                    </Button>
                                                )
                                            }
                                        </div>
                                        <h1 className="text-3xl  mt-3 w-full flex justify-center">{scenarioDetails?.call_type}</h1>
                                        {
                                            scenarioDetails?.agenda?.goals.length > 0 && (
                                                <>
                                                    <h2 className="text-2xl  mt-3 w-full flex justify-center">Goals to be achieved during the simulation</h2>
                                                    <div>
                                                        <ul className="li-disc pl-4">
                                                            {scenarioDetails?.agenda?.goals?.map((goal: any, index: number) => (
                                                                <li key={index}>{goal}</li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                </>
                                            )
                                        }
                                        {
                                            scenarioDetails?.agenda?.topics.length > 0 && (
                                                <>
                                                    <h2 className="text-2xl  mt-3 w-full flex justify-center">Topics to cover during simulation</h2>
                                                    <div>
                                                        <ul className="li-disc pl-4">
                                                            {scenarioDetails?.agenda?.topics?.map((topic: any, index: number) => (
                                                                <li key={index}>{topic}</li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                </>
                                            )
                                        }
                                        <h2 className="text-2xl  mt-3 w-full flex justify-center">Explicit Objections</h2>
                                        <div>
                                            <ul className="pl-4 li-disc ">
                                                {
                                                    scenarioDetails?.explicit_objections.map((objection: any, index) => {
                                                        if (typeof objection === 'string') {
                                                            return <li key={index}>{objection}</li>
                                                        }
                                                        else {
                                                            return <li key={index}>{objection.objection}</li>
                                                        }
                                                    })
                                                }
                                            </ul>
                                        </div>
                                        {
                                            scenarioDetails?.implicit_objections.length > 0 && (
                                                <div>
                                                    <h2 className="text-2xl  mt-3 w-full flex justify-center">Implicit Objections</h2>
                                                    <ul className="li-disc pl-4">
                                                        {scenarioDetails?.implicit_objections.map((objection: any, index) => {
                                                            if (typeof objection === 'string') {
                                                                return <li key={index}>{objection}</li>
                                                            }
                                                            else {
                                                                return <li key={index}>{objection.objection}</li>
                                                            }
                                                        })}
                                                    </ul>
                                                </div>
                                            )
                                        }
                                        {
                                            (scenarioDetails?.previous_interaction) && (
                                                <>
                                                    <h2 className="text-2xl  mt-3 w-full flex justify-center">Previous Interaction</h2>
                                                    <div><strong> Context: </strong> {scenarioDetails?.previous_interaction.context} </div>
                                                    <div><strong> First Customer Statement: </strong> {scenarioDetails?.previous_interaction.customer_message} </div>
                                                </>
                                            )     
                                        }
                                        <h2 className="text-2xl mt-3 w-full flex justify-center">Customer Metadata</h2>
                                        <div>
                                            <ul className="li-disc pl-4">
                                                <li><strong>Name: </strong>{scenarioDetails?.customer_metadata?.name}</li>
                                                <li><strong>Job Title: </strong>{scenarioDetails?.customer_metadata?.job_title}</li>
                                                <li><strong>Organization: </strong>{scenarioDetails?.customer_metadata?.organization}</li>
                                                <li><strong>Industry: </strong>{scenarioDetails?.customer_metadata?.org_industry}</li>
                                                <li><strong>Behavior: </strong>{scenarioDetails?.customer_metadata?.behaviour}</li>
                                                <li><strong>Way of Speaking: </strong>{scenarioDetails?.customer_metadata?.way_of_speaking}</li>
                                                {scenarioDetails?.customer_metadata?.current_solution?.name && (
                                                    <li><strong>Current Solution: </strong>{scenarioDetails?.customer_metadata?.current_solution.name}</li>
                                                )}
                                                <li><strong>Answers the customer will give to a sales rep&#39;s questions:</strong>
                                                    <ul className="li-disc pl-4">
                                                        
                                                        {scenarioDetails?.qna.map((qa, index) => (
                                                            <li key={index} className="mb-2">
                                                                <><strong>Question</strong>: {qa.question}</> <br />
                                                                <><strong>Answer</strong>: {qa.answer}</>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </li>
                                            </ul>
                                        </div>
                                        {
                                            scenarioDetails?.customers_product_knowledge.length > 0 && (
                                                <div className="mt-2">
                                                    <h2 className="text-2xl mt-3 w-full flex justify-center">Other Information</h2>
                                                    <strong>The AI customer is informed about the following aspects of the product: </strong>
                                                    <ul className="li-disc pl-4">
                                                        {scenarioDetails?.customers_product_knowledge.map((knowledge, index) => (
                                                            <li key={index}>{knowledge}</li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            )
                                        }
                                        <Modal
                                            open={editScenarioModal}
                                            onClose={() => setEditScenarioModal(false)}
                                            aria-labelledby="modal-modal-title"
                                            aria-describedby="modal-modal-description"
                                        >
                                            <Box sx={{ position: 'absolute', top: "50%", left: "50%", transform: 'translate(-50%, -50%)', width: 1000, bgcolor: 'background.paper', border: "none", borderRadius: 5, boxShadow: 24, p: 4, maxHeight: "80%", overflowY: "auto" }}>
                                                <div >
                                                    <div className="flex justify-between">
                                                        <TextField
                                                            label="Scenario Title"
                                                            variant="outlined"
                                                            required
                                                            value={editedScenarioDetails.title}
                                                            onChange={(e) => setEditedScenarioDetails({ ...editedScenarioDetails, title: e.target.value })}
                                                            style={{ width: "49%", marginTop: "20px" }}
                                                        />
                                                        <FormControl variant="outlined" style={{ marginTop: "20px", width: "49%" }} fullWidth required>
                                                            <InputLabel>Select Call Type</InputLabel>
                                                            <Select
                                                                value={editedScenarioDetails.call_type}
                                                                onChange={(e) => setEditedScenarioDetails({ ...editedScenarioDetails, call_type: e.target.value })}
                                                                label="Select Call Type"
                                                                required
                                                            >
                                                                {Object.keys(CALL_DESCRIPTION).map((call, index) => (
                                                                    <MenuItem key={index} value={call}>{call}</MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </div>
                                                    <TextField
                                                        label="Scenario description or context"
                                                        variant="outlined"
                                                        required
                                                        placeholder="The customer has already raised concerns about the price of the Platinum plan, questioning if there are any cheaper alternatives that still offer the critical 24/7 support they need"
                                                        value={editedScenarioDetails.previous_interaction.context}
                                                        onChange={(e) => setEditedScenarioDetails({ ...editedScenarioDetails, previous_interaction: {...editedScenarioDetails.previous_interaction, context: e.target.value}})}
                                                        style={{ width: "100%", marginTop: "20px" }}
                                                        multiline
                                                        rows={3}
                                                    />
                                                    <TextField
                                                        label="Customer's first statement"
                                                        variant="outlined"
                                                        placeholder='The first statement that the customer speaks when a simulation starts. Avoid keeping this an objection.'
                                                        required
                                                        value={editedScenarioDetails.previous_interaction.customer_message}
                                                        onChange={(e) => setEditedScenarioDetails({ ...editedScenarioDetails, previous_interaction: {...editedScenarioDetails.previous_interaction, customer_message: e.target.value}})}
                                                        style={{ width: "100%", marginTop: "20px" }}
                                                        multiline
                                                        rows={2}
                                                    />
                                                    <div className="flex justify-center relative mt-5 items-center">
                                                            <Typography variant='h6'>Goals (Optional)</Typography>
                                                            <Button 
                                                                variant="contained" style={{ backgroundColor: "#393E46", position: "absolute", right: "0" }} 
                                                                onClick={() => setEditedScenarioDetails({ ...editedScenarioDetails, agenda: {...editedScenarioDetails.agenda, goals: [...editedScenarioDetails.agenda.goals, ""] } })}>
                                                                <AddIcon style={{ color: 'white' }} />
                                                            </Button>
                                                        </div>
                                                        {
                                                            editedScenarioDetails.agenda?.goals?.map((goal: string, index: number) => (
                                                                <div className="flex" key={index}>
                                                                    <TextField
                                                                        label={`Goal ${index + 1}`}
                                                                        variant="outlined"
                                                                        value={goal}
                                                                        onChange={(e) => {
                                                                            const goalsCopy = [...editedScenarioDetails.agenda.goals];
                                                                            goalsCopy[index] = e.target.value;
                                                                            setEditedScenarioDetails({ ...editedScenarioDetails, agenda: { ...editedScenarioDetails.agenda, goals: goalsCopy } });
                                                                        }}
                                                                        style={{ width: "100%", marginTop: "10px" }}
                                                                    />
                                                                    <Button
                                                                        sx={{ backgroundColor: "#FF0000", color: "white", marginTop: "10px", marginLeft: "5px" }}
                                                                        onClick={() => {
                                                                            const goalsCopy = [...editedScenarioDetails.agenda.goals];
                                                                            goalsCopy.splice(index, 1);
                                                                            setEditedScenarioDetails({ ...editedScenarioDetails, agenda: { ...editedScenarioDetails.agenda, goals: goalsCopy } });
                                                                        }}
                                                                    >
                                                                        <DeleteOutlineIcon />
                                                                    </Button>
                                                                </div>
                                                            ))
                                                        }
                                                        <div className="flex justify-center relative mt-5 items-center">
                                                            <Typography variant='h6'>Topics to cover (Optional)</Typography>
                                                            <Button 
                                                                variant="contained" style={{ backgroundColor: "#393E46", position: "absolute", right: "0" }} 
                                                                onClick={() => setEditedScenarioDetails({ ...editedScenarioDetails, agenda: {...editedScenarioDetails.agenda, topics: [...editedScenarioDetails.agenda.topics, ""] } })}>
                                                                <AddIcon style={{ color: 'white' }} />
                                                            </Button>
                                                        </div>
                                                        {
                                                            editedScenarioDetails.agenda?.topics?.map((topic: string, index: number) => (
                                                                <div className="flex" key={index}>
                                                                    <TextField
                                                                        label={`Topic ${index + 1}`}
                                                                        variant="outlined"
                                                                        value={topic}
                                                                        onChange={(e) => {
                                                                            const topicsCopy = [...editedScenarioDetails.agenda.topics];
                                                                            topicsCopy[index] = e.target.value;
                                                                            setEditedScenarioDetails({ ...editedScenarioDetails, agenda: { ...editedScenarioDetails.agenda, topics: topicsCopy } });
                                                                        }}
                                                                        style={{ width: "100%", marginTop: "10px" }}
                                                                    />
                                                                    <Button
                                                                        sx={{ backgroundColor: "#FF0000", color: "white", marginTop: "10px", marginLeft: "5px" }}
                                                                        onClick={() => {
                                                                            const topicsCopy = [...editedScenarioDetails.agenda.topics];
                                                                            topicsCopy.splice(index, 1);
                                                                            setEditedScenarioDetails({ ...editedScenarioDetails, agenda: { ...editedScenarioDetails.agenda, topics: topicsCopy } });
                                                                        }}
                                                                    >
                                                                        <DeleteOutlineIcon />
                                                                    </Button>
                                                                </div>
                                                            ))
                                                        }
                                                    <Typography variant='h6' style={{ marginTop: "20px", width: "100%", display: "flex", justifyContent: "center" }}>Choose Explicit Objections</Typography>
                                                    {fetchingObjections ? <Loader /> : (
                                                        <DataGrid
                                                            rows={explicitObjections}
                                                            columns={objectionColumns}
                                                            initialState={{
                                                                pagination: {
                                                                    paginationModel: { page: 0, pageSize: 5 },
                                                                },
                                                            }}
                                                            style={{ backgroundColor: "white", textAlign: "center" }}
                                                            disableColumnMenu={true}
                                                            components={{ Toolbar: GridToolbar }}
                                                            pageSizeOptions={[5, 10, 20, 50]}
                                                            getRowHeight={() => 'auto'}
                                                            disableColumnFilter
                                                            disableColumnSelector
                                                            checkboxSelection
                                                            disableRowSelectionOnClick
                                                            disableDensitySelector
                                                            slotProps={{
                                                                toolbar: {
                                                                    showQuickFilter: true,
                                                                },
                                                            }}
                                                            rowSelectionModel={selectionModelExplicit}
                                                            onRowSelectionModelChange={handleSelectionExplicit}
                                                        />
                                                    )}
                                                    <Typography variant='h6' style={{ marginTop: "20px", width: "100%", display: "flex", justifyContent: "center" }}>Choose Implicit Objections</Typography>
                                                    {fetchingObjections ? <Loader /> : (
                                                        <DataGrid
                                                            rows={implicitObjections}
                                                            columns={objectionColumns}
                                                            initialState={{
                                                                pagination: {
                                                                    paginationModel: { page: 0, pageSize: 5 },
                                                                },
                                                            }}
                                                            style={{ backgroundColor: "white", textAlign: "center" }}
                                                            disableColumnMenu={true}
                                                            components={{ Toolbar: GridToolbar }}
                                                            pageSizeOptions={[5, 10, 20, 50]}
                                                            getRowHeight={() => 'auto'}
                                                            disableColumnFilter
                                                            disableColumnSelector
                                                            checkboxSelection
                                                            disableRowSelectionOnClick
                                                            disableDensitySelector
                                                            slotProps={{
                                                                toolbar: {
                                                                    showQuickFilter: true,
                                                                },
                                                            }}
                                                            rowSelectionModel={selectionModelImplicit}
                                                            onRowSelectionModelChange={handleSelectionImplicit}
                                                        />
                                                    )}

                                                    <Typography variant='h6' style={{ marginTop: "20px", display: "flex", justifyContent: "center" }}>Customer Persona Details</Typography>
                                                    <div className="flex justify-between">
                                                        <TextField
                                                            label="Name"
                                                            variant="outlined"
                                                            required
                                                            value={editedScenarioDetails.customer_metadata.name}
                                                            onChange={(e) => setEditedScenarioDetails({ ...editedScenarioDetails, customer_metadata: { ...editedScenarioDetails.customer_metadata, name: e.target.value } })}
                                                            style={{ width: "49%", marginTop: "20px" }}
                                                        />
                                                        <TextField
                                                            label="Job Title"
                                                            variant="outlined"
                                                            required
                                                            value={editedScenarioDetails.customer_metadata.job_title}
                                                            onChange={(e) => setEditedScenarioDetails({ ...editedScenarioDetails, customer_metadata: { ...editedScenarioDetails.customer_metadata, job_title: e.target.value } })}
                                                            style={{ width: "49%", marginTop: "20px" }}
                                                        />
                                                    </div>
                                                    <div className="flex justify-between">
                                                        <TextField
                                                            label="Organization name"
                                                            variant="outlined"
                                                            required
                                                            value={editedScenarioDetails.customer_metadata.organization}
                                                            onChange={(e) => setEditedScenarioDetails({ ...editedScenarioDetails, customer_metadata: { ...editedScenarioDetails.customer_metadata, organization: e.target.value } })}
                                                            style={{ width: "49%", marginTop: "20px" }}
                                                        />
                                                        <TextField
                                                            label="Organization Industry"
                                                            variant="outlined"
                                                            required
                                                            value={editedScenarioDetails.customer_metadata.org_industry}
                                                            onChange={(e) => setEditedScenarioDetails({ ...editedScenarioDetails, customer_metadata: { ...editedScenarioDetails.customer_metadata, org_industry: e.target.value } })}
                                                            style={{ width: "49%", marginTop: "20px" }}
                                                        />
                                                    </div>
                                                    <div className="flex justify-between">
                                                        <TextField
                                                            label="Way of Speaking"
                                                            variant="outlined"
                                                            required
                                                            value={editedScenarioDetails.customer_metadata.way_of_speaking}
                                                            onChange={(e) => setEditedScenarioDetails({ ...editedScenarioDetails, customer_metadata: { ...editedScenarioDetails.customer_metadata, way_of_speaking: e.target.value } })}
                                                            style={{ width: "49%", marginTop: "20px" }}
                                                        />
                                                        <TextField
                                                            label="Behavior"
                                                            variant="outlined"
                                                            required
                                                            value={editedScenarioDetails.customer_metadata.behaviour}
                                                            onChange={(e) => setEditedScenarioDetails({ ...editedScenarioDetails, customer_metadata: { ...editedScenarioDetails.customer_metadata, behaviour: e.target.value } })}
                                                            style={{ width: "49%", marginTop: "20px" }}
                                                        />
                                                    </div>
                                                    <div className="flex justify-center relative mt-5 items-center">
                                                        <Typography variant='h6'>Customer QnA (Optional)</Typography>
                                                        <Button 
                                                            variant="contained" style={{ backgroundColor: "#393E46", position: "absolute", right: "0" }} 
                                                            onClick={() => setEditedScenarioDetails({ ...editedScenarioDetails, qna: [...editedScenarioDetails.qna, { question: "", answer: "" }] })}>
                                                            <AddIcon style={{ color: 'white' }} />
                                                        </Button>
                                                    </div>
                                                    {
                                                            editedScenarioDetails.qna?.map((qna: any, index: number) => (
                                                                <div key={index} className="flex justify-between items-center mt-3">
                                                                    <TextField
                                                                        label={`Question ${index + 1}`}
                                                                        variant="outlined"
                                                                        value={qna.question}
                                                                        onChange={(e) => {
                                                                            const qnaCopy = [...editedScenarioDetails.qna];
                                                                            qnaCopy[index].question = e.target.value;
                                                                            setEditedScenarioDetails({ ...editedScenarioDetails, qna: qnaCopy });
                                                                        }}
                                                                        style={{ width: "49%", marginRight: "5px" }}
                                                                    /> :
                                                                    <TextField
                                                                        label={`Answer ${index + 1}`}
                                                                        variant="outlined"
                                                                        value={qna.answer}
                                                                        onChange={(e) => {
                                                                            const qnaCopy = [...editedScenarioDetails.qna];
                                                                            qnaCopy[index].answer = e.target.value;
                                                                            setEditedScenarioDetails({ ...editedScenarioDetails, qna: qnaCopy });
                                                                        }}
                                                                        style={{ width: "49%", marginLeft: "5px" }}
                                                                    />
                                                                    <Button
                                                                        sx={{ backgroundColor: "#FF0000", color: "white", marginLeft: "5px", height: "100%" }}
                                                                        onClick={() => {
                                                                            const qnaCopy = [...editedScenarioDetails.qna];
                                                                            qnaCopy.splice(index, 1);
                                                                            setEditedScenarioDetails({ ...editedScenarioDetails, qna: qnaCopy });
                                                                        }}
                                                                    >
                                                                        <DeleteOutlineIcon />
                                                                    </Button>
                                                                </div>
                                                            ))
                                                        }
                                                    <div style={{ marginRight: "10px", marginTop: "20px", display: "flex", justifyContent: "center", width: "100%" }}>
                                                        {
                                                            isSubmitting ? <CircularProgress /> : (
                                                                    <Button onClick={handleSubmit} variant="contained" style={{ backgroundColor: "#45474B" }}>Submit</Button>
                                                                )
                                                            }
                                                    </div>
                                                </div>
                                            </Box>
                                        </Modal>
                                    </>
                                )
                            }
                        </div>
                    </div>
                </Box>
            </Modal>
        </div>
    );
};

export default ScenarioCard;

