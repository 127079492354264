import React, { useState, useEffect } from 'react';
import MultiActionAreaCard from '../components/MultiActionAreaCard';
import { useDispatch, useSelector } from 'react-redux'
import { setModules } from '../redux/moduleSlice';
import axios from 'axios';
import { CircularProgress, Typography } from '@mui/material';
import { REACT_APP_BACKEND_URL } from '../constants';
import { toast } from 'react-toastify';

interface ModulesProps {
  isManager: boolean
}
const Modules = ({isManager}: ModulesProps) => {
  const [loading, setLoading] = useState(true)
  const modules = useSelector((state: any) => state.modules.value)
  const dispatch = useDispatch()
  
  useEffect(() => {
    axios.get(`${REACT_APP_BACKEND_URL}/organization/${localStorage.getItem('org_id')}/module`, {
      headers: { 'Authorization': `Bearer ${localStorage.getItem('google_access_token')}` }
  })
      .then((response) => {
        console.log("modules are", response.data.modules)
        const modules_data: Record<string, any> = {}
        response.data.modules.forEach((module: any) => {
          modules_data[module.id] = module
        })
        dispatch(setModules(modules_data))
      })
      .catch((error) => {
        console.error(error)
        toast.error('Failed to fetch modules')
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  return (
    <div className="flex flex-col">
      <div>
        {
          loading ? (
            <div style={{minHeight: "90vh"}} className="flex justify-center items-center">
              <CircularProgress />
            </div>
          ) : (
            <div>
              <div className="w-full flex justify-center mb-3">
                  <Typography variant='h3'>Select a Training Module</Typography>
              </div>
              <div className="flex flex-wrap justify-center">
                {
                  Object.entries(modules).length === 0 && (
                    <div className="flex justify-center items-center">
                        <div className="text-xl absolute" style={{ top: "50%", color: "gray" }}>No modules found</div>
                    </div>
                  )
                }
                {
                  Object.entries(modules).map(([key, module]: [string, any]) => {
                    return (
                      <MultiActionAreaCard
                        key={key}
                        imageUrl={"https://www.salesforce.com/blog/wp-content/uploads/sites/2/2023/10/Sales_Quotas.jpg?w=889&h=500"}
                        imageAltText={module.name}
                        cardTitle={module.name}
                        cardText={module.description}
                        navigateTo={isManager ? `/manager/module/${module.id}` : `/module/${module.id}`}
                      />
                    )
                  })
                }
              </div>
            </div>
          )
        }
      </div>
    </div>
  );
}

export default Modules;