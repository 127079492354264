import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from "react-router-dom";
import { handleLogout } from '../../utils/auth';
import { ZOOM_AUTHORIZATION_URL, GMEET_AUTHORIZATION_URL } from '../../constants';
import { FormControl, Select, InputLabel, SvgIcon } from '@mui/material';
import { REACT_APP_BACKEND_URL } from '../../constants';
import axios from 'axios';
import Avatar from '@mui/material/Avatar';
import LogoutIcon from '@mui/icons-material/Logout';
import GroupIcon from '@mui/icons-material/Group';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import { useDispatch, useSelector } from 'react-redux';
import { setNavbarProducts, setAllProducts } from '../../redux/productSlice';
import "../../App.css";
import img from './logo.png'
import { toast } from 'react-toastify';
const pages: Record<string, any>[] = [
  { "title": "Products", "navigateTo": "/manager/products", "isManager": true },
  { "title": "Training", "navigateTo": "/manager/modules", "isManager": true },
];

function stringToColor(string: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

export function stringAvatar(name: string, size = "50px") {
  return {
    sx: {
      bgcolor: stringToColor(name),
      width: size,
      height: size
    },
    children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
  };
}
export default function Navbar() {
  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const products = useSelector((state: any) => state.products?.navbarProducts);

  const dispatch = useDispatch();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const fetchAllproducts = async () => {
    try {
      const response = await axios.get(`${REACT_APP_BACKEND_URL}/organization/${localStorage.getItem('org_id')}/product`, {
        headers: { 'Authorization': `Bearer ${localStorage.getItem('google_access_token')}` }
      });
      dispatch(setNavbarProducts(response.data.products));
      const products_data: Record<string, any> = {}
      response.data.products.forEach((product: any) => {
        products_data[product.id] = product
      })
      dispatch(setAllProducts(products_data));
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  }
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleProductChange = (productId: string) => {
    navigate(`/manager/product/${productId}`);
    window.location.reload();
  }

  useEffect(() => {
    window.addEventListener('orgChange', () => {
      console.log("Org ID change detected");
      fetchAllproducts();
    })
  }, []);

  useEffect(() => {
    const userProfile = JSON.parse(localStorage.getItem('userProfile') as string);
    const email = userProfile?.email
    const org_id = localStorage.getItem('org_id')

    if(!email || !org_id) return
    axios.get(
      `${REACT_APP_BACKEND_URL}/organization/${org_id}/person/${email}`,
      { headers: { 'Authorization': `Bearer ${localStorage.getItem('google_access_token')}` } }
    ).then((response) => {
      localStorage.setItem('is_manager', response.data.role.toLowerCase() === "vp" ? 'true' : 'false')
      localStorage.setItem('userProfile', JSON.stringify({...userProfile, orgs: response.data.user_profile.orgs}))
    }).catch(err => {
      console.log("error", err)
      toast.error("Error fetching user profile")
    })
  }, [])

  useEffect(() => {
    fetchAllproducts();
  }, [])

  return (
    <Box className="Navbar" sx={{ flexGrow: 1 }}>
      <div style={{ backgroundColor: "#F6F6F6", zIndex: '2' }}>
        <Toolbar>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => {
                if (!page.isManager || localStorage.getItem('is_manager') === "true") return (
                  <MenuItem key={page.title} onClick={handleCloseNavMenu}>
                    <Typography textAlign="center">{page.title}</Typography>
                  </MenuItem>
                )
              })}
            </Menu>
          </Box>

          <Button sx={{ color: 'white', display: 'block' }} onClick={() => navigate('/')}>
            <img src={img} alt="logo" style={{ height: "90px", mixBlendMode: "darken" }} />
          </Button>

          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, marginLeft: "40px" }}>
            {pages.map((page) => {
              if (!page.isManager || localStorage.getItem('is_manager') === "true") return (
                <Button
                  key={page.title}
                  onClick={() => navigate(page.navigateTo)}
                  sx={{
                    my: 2,
                    color: 'black',
                    display: 'block',
                    marginLeft: "10px",
                    backgroundColor: page.navigateTo === window.location.pathname ? "#EEEEEE" : "transparent",
                  }}
                >
                  {page.title}
                </Button>
              )
            })}
          </Box>
          {!localStorage.getItem('google_access_token') ? <></> :
            <>
              {(window.location.href.includes('manager/product/')) &&
                <FormControl style={{ minWidth: "200px", color: "black", marginRight: "20px" }}>
                  <InputLabel sx={{ color: "black", fontSize: "20px" }} >Product</InputLabel>
                  <Select
                    value={window.location.href.split('/').pop()}
                    sx={{
                      fontSize: "20px",
                      '.MuiOutlinedInput-notchedOutline': {
                        borderColor: 'black',
                        border: "2px solid #393E46"
                      }
                    }}
                    onChange={(e) => handleProductChange(e.target.value as string)}
                    label="Product"
                    maxRows={5}
                  >
                    {
                      products?.map((product: any) => (
                        <MenuItem key={product.id} value={product.id}>{product.name}</MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              }
              <div>
                <IconButton onClick={handleClick} >
                  <Avatar {...stringAvatar(JSON.parse(localStorage.getItem('userProfile') || '{}').name)} />
                </IconButton>
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  {
                    localStorage.getItem('is_manager') === "true" && (
                        <MenuItem onClick={() => { handleClose(); navigate('/manager/members') }}><GroupIcon style={{ marginRight: "8px" }} />Members</MenuItem>
                    )
                  }
                  <MenuItem onClick={() => { handleClose(); navigate('/settings') }}><CorporateFareIcon style={{ marginRight: "8px" }} />Org Settings</MenuItem>
                  <a href={ZOOM_AUTHORIZATION_URL} style={{ textDecoration: "none", color: "black" }}>
                    <MenuItem>
                      <SvgIcon style={{ marginRight: "8px", width: "30px" }}>
                        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100%" viewBox="0 0 96 96" >
                          <path fill="#094DD5" opacity="1.000000" stroke="none" d="M1.000000,59.000000 	C1.000000,56.299370 1.000000,53.598740 1.374842,50.453995 	C5.868902,52.419891 9.988119,54.829899 14.068933,57.636452 	C13.338191,62.438683 15.629438,63.942177 19.637072,63.884670 	C21.423323,63.859039 23.212080,64.008026 25.041088,64.422073 	C26.650887,66.141243 28.051245,67.801506 29.816996,68.841217 	C37.194260,73.185112 44.752701,77.222725 52.107021,81.603783 	C58.845764,85.618126 65.407555,89.929520 71.848007,94.448959 	C68.780449,95.230240 65.912193,95.672585 62.999226,95.756538 	C61.932961,94.412529 61.057510,93.177841 59.867325,92.479698 	C47.487114,85.217575 35.090763,77.981339 22.626194,70.865265 	C15.486530,66.789207 8.213168,62.947319 1.000000,59.000000 z" />
                          <path fill="#2C75F7" opacity="1.000000" stroke="none" d="M97.000000,39.000000 	C97.000000,40.066788 97.000000,41.133575 96.623283,42.595055 	C94.289566,42.207474 92.337029,41.413830 90.374550,40.645542 	C87.250122,39.422356 84.119072,38.216068 80.663132,36.857597 	C77.219460,35.721287 74.103477,34.730011 70.981125,33.382843 	C70.131920,31.931702 69.515915,30.448948 68.410667,29.802019 	C58.876373,24.221296 49.177795,18.918869 39.689785,13.262430 	C34.938759,10.430027 30.523756,7.033991 26.158245,3.563355 	C28.904982,2.878710 31.450253,2.523581 34.058640,2.497626 	C35.229916,3.781427 36.220901,4.938186 37.464523,5.659162 	C48.884975,12.280038 60.304684,18.904715 71.815628,25.366135 	C80.138878,30.038206 88.600235,34.464241 97.000000,39.000000 z" />
                          <path fill="#094BD0" opacity="1.000000" stroke="none" d="M1.000000,59.399994 	C8.213168,62.947319 15.486530,66.789207 22.626194,70.865265 	C35.090763,77.981339 47.487114,85.217575 59.867325,92.479698 	C61.057510,93.177841 61.932961,94.412529 62.979729,95.977386 	C63.004940,96.556625 63.000000,97.000000 63.000000,97.000000 	C60.299988,97.000000 57.599972,97.000000 54.399708,96.658134 	C52.678448,95.250786 51.591042,93.955338 50.215355,93.156082 	C38.932915,86.601204 27.653072,80.037491 16.249189,73.698181 	C11.715122,71.177742 6.870237,69.216438 2.093630,66.628105 	C1.680270,65.170418 1.340135,64.085213 1.000000,63.000000 	C1.000000,61.933331 1.000000,60.866661 1.000000,59.399994 z" />
                          <path fill="#FFFFFF" opacity="1.000000" stroke="none" d="M33.995525,2.168452 	C31.450253,2.523581 28.904982,2.878710 25.804762,3.620065 	C23.151470,5.301370 21.053127,6.596449 18.611626,7.947171 	C16.497402,9.297571 14.726336,10.592327 12.641619,12.032917 	C10.513551,14.770483 8.699133,17.362215 6.570374,20.120569 	C5.223661,23.190914 4.191290,26.094637 2.989719,29.340591 	C2.213679,31.455214 1.606840,33.227608 1.000000,35.000000 	C1.000000,23.740095 1.000000,12.480190 1.000000,1.000000 	C12.019917,1.000000 23.041300,1.000000 34.474770,1.271164 	C34.589745,1.751036 34.292637,1.959744 33.995525,2.168452 z" />
                          <path fill="#FFFFFF" opacity="1.000000" stroke="none" d="M1.000000,63.468658 	C1.340135,64.085213 1.680270,65.170418 2.141110,66.997284 	C3.469049,70.506523 4.676281,73.274124 5.939698,76.398201 	C8.663563,80.170296 11.029380,83.886169 14.063863,86.936935 	C19.791193,92.695000 27.353409,95.024536 35.000000,97.000000 	C23.740093,97.000000 12.480186,97.000000 1.000000,97.000000 	C1.000000,85.980080 1.000000,74.958702 1.000000,63.468658 z" />
                          <path fill="#FFFFFF" opacity="1.000000" stroke="none" d="M63.468658,97.000000 	C63.000000,97.000000 63.004940,96.556625 63.024437,96.335785 	C65.912193,95.672585 68.780449,95.230240 72.203865,94.397133 	C74.856056,92.708351 76.953087,91.410332 79.404236,90.061493 	C82.213036,87.687386 84.667725,85.364098 87.432671,82.906540 	C89.200600,80.529556 90.658264,78.286842 92.435379,75.874634 	C93.542747,73.151207 94.330681,70.597267 95.444412,67.861908 	C96.180130,66.120323 96.590065,64.560158 97.000000,63.000000 	C97.000000,74.259911 97.000000,85.519814 97.000000,97.000000 	C85.980080,97.000000 74.958702,97.000000 63.468658,97.000000 z" />
                          <path fill="#FFFFFF" opacity="1.000000" stroke="none" d="M63.000000,1.000000 	C74.259903,1.000000 85.519814,1.000000 97.000000,1.000000 	C97.000000,12.019917 97.000000,23.041300 96.696365,34.434456 	C96.205643,34.206776 96.018539,33.607327 95.900528,32.636417 	C95.591423,30.845625 95.213242,29.426294 94.894592,27.645699 	C93.339256,24.508558 91.724396,21.732681 90.056488,18.604557 	C87.916222,15.815053 85.963692,13.244873 83.715347,10.966620 	C78.020798,5.196312 70.517906,3.053255 63.000000,1.000000 z" />
                          <path fill="#0949CA" opacity="1.000000" stroke="none" d="M5.883514,76.041718 	C4.676281,73.274124 3.469049,70.506523 2.214337,67.369751 	C6.870237,69.216438 11.715122,71.177742 16.249189,73.698181 	C27.653072,80.037491 38.932915,86.601204 50.215355,93.156082 	C51.591042,93.955338 52.678448,95.250786 53.949730,96.658134 	C50.307091,97.000000 46.614182,97.000000 42.410557,96.658691 	C33.773575,91.292885 25.731970,86.123260 17.482206,81.310455 	C13.833282,79.181725 9.762688,77.775780 5.883514,76.041718 z" />
                          <path fill="#347BF5" opacity="1.000000" stroke="none" d="M95.831444,33.007877 	C96.018539,33.607327 96.205643,34.206776 96.696365,34.903114 	C97.000000,36.066669 97.000000,37.133339 97.000000,38.600006 	C88.600235,34.464241 80.138878,30.038206 71.815628,25.366135 	C60.304684,18.904715 48.884975,12.280038 37.464523,5.659162 	C36.220901,4.938186 35.229916,3.781427 34.058640,2.497626 	C34.292637,1.959744 34.589745,1.751036 34.943428,1.271164 	C37.041668,1.000000 39.083332,1.000000 41.599770,1.349456 	C43.361423,2.914458 44.474480,4.426861 45.962814,5.298381 	C57.415081,12.004462 68.896294,18.664186 80.463051,25.170250 	C85.470932,27.987087 90.701843,30.407433 95.831444,33.007877 z" />
                          <path fill="#3B81F3" opacity="1.000000" stroke="none" d="M95.900528,32.636417 	C90.701843,30.407433 85.470932,27.987087 80.463051,25.170250 	C68.896294,18.664186 57.415081,12.004462 45.962814,5.298381 	C44.474480,4.426861 43.361423,2.914458 42.037270,1.349456 	C44.370556,1.000000 46.741116,1.000000 49.605904,1.341313 	C51.475300,2.820211 52.728672,4.164037 54.246342,5.060327 	C61.518135,9.354825 68.834854,13.575288 76.187592,17.730070 	C82.366272,21.221434 88.616501,24.586170 94.835060,28.006966 	C95.213242,29.426294 95.591423,30.845625 95.900528,32.636417 z" />
                          <path fill="#0847C3" opacity="1.000000" stroke="none" d="M5.939698,76.398201 	C9.762688,77.775780 13.833282,79.181725 17.482206,81.310455 	C25.731970,86.123260 33.773575,91.292885 41.949921,96.658691 	C39.958332,97.000000 37.916668,97.000000 35.437500,97.000000 	C27.353409,95.024536 19.791193,92.695000 14.063863,86.936935 	C11.029380,83.886169 8.663563,80.170296 5.939698,76.398201 z" />
                          <path fill="#4286F1" opacity="1.000000" stroke="none" d="M94.894592,27.645700 	C88.616501,24.586170 82.366272,21.221434 76.187592,17.730070 	C68.834854,13.575288 61.518135,9.354825 54.246342,5.060327 	C52.728672,4.164037 51.475300,2.820211 50.050064,1.341313 	C52.700012,1.000000 55.400028,1.000000 58.600296,1.341858 	C68.943336,7.439094 78.782074,13.201434 88.644585,18.922792 	C89.014870,19.137598 89.615982,18.954487 90.109528,18.956806 	C91.724396,21.732681 93.339256,24.508558 94.894592,27.645700 z" />
                          <path fill="#0F58EB" opacity="1.000000" stroke="none" d="M1.000000,35.375000 	C1.606840,33.227608 2.213679,31.455214 3.353284,29.347462 	C7.310509,30.701563 10.734968,32.391026 14.090349,34.459106 	C14.260347,38.851025 16.771544,40.167057 20.371967,40.175339 	C23.617290,40.182808 26.862646,40.176914 31.486677,40.176914 	C27.387131,44.097008 24.220753,47.124783 21.007730,49.791195 	C14.307391,45.619888 7.653696,41.809944 1.000000,38.000000 	C1.000000,37.250000 1.000000,36.500000 1.000000,35.375000 z" />
                          <path fill="#0A52E1" opacity="1.000000" stroke="none" d="M1.000000,38.460636 	C7.653696,41.809944 14.307391,45.619888 20.875557,50.090553 	C18.562464,52.914150 16.334900,55.077030 14.107337,57.239906 	C9.988119,54.829899 5.868902,52.419891 1.374842,50.004944 	C1.000000,46.307091 1.000000,42.614182 1.000000,38.460636 z" />
                          <path fill="#4A8CEF" opacity="1.000000" stroke="none" d="M90.056488,18.604557 	C89.615982,18.954487 89.014870,19.137598 88.644585,18.922792 	C78.782074,13.201434 68.943336,7.439094 59.050278,1.341858 	C60.066669,1.000000 61.133339,1.000000 62.600006,1.000000 	C70.517906,3.053255 78.020798,5.196312 83.715347,10.966620 	C85.963692,13.244873 87.916222,15.815053 90.056488,18.604557 z" />
                          <path fill="#0D5DFD" opacity="1.000000" stroke="none" d="M97.000000,62.600121 	C96.590065,64.560158 96.180130,66.120323 95.079559,67.817154 	C90.865997,66.609962 87.343056,65.266083 83.928009,63.487732 	C84.153641,60.372643 84.271393,57.692036 84.758804,55.013275 	C89.085648,56.343418 93.042824,57.671707 97.000000,59.000000 	C97.000000,60.066746 97.000000,61.133492 97.000000,62.600121 z" />
                          <path fill="#2A73FA" opacity="1.000000" stroke="none" d="M80.990822,37.002632 	C84.119072,38.216068 87.250122,39.422356 90.374550,40.645542 	C92.337029,41.413830 94.289566,42.207474 96.623283,42.994873 	C97.000000,45.370556 97.000000,47.741116 96.625610,50.549759 	C92.293716,49.332001 88.336212,47.676159 84.272980,45.635136 	C83.108437,42.500847 82.049629,39.751740 80.990822,37.002632 z" />
                          <path fill="#1765FC" opacity="1.000000" stroke="none" d="M84.378708,46.020317 	C88.336212,47.676159 92.293716,49.332001 96.625610,50.993919 	C97.000000,53.370556 97.000000,55.741116 97.000000,58.555836 	C93.042824,57.671707 89.085648,56.343418 84.663528,54.571686 	C84.258629,51.425606 84.318672,48.722961 84.378708,46.020317 z" />
                          <path fill="#F3F7FE" opacity="1.000000" stroke="none" d="M14.068933,57.636456 	C16.334900,55.077030 18.562464,52.914150 20.922201,50.451912 	C24.220753,47.124783 27.387131,44.097008 31.486677,40.176914 	C26.862646,40.176914 23.617290,40.182808 20.371967,40.175339 	C16.771544,40.167057 14.260347,38.851025 14.543858,34.481651 	C19.376995,34.058720 23.687546,33.991863 28.440384,34.011475 	C30.540098,34.109608 32.197777,34.106735 33.854908,34.135559 	C39.775650,34.238552 41.131653,36.364685 38.550236,42.079220 	C36.150635,44.423714 34.050285,46.637646 31.641644,48.991257 	C28.634090,51.670853 25.934828,54.210770 23.182972,56.800171 	C29.605280,60.134857 38.357483,53.531532 42.289635,63.378963 	C40.857536,63.681396 39.927994,63.877705 38.540352,63.990086 	C33.721401,63.963360 29.360550,64.020561 24.999699,64.077759 	C23.212080,64.008026 21.423323,63.859039 19.637072,63.884670 	C15.629438,63.942177 13.338191,62.438683 14.068933,57.636456 z" />
                          <path fill="#094FDB" opacity="1.000000" stroke="none" d="M25.041088,64.422073 	C29.360550,64.020561 33.721401,63.963360 38.595840,64.325859 	C46.799225,69.523544 54.520283,74.252174 62.168499,79.095833 	C67.844719,82.690598 73.426361,86.434715 79.050125,90.112312 	C76.953087,91.410332 74.856056,92.708351 72.403168,94.058197 	C65.407555,89.929520 58.845764,85.618126 52.107021,81.603783 	C44.752701,77.222725 37.194260,73.185112 29.816996,68.841217 	C28.051245,67.801506 26.650887,66.141243 25.041088,64.422073 z" />
                          <path fill="#FAFCFF" opacity="1.000000" stroke="none" d="M84.272980,45.635139 	C84.318672,48.722961 84.258629,51.425606 84.293869,54.569836 	C84.271393,57.692036 84.153641,60.372643 83.581345,63.427452 	C79.286530,62.842758 77.845078,60.246330 77.968452,56.458199 	C78.038231,54.315918 77.890297,52.166550 77.916115,49.597176 	C77.998802,48.350883 77.988998,47.527245 78.015648,46.704788 	C78.140297,42.858307 76.635071,39.998459 72.515045,40.103855 	C68.534538,40.205681 66.752548,42.779663 67.309731,47.278755 	C67.271690,52.833733 67.301414,57.921375 67.092224,63.260071 	C61.004868,63.397533 61.099247,59.077965 60.910213,54.555973 	C60.987049,51.131046 61.163242,48.148258 60.948349,45.193920 	C60.712559,41.952209 58.634689,39.531658 55.513771,40.230370 	C53.484821,40.684608 51.276386,43.020607 50.377235,45.056320 	C49.537712,46.957035 50.320469,49.574287 50.313377,52.283768 	C50.092422,55.317913 49.947258,57.944439 49.515411,60.787025 	C47.733902,62.041214 46.239075,63.079338 43.831074,64.751648 	C43.831074,60.959095 43.831074,58.480713 43.901424,55.543648 	C43.966724,51.057381 43.961674,47.029797 44.129272,42.697632 	C46.546593,39.746147 48.791260,37.099243 51.406898,34.384361 	C54.492168,34.293987 57.206474,34.271591 60.135220,34.505173 	C61.718880,35.544613 63.088097,36.328075 63.775078,36.721161 	C66.604050,35.551346 68.795776,34.645039 70.987503,33.738731 	C74.103477,34.730011 77.219460,35.721287 80.663132,36.857597 	C82.049629,39.751740 83.108437,42.500847 84.272980,45.635139z" />
                          <path fill="#216DFA" opacity="1.000000" stroke="none" d="M70.981125,33.382843 	C68.795776,34.645039 66.604050,35.551346 63.775078,36.721161 	C63.088097,36.328075 61.718880,35.544613 60.120392,34.145672 	C56.281734,31.029541 52.776623,28.359501 49.042801,26.060984 	C39.065922,19.919281 28.990841,13.937100 18.954784,7.891528 	C21.053127,6.596449 23.151470,5.301370 25.603298,3.949582 	C30.523756,7.033991 34.938759,10.430027 39.689785,13.262430 	C49.177795,18.918869 58.876373,24.221296 68.410667,29.802019 	C69.515915,30.448948 70.131920,31.931702 70.981125,33.382843 z" />
                          <path fill="#0D5DFD" opacity="1.000000" stroke="none" d="M51.035927,34.452339 	C48.791260,37.099243 46.546593,39.746147 43.753036,42.691307 	C41.752594,42.642593 40.301041,42.295624 38.849483,41.948654 	C41.131653,36.364685 39.775650,34.238552 33.854908,34.135559 	C32.197777,34.106735 30.540098,34.109608 28.401176,33.665794 	C26.956415,32.163406 26.182873,30.766830 24.997911,30.077911 	C19.019094,26.601921 12.933103,23.310274 6.884715,19.953947 	C8.699133,17.362215 10.513551,14.770483 12.828903,12.321163 	C25.898535,19.793163 38.467232,27.122751 51.035927,34.452339 z" />
                          <path fill="#1665FC" opacity="1.000000" stroke="none" d="M51.406895,34.384361 	C38.467232,27.122751 25.898535,19.793163 13.142553,12.175329 	C14.726336,10.592327 16.497402,9.297571 18.611626,7.947172 	C28.990841,13.937100 39.065922,19.919281 49.042801,26.060984 	C52.776623,28.359501 56.281734,31.029541 59.905952,33.889690 	C57.206474,34.271591 54.492168,34.293987 51.406895,34.384361 z" />
                          <path fill="#0B59F5" opacity="1.000000" stroke="none" d="M6.570374,20.120569 	C12.933103,23.310274 19.019094,26.601921 24.997911,30.077911 	C26.182873,30.766830 26.956415,32.163406 27.958889,33.579323 	C23.687546,33.991863 19.376995,34.058720 14.612936,34.103035 	C10.734968,32.391026 7.310509,30.701563 3.522485,29.005230 	C4.191290,26.094637 5.223661,23.190914 6.570374,20.120569 z" />
                          <path fill="#0C54E5" opacity="1.000000" stroke="none" d="M43.831074,56.002331 	C43.831074,58.480713 43.831074,60.959095 43.831074,64.751648 	C46.239075,63.079338 47.733902,62.041214 49.596867,61.159798 	C53.866631,63.922600 57.634403,66.762802 61.697800,69.085930 	C70.089233,73.883492 78.638229,78.405464 87.122406,83.040802 	C84.667725,85.364098 82.213036,87.687386 79.404236,90.061493 	C73.426361,86.434715 67.844719,82.690598 62.168499,79.095833 	C54.520283,74.252174 46.799225,69.523544 39.053940,64.409790 	C39.927994,63.877705 40.857536,63.681396 42.289635,63.378963 	C38.357483,53.531532 29.605280,60.134857 23.182972,56.800171 	C25.934828,54.210770 28.634090,51.670853 31.696802,49.342979 	C35.983856,51.704128 39.907463,53.853230 43.831074,56.002331 z" />
                          <path fill="#0A56ED" opacity="1.000000" stroke="none" d="M87.432671,82.906540 	C78.638229,78.405464 70.089233,73.883492 61.697800,69.085930 	C57.634403,66.762802 53.866631,63.922600 49.883553,60.943733 	C49.947258,57.944439 50.092422,55.317913 50.738976,52.323944 	C54.439304,52.974548 57.638248,53.992599 60.837193,55.010651 	C61.099247,59.077965 61.004868,63.397533 67.446541,63.267014 	C69.697380,63.688141 71.452080,64.180756 72.995674,65.048759 	C79.403778,68.652214 85.748161,72.368958 92.115936,76.044121 	C90.658264,78.286842 89.200600,80.529556 87.432671,82.906540 z" />
                          <path fill="#0B5AF7" opacity="1.000000" stroke="none" d="M92.435379,75.874634 	C85.748161,72.368958 79.403778,68.652214 72.995674,65.048759 	C71.452080,64.180756 69.697380,63.688141 67.685448,63.015953 	C67.301414,57.921375 67.271690,52.833733 67.735092,47.336250 	C71.432281,47.957733 74.636353,48.989052 77.840424,50.020374 	C77.890297,52.166550 78.038231,54.315918 77.968452,56.458199 	C77.845078,60.246330 79.286530,62.842758 83.473457,63.861931 	C87.343056,65.266083 90.865997,66.609962 94.753777,67.998581 	C94.330681,70.597267 93.542747,73.151207 92.435379,75.874634 z" />
                          <path fill="#0B57EF" opacity="1.000000" stroke="none" d="M43.901424,55.543648 	C39.907463,53.853230 35.983856,51.704128 32.005089,49.203300 	C34.050285,46.637646 36.150635,44.423714 38.550236,42.079216 	C40.301041,42.295624 41.752594,42.642593 43.580387,42.995888 	C43.961674,47.029797 43.966724,51.057381 43.901424,55.543648 z" />
                          <path fill="#135FF9" opacity="1.000000" stroke="none" d="M60.910213,54.555969 	C57.638248,53.992599 54.439304,52.974548 50.814766,51.916325 	C50.320469,49.574287 49.537712,46.957035 50.377235,45.056320 	C51.276386,43.020607 53.484821,40.684608 55.513771,40.230370 	C58.634689,39.531658 60.712559,41.952209 60.948349,45.193920 	C61.163242,48.148258 60.987049,51.131046 60.910213,54.555969 z" />
                          <path fill="#206BFC" opacity="1.000000" stroke="none" d="M77.916115,49.597176 	C74.636353,48.989052 71.432281,47.957733 67.802856,46.868916 	C66.752548,42.779663 68.534538,40.205681 72.515045,40.103855 	C76.635071,39.998459 78.140297,42.858307 78.015648,46.704788 	C77.988998,47.527245 77.998802,48.350883 77.916115,49.597176 z" />
                        </svg>
                      </SvgIcon>

                      Connect Zoom

                    </MenuItem>
                  </a>
                  <a href={GMEET_AUTHORIZATION_URL} style={{ textDecoration: "none", color: "black" }}>
                    <MenuItem>
                      <SvgIcon style={{ marginRight: "8px", width: "30px" }}>
                        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100%" viewBox="0 0 192 192">
                          <path fill="#FFFFFF" opacity="1.000000" stroke="none"
                            d="M113.000000,193.000000 	C75.333336,193.000000 38.166672,193.000000 1.000005,193.000000 	C1.000003,129.000000 1.000003,65.000015 1.000002,1.000014 	C64.999992,1.000009 128.999985,1.000009 192.999969,1.000005 	C192.999985,64.999985 192.999985,128.999969 193.000000,192.999969 	C166.500000,193.000000 140.000000,193.000000 113.000000,193.000000 M10.126808,66.528175 C10.138393,87.031410 10.149979,107.534637 10.023902,128.961472 	C10.017161,138.287552 9.902549,147.615219 10.038879,156.939209 	C10.142108,163.999207 14.983859,168.876022 22.034325,168.955460 C31.688053,169.064240 41.344994,168.887680 51.928139,168.975571 	C79.401108,168.983643 106.874077,168.997269 134.347046,168.996902 C144.557465,168.996780 148.946762,164.648148 148.993057,154.467331 C149.026703,147.065506 148.999634,139.663391 148.999634,131.155914 	C157.692688,138.535706 165.363800,145.345612 173.413818,151.673508 C175.437668,153.264404 179.259674,154.589905 181.193069,153.708160 C183.147827,152.816681 184.823303,149.043488 184.841827,146.519608 	C185.079987,114.053223 185.081482,81.583862 184.837662,49.117554 C184.818909,46.619865 183.083588,43.083622 181.074402,41.978397 	C179.407486,41.061432 175.654694,42.345497 173.701019,43.816593 C165.337646,50.114124 157.298233,56.841896 149.022385,62.415642 C149.014679,55.087673 149.013412,47.759689 148.997757,40.431740 	C148.974167,29.390560 144.622971,25.010574 133.610840,25.005003 C107.629631,24.991856 81.648399,24.989950 55.667236,25.026867 C54.059326,25.029152 52.451946,25.407200 50.233719,25.930225 C37.034962,39.235001 23.836205,52.539776 10.126808,66.528175 z" />
                          <path fill="#34A853" opacity="1.000000" stroke="none"
                            d="M149.140717,63.412804 C157.298233,56.841896 165.337646,50.114124 173.701019,43.816593 	C175.654694,42.345497 179.407486,41.061432 181.074402,41.978397 	C183.083588,43.083622 184.818909,46.619865 184.837662,49.117554 C185.081482,81.583862 185.079987,114.053223 184.841827,146.519608 	C184.823303,149.043488 183.147827,152.816681 181.193069,153.708160 C179.259674,154.589905 175.437668,153.264404 173.413818,151.673508 C165.363800,145.345612 157.692688,138.535706 148.999634,131.155914 	C148.999634,139.663391 149.026703,147.065506 148.993057,154.467331 C148.946762,164.648148 144.557465,168.996780 134.347046,168.996902 	C106.874077,168.997269 79.401108,168.983643 51.464096,168.446899 C50.967064,154.700668 50.934078,141.483109 50.987862,128.203888 	C51.074631,128.142227 51.166431,127.950150 51.622238,127.971115 	C70.975365,127.992073 89.872681,127.992073 109.274719,127.992073 C109.274719,117.526611 109.274719,107.733826 109.604881,97.988197 C122.594337,108.696136 135.253632,119.356918 148.558716,130.561539 	C148.803619,128.572815 148.984863,127.784256 148.985535,126.995537 C149.002335,107.556480 149.017288,88.117355 148.960388,68.678467 	C148.956787,67.444824 148.367950,66.212891 148.145874,64.758034 C148.540466,64.161537 148.840591,63.787174 149.140717,63.412804 z" />
                          <path fill="#FBBC04" opacity="1.000000" stroke="none"
                            d="M149.081543,62.914227 C148.840591,63.787174 148.540466,64.161537 147.826141,64.877342 	C134.714645,75.747421 122.017349,86.276054 109.197311,96.298325 	C109.074570,85.922234 109.074570,76.052505 109.074570,66.147911 C89.192726,66.147911 70.132492,66.147911 51.007370,66.087036 	C50.942493,66.026169 50.857628,65.869789 50.911629,65.413055 C50.925190,51.841026 50.884754,38.725723 50.844322,25.610418 C52.451946,25.407200 54.059326,25.029152 55.667236,25.026867 	C81.648399,24.989950 107.629631,24.991856 133.610840,25.005003 	C144.622971,25.010574 148.974167,29.390560 148.997757,40.431740 C149.013412,47.759689 149.014679,55.087673 149.081543,62.914227 z" />
                          <path fill="#4285F4" opacity="1.000000" stroke="none"
                            d="M10.161564,128.037872 C10.149979,107.534637 10.138393,87.031410 10.876650,66.251343 C24.703537,65.939606 37.780582,65.904694 50.857628,65.869789 	C50.857628,65.869789 50.942493,66.026169 50.977516,66.545074 C51.063835,87.359375 51.115135,107.654762 51.166431,127.950150 	C51.166431,127.950150 51.074631,128.142227 50.536888,128.108246 	C36.719948,128.062134 23.440756,128.050003 10.161564,128.037872 z" />
                          <path fill="#1967D2" opacity="1.000000" stroke="none"
                            d="M10.092733,128.499664 C23.440756,128.050003 36.719948,128.062134 50.450115,128.169922 C50.934078,141.483109 50.967064,154.700668 51.000305,168.375824 	C41.344994,168.887680 31.688053,169.064240 22.034325,168.955460 	C14.983859,168.876022 10.142108,163.999207 10.038879,156.939209 	C9.902549,147.615219 10.017161,138.287552 10.092733,128.499664 z" />
                          <path fill="#EA4335" opacity="1.000000" stroke="none"
                            d="M50.911629,65.413055 C37.780582,65.904694 24.703537,65.939606 11.131970,65.909531 	C23.836205,52.539776 37.034962,39.235001 50.539017,25.770321 	C50.884754,38.725723 50.925190,51.841026 50.911629,65.413055 z" />
                          <path fill="#FFFFFF" opacity="1.000000" stroke="none"
                            d="M51.622238,127.971115 C51.115135,107.654762 51.063835,87.359375 51.042393,66.605949 	C70.132492,66.147911 89.192726,66.147911 109.074570,66.147911 C109.074570,76.052505 109.074570,85.922234 109.151703,96.484604 	C109.157845,97.436989 109.173141,97.691589 109.274719,97.941040 C109.274719,107.733826 109.274719,117.526611 109.274719,127.992073 	C89.872681,127.992073 70.975365,127.992073 51.622238,127.971115 z" />
                          <path fill="#198038" opacity="1.000000" stroke="none"
                            d="M109.604881,97.988197 C109.173141,97.691589 109.157845,97.436989 109.274452,96.990974 	C122.017349,86.276054 134.714645,75.747421 147.731659,65.099480 	C148.367950,66.212891 148.956787,67.444824 148.960388,68.678467 C149.017288,88.117355 149.002335,107.556480 148.985535,126.995537 	C148.984863,127.784256 148.803619,128.572815 148.558716,130.561539 C135.253632,119.356918 122.594337,108.696136 109.604881,97.988197 z" />
                        </svg>
                      </SvgIcon>

                      Connect GMeet

                    </MenuItem>
                  </a>
                  <MenuItem onClick={() => { handleClose(); handleLogout(); localStorage.removeItem('org_id'); }}><LogoutIcon style={{ marginRight: "8px" }} />Logout</MenuItem>
                </Menu>
              </div>
            </>
          }
        </Toolbar>
      </div>
    </Box>
  );
}
