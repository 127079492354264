import React, { useState} from 'react';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';

interface DeleteWithConfirmationProps {
    handleDelete: () => void;
    subject: string;
    description: string;
}
const DeleteWithConfirmation: React.FC<DeleteWithConfirmationProps> = ({handleDelete, subject, description}) => {
    const [openDeletePopup, setOpenDeletePopup] = useState(false)
    return(
        <div>
            <Dialog
                open={openDeletePopup}
                onClose={() => setOpenDeletePopup(false)}
            >
                <DialogTitle id="alert-dialog-title">
                    {subject}
                </DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {description}
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button 
                    onClick={() =>{
                        handleDelete()
                        setOpenDeletePopup(false)
                    }}
                >
                    Yes
                </Button>
                <Button onClick={() => setOpenDeletePopup(false)} autoFocus>
                    No
                </Button>
                </DialogActions>
            </Dialog>
        <Button variant="contained" sx={{margin: '0', background: "#393E46"}} onClick={() => setOpenDeletePopup(true)}><DeleteOutlineIcon /></Button>
        </div>
    )
}
export default DeleteWithConfirmation;