import React, {useEffect, useState} from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import axios from "axios";
import { REACT_APP_BACKEND_URL } from "../constants";
import { toast } from "react-toastify";
import { CircularProgress, IconButton, Typography } from "@mui/material";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

export default function Simulations() {
    const { scenarioId } = useParams()
    const scenarios = useSelector((state: any) => state.scenarios.value)
    const [ simulations, setSimulations ] = useState<any>([])
    const [loading, setLoading] = useState<boolean>(true)

    const handleViewSimulation = (simulationId: string, simulationRepEmail: string) => {
        if (scenarioId === undefined) return
        window.open(`/product/${scenarios[scenarioId]?.product_id}/module/${scenarios[scenarioId]?.module_id}/scenario/${scenarioId}/rep_email/${simulationRepEmail}/simulation/${simulationId}/view`, '_blank')
    }

    useEffect(() => {
        if(scenarioId !== undefined && scenarios[scenarioId] !== undefined){
            console.log("scenarioId", scenarioId)
            const email = JSON.parse(localStorage.getItem('userProfile') as string).email
            let url = `${REACT_APP_BACKEND_URL}/organization/${localStorage.getItem('org_id')}/product/${scenarios[scenarioId].product_id}/module/${scenarios[scenarioId].module_id}/scenario/${scenarioId}/simulation`
            if (localStorage.getItem('is_manager') !== "true") url += `?rep_email=${email}`
            axios.get(url, {
                headers: { 'Authorization': `Bearer ${localStorage.getItem('google_access_token')}` }
            })
                .then((response) => {
                    console.log("simulations are", response.data.simulations)
                    setSimulations(response.data.simulations)
                })
                .catch((error) => {
                    console.error(error)
                    toast.error("Failed to fetch simulations")
                })
                .finally(() => {
                    setLoading(false)
                })
        }
    }, [scenarioId, scenarios])
    return (
        <div className="p-4">
            {
                loading ? (
                    <div style={{minHeight: "90vh"}} className="flex justify-center items-center">
                        <CircularProgress />
                    </div>
                ) : (
                    <div className="flex flex-col items-center">
                        {scenarioId && scenarios[scenarioId] && <Typography variant='h3'>Simulations for {scenarios[scenarioId].title}</Typography>}
                        {
                            simulations.length === 0 ? (
                                <div className="flex justify-center items-center">
                                    <div className="text-xl absolute" style={{ top: "50%", color: "gray" }}>No previous simulations found</div>
                                </div>
                            ) : (
                                <TableContainer component={Paper} style={{border: "2px solid #393E46", marginTop: "20px"}}>
                                    <Table aria-label="simple table">
                                        <TableHead style={{ background: "#393E46" }}>
                                        <TableRow>
                                            <TableCell align="center" style={{color: "white"}}><strong>Simulation ID</strong></TableCell>
                                            <TableCell align="center" style={{color: "white"}}><strong>IC Email</strong></TableCell>
                                            <TableCell align="center" style={{color: "white"}}><strong>Evaluated at</strong></TableCell>
                                            <TableCell align="center" style={{color: "white"}}><strong>View Simulation</strong></TableCell>
                                        </TableRow>
                                        </TableHead>
                                        <TableBody>
                                        {simulations.map((simulation: any) => (
                                            <TableRow
                                            key={simulation.id}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 }, height: "50px", width: "100px !important"}}
                                            >
                                                <TableCell component="th" scope="row" align="center">
                                                    {simulation.id}
                                                </TableCell >
                                                <TableCell align="center">{simulation.rep_email}</TableCell>
                                                <TableCell align="center">{simulation.evaluation_timestamp} (UTC)</TableCell>
                                                <TableCell align="center">
                                                    <IconButton onClick={() => handleViewSimulation(simulation.id, simulation.rep_email)}>
                                                        <OpenInNewIcon />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            )
                        }
                    </div>
                )
            }
        </div>
    )
}