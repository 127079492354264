import React from 'react';
import Members from '../components/Members';
// import Teams from '../components/Teams';
// import { Typography } from '@mui/material';

const ManageMembers = () => {
    return (
        <div className="w-full flex flex-col items-center">
            {/* <Typography variant='h3'>Manage Members</Typography> */}
            <div className='flex w-full'>
                <Members /> 
                {/* <Teams /> */}
            </div>
        </div>
    )
};

export default ManageMembers;
