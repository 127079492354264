import * as React from 'react';
import ObjectionTypeComponent, { ObjectionTypeComponentProps } from './ObjectionTypeComponent';

export interface ObjectionEvaluationComponentProps {
  data: ObjectionTypeComponentProps[]
}

const ObjectionEvaluationComponent: React.FC<ObjectionEvaluationComponentProps> = ({data}) => {
  return (
    <div>
        {
          data.length === 0 && <div>No objections were found in this simulation</div>
        }
        {
          data.map((objection_type_details, idx) => {
            return(
              <ObjectionTypeComponent key={idx} objection_type={objection_type_details.objection_type} instances={objection_type_details.instances} />
            )
          })
        }
    </div>
  );
}

export default ObjectionEvaluationComponent;