import React from 'react'
import { Routes, Route } from 'react-router-dom';
import './App.css'

import Modules from './pages/modules';
import NotFound from './pages/notfound'

import Simulation from './pages/simulation';
import Scenario from './pages/scenario';
import useAuth from './utils/auth';
import { CircularProgress } from '@mui/material';
import Products from './pages/all_products';
import Product from './pages/product';
import Module from './pages/module';
import Simulations from './pages/simulations';
import ZoomCallback from './pages/zoom';
import ManageMembers from './pages/manage_membets';
import ViewSimulation from './pages/view_simulation';
import GmeetCallback from './pages/gmeet';
import OrgSettings from './pages/org_settings';
import ModuleResult from './pages/module_result';

function App() {
    const isAuthLoading  = useAuth();
    return (
        <div className="App" style={{padding: "25px", backgroundColor: "#F7F7F7", minHeight: "80vh", fontFamily: "sans-serif", paddingBottom: "40px"}}>
            {
                isAuthLoading ? (
                    <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}><CircularProgress /></div>
                ) : (
                    <Routes>
                            <Route path="/zoom/callback" element={<ZoomCallback />} />
                            <Route path="/gmeet/callback" element={<GmeetCallback />} />
                            <Route path="/" element={localStorage.getItem('is_manager') === "true" ? <Products/> : <Modules isManager={false}/>} />
                            <Route path="/simulation/:simulationId" element={<Simulation />} />
                            <Route path="/scenario/:scenarioId" element={<Scenario />} />
                            <Route path="/module/:moduleId" element={<Module />} />
                            <Route path="/scenario/:scenarioId/simulations" element={<Simulations />} />
                            <Route path="/product/:productId/module/:moduleId/scenario/:scenarioId/rep_email/:simulationRepEmail/simulation/:simulationId/view" element={<ViewSimulation />} />
                            <Route path="/settings" element={<OrgSettings />} />
                            <Route path="/module/:moduleId/results" element={<ModuleResult />} />

                            {/* View all available products */}
                            <Route path="/product" element={<></>} />

                            {/* View a specific product's details (ONLY product details)  */}
                            <Route path="/product/:productId" element={<></>} /> 

                            <Route path="/manager/modules" element={<Modules isManager={true} />} />
                            <Route path="/manager/module/:moduleId" element={<Module />} />

                            <Route path="/manager/products" element={<Products/>} />
                            <Route path="/manager/product/:id" element={<Product/>} />
                            <Route path="/manager/members" element={<ManageMembers />} />

                            <Route path="/*" element={<NotFound />} />
                    </Routes>
                )
            }
        </div>
    )
}

export default App
