import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { REACT_APP_BACKEND_URL } from '../../constants';
import { toast } from 'react-toastify';
import CircularProgress from '@mui/material/CircularProgress';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Autocomplete, Button, Tooltip, Modal, Box, TextField, FormControl, Select, InputLabel, MenuItem, Typography } from '@mui/material';
import ObjectionChart, { coreOptions } from './ObjectionChart';
import { explicitObjectionDefinition, implicitObjectionDefinition } from '../../constants';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import AddIcon from '@mui/icons-material/Add';
import { EXPLICIT_OBJECTIONS, IMPLICIT_OBJECTIONS } from '../../constants';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    bgcolor: 'background.paper',
    p: 4,
    borderRadius: 5,
    outline: null,
    maxHeight: '90vh',
    overflowY: 'auto'
};

interface ProductObjectionProps {
    productID: string;
}

const ProductObjection: React.FC<ProductObjectionProps> = ({ productID }) => {
    const [fetchingObjections, setFetchingObjections] = useState(false);
    const [aggregatedObjections, setAggregatedObjections] = useState<any[]>([]);
    const [filteredAggregatedObjections, setFilteredAggregatedObjections] = useState<any[]>([]);
    const [aggregatedExplicitObjectionsOptions, setAggregatedExplicitObjectionsOptions] = useState<Record<string, any>>({});
    const [aggregatedImplicitObjectionsOptions, setAggregatedImplicitObjectionsOptions] = useState<Record<string, any>>({});
    const [availableUsernames, setAvailableUsernames] = useState<string[]>([])
    const [filterUsername, setFilterUsername] = useState<string | null>(null);
    const [objectionType, setObjectionType] = useState<string | null>('Explicit');
    const [rootObjections, setRootObjections] = useState<any>({ "Explicit": [], "Implicit": [] });
    const [nextLevelObjections, setNextLevelObjections] = useState<any>({});

    const [openObjectionModal, setOpenObjectionModal] = useState(false);
    const [newObjectionDetails, setNewObjectionDetails] = useState<any>({});
    const [creatingNewObjection, setCreatingNewObjection] = useState(false);

    useEffect(() => {
        document.getElementById('backButton')
    })
    const Loader = () => {
        return (
            <div style={{ minHeight: "90vh" }} className="flex justify-center items-center">
                <CircularProgress />
            </div>
        );
    }
    const populateRootObjections = () => {
        const explicitObjections: any = {};
        const implicitObjections: any = {};
        for (const obj of filteredAggregatedObjections) {
            if (obj.category === "Explicit") {
                if (!explicitObjections[obj.objection_type]) {
                    explicitObjections[obj.objection_type] = [];
                }
                explicitObjections[obj.objection_type].push(obj);
            }
            else {
                if (!implicitObjections[obj.objection_type]) {
                    implicitObjections[obj.objection_type] = [];
                }
                implicitObjections[obj.objection_type].push(obj);
            }
        }
        const resp: any = {
            "Explicit": [],
            "Implicit": []
        }

        for (const key in explicitObjections) {
            resp["Explicit"].push({
                "objection_type": key,
                "id": key,
                "total_objections": explicitObjections[key].reduce((total: number, obj: any) => total + (obj.children?.length === 0 ? (obj.is_custom ? 1 : 0) : obj.children?.length), 0),
                "distinct_objections": explicitObjections[key].length
            })
        }
        for (const key in implicitObjections) {
            resp["Implicit"].push({
                "objection_type": key,
                "id": key,
                "total_objections": implicitObjections[key].reduce((total: number, obj: any) => total + (obj.children?.length === 0 ? (obj.is_custom ? 1 : 0) : obj.children?.length), 0),
                "distinct_objections": implicitObjections[key].length
            })
        }
        setRootObjections(resp);
        const tempNextLevelObjections: any = {}
        for (const obj of filteredAggregatedObjections) {
            if (!tempNextLevelObjections[obj.objection_type]) {
                tempNextLevelObjections[obj.objection_type] = []
            }
            tempNextLevelObjections[obj.objection_type].push(obj)
        }
        setNextLevelObjections(tempNextLevelObjections);

    }
    const fetchAggregatedObjections = async () => {
        try {
            setFetchingObjections(true);
            const response = await axios.get(`${REACT_APP_BACKEND_URL}/organization/${localStorage.getItem('org_id')}/product/${productID}/objections`, {
                headers: { 'Authorization': `Bearer ${localStorage.getItem('google_access_token')}` }
            });
            const currentAvailableUsernames = new Set<string>();
            for (const objection of response.data.objections) {
                for (const child of objection.children) {
                    currentAvailableUsernames.add(child.rep_username)
                }
            }
            setAvailableUsernames(Array.from(currentAvailableUsernames))

            setAggregatedObjections(response.data.objections);
            setFilteredAggregatedObjections(response.data.objections);

        } catch (error) {
            console.error('Error fetching aggregated objections:', error);
            toast.error('Something went wrong while fetching objections')
        }
        setFetchingObjections(false);
    }
    useEffect(() => {
        const filteredObjections = [];
        if (!filterUsername) {
            setFilteredAggregatedObjections(aggregatedObjections)
            return
        }
        for (const objection of aggregatedObjections) {
            const updatedChildren = objection.children.filter((child: any) => {
                return child.rep_username === filterUsername
            }
            )
            if (updatedChildren.length > 0) {
                objection.children = updatedChildren
                filteredObjections.push(objection)
            }
        }
        setFilteredAggregatedObjections(filteredObjections);
    }, [filterUsername]);

    useEffect(() => {
        const explicitObjections: Record<string, any[]> = {};
        const implicitObjections: Record<string, any[]> = {};

        filteredAggregatedObjections.forEach((objection: any) => {
            if (objection.children?.length > 0 || objection.is_custom) {
                if (objection.category === "Explicit") {
                    if (!explicitObjections[objection.objection_type]) {
                        explicitObjections[objection.objection_type] = [];
                    }
                    explicitObjections[objection.objection_type].push(objection);
                }
                else {
                    if (!implicitObjections[objection.objection_type]) {
                        implicitObjections[objection.objection_type] = [];
                    }
                    implicitObjections[objection.objection_type].push(objection);
                }
            }
        });
        setAggregatedExplicitObjectionsOptions({
            ...coreOptions,
            data: [{
                ...coreOptions.data[0],
                dataPoints: Object.entries(explicitObjections).map(([key, value]) => (
                    {
                        label: key,
                        name: key,
                        y: value.reduce((total, obj) => total + (obj.children?.length === 0 ? (obj.is_custom ? 1 : 0) : obj.children?.length), 0),
                        objections: value,
                        distinct_objections: value.length,
                        toolTipContent: "<div style=\"display: flex; flex-direction: column; max-width: 50px;\"><div style=\"display: flex;\"><strong> Total objections:&nbsp;</strong><span> {y}</span></div><div style=\"display: flex;\"><br /><strong>Total Distinct Objections:&nbsp;</strong><span> {distinct_objections}</span></div></div>"
                    }
                ))
            }],
            originalData: explicitObjections,
            copyData: [{
                ...coreOptions.data[0],
                dataPoints: Object.entries(explicitObjections).map(([key, value]) => (
                    {
                        label: key,
                        name: key,
                        y: value.reduce((total, obj) => total + (obj.children?.length === 0 ? (obj.is_custom ? 1 : 0) : obj.children?.length), 0),
                        objections: value,
                        distinct_objections: value.length
                    }
                ))
            }]
        });
        setAggregatedImplicitObjectionsOptions({
            ...coreOptions,
            data: [{
                ...coreOptions.data[0],
                dataPoints: Object.entries(implicitObjections).map(([key, value]) => (
                    {
                        label: key,
                        name: key,
                        y: value.reduce((total, obj) => total + (obj.children?.length === 0 ? (obj.is_custom ? 1 : 0) : obj.children?.length), 0),
                        objections: value,
                        distinct_objections: value.length,
                        toolTipContent: "<div style=\"display: flex; flex-direction: column; max-width: 50px;\"><div style=\"display: flex;\"><strong> Total objections:&nbsp;</strong><span> {y}</span></div><div style=\"display: flex;\"><br /><strong>Total Distinct Objections:&nbsp;</strong><span> {distinct_objections}</span></div></div>"
                    }
                ))
            }],
            originalData: implicitObjections,
            copyData: [{
                ...coreOptions.data[0],
                dataPoints: Object.entries(implicitObjections).map(([key, value]) => (
                    {
                        label: key,
                        name: key,
                        y: value.reduce((total, obj) => total + (obj.children?.length === 0 ? (obj.is_custom ? 1 : 0) : obj.children?.length), 0),
                        objections: value,
                        distinct_objections: value.length
                    }
                ))
            }],
        });
        populateRootObjections();

    }, [filteredAggregatedObjections]);

    useEffect(() => {
        fetchAggregatedObjections();
    }, [])
    const handleFilterUsername = (username: string | null) => {
        setFilterUsername(username)
    }
    const handleObjectionType = (
        event: React.MouseEvent<HTMLElement>,
        type: string | null,
    ) => {
        if (type === null) return;
        setObjectionType(type)
    };

    const handleCreateCustomObjection = async () => {
        console.log('Create custom objection');
        setCreatingNewObjection(true)
        if (!newObjectionDetails.category || !newObjectionDetails.description || !newObjectionDetails.objection_type) {
            toast.error('Please fill in all the required fields');
            return;
        }
        try {
            const response = await axios.post(`${REACT_APP_BACKEND_URL}/organization/${localStorage.getItem('org_id')}/product/${productID}/objection`,
                newObjectionDetails,
                {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('google_access_token')}`
                    }
                }
            );
            await fetchAggregatedObjections();
            console.log('response', response.data);
            toast.success('Objection created successfully');
            setNewObjectionDetails({});
            setOpenObjectionModal(false);
        }
        catch (error) {
            console.error('Error creating objection', error);
            toast.error('Something went wrong while creating objection');
        }
        setCreatingNewObjection(false);
    }

    return (
        <>{fetchingObjections ? (<Loader />) : (
            <div>
                {/* <div className="flex relative w-full justify-end items-center mt-3 mb-6">
                    <Button variant='contained' style={{ background: "#393E46", borderRadius: "100px" }} onClick={() => setOpenObjectionModal(true)} >
                        <AddIcon /> &nbsp;
                        Add Objection
                    </Button>
                </div> */}
                <div style={{ position: "relative", width: "100%", height: "60px" }}>
                    <div style={{ position: "absolute", left: "50%", transform: "translateX(-50%)", }}>
                        <ToggleButtonGroup
                            value={objectionType}
                            exclusive
                            onChange={handleObjectionType}
                            aria-label="text alignment"
                        >
                            <ToggleButton value="Explicit" >
                                Explicit Objections
                                <Tooltip style={{ marginLeft: "10px", fontSize: "20px" }} title={<span style={{ fontSize: "16px" }}>{explicitObjectionDefinition}<br /> <br /> Click on any of the slices of the chart to drill down</span>}>
                                    <HelpOutlineIcon />
                                </Tooltip>
                            </ToggleButton>
                            <ToggleButton value="Implicit">
                                Implicit Objections
                                <Tooltip style={{ marginLeft: "10px", fontSize: "20px" }} title={<span style={{ fontSize: "16px" }}>{implicitObjectionDefinition}<br /> <br /> Click on any of the slices of the chart to drill down</span>}>
                                    <HelpOutlineIcon />
                                </Tooltip>
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </div>
                    <Button
                        variant="contained"
                        style={{ background: "#393E46", height: "fit-content", marginLeft: "20px", minWidth: "fit-content", borderRadius: "100px", color: "white", position: "absolute", top: "0.5rem", right: "0" }}
                        onClick={() => setOpenObjectionModal(true)}
                    >
                        <AddIcon /> &nbsp; Add Objection
                    </Button>
                </div>
                <div className="mt-5 p-4" style={{ border: "2px solid #393E46", borderRadius: "20px", position: "relative", minWidth: "95%" }}>
                    <div style={{ position: "absolute", right: "1rem", top: "1rem", zIndex: "10" }}>
                        <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={availableUsernames}
                            onChange={(e, value) => {
                                handleFilterUsername(value)
                            }}
                            sx={{ width: 200, backgroundColor: "white", }}
                            renderInput={(params) => <TextField {...params} label="Filter by salesperson" />}
                        />
                    </div>
                    {
                        (objectionType === "Implicit" ? aggregatedImplicitObjectionsOptions : aggregatedExplicitObjectionsOptions).data?.[0].dataPoints.length === 0 ? (
                            <div className="flex justify-center">
                                <div className="text-xl text-gray-500 flex items-center mb-20" style={{ minHeight: "150px" }}>No {objectionType?.toLocaleLowerCase()} objections found</div>
                            </div>
                        ) : (
                            (objectionType === "Implicit" ?
                                <div>
                                    <ObjectionChart 
                                        options={aggregatedImplicitObjectionsOptions} 
                                        productID={productID} 
                                        filterUsername={filterUsername} 
                                        rootObjections={rootObjections['Implicit']} 
                                        nextLevelObjections={nextLevelObjections}
                                        fetchAggregatedObjections={fetchAggregatedObjections}
                                    />
                                </div>
                                : <div>
                                    <ObjectionChart 
                                        options={aggregatedExplicitObjectionsOptions} 
                                        productID={productID} 
                                        filterUsername={filterUsername} 
                                        rootObjections={rootObjections['Explicit']} 
                                        nextLevelObjections={nextLevelObjections}
                                        fetchAggregatedObjections={fetchAggregatedObjections}
                                    />
                                </div>
                            )
                        )
                    }
                </div>
                <Modal
                    open={openObjectionModal}
                    onClose={() => {
                        setOpenObjectionModal(false)
                    }}
                >
                    <Box sx={style}>
                        <div className="flex w-full justify-center">
                            <Typography variant="h4" style={{ marginBottom: "10px" }}>Add new objection</Typography>
                        </div>
                        <div className="flex items-end">
                            <div className="w-1/2">
                                <FormControl variant="outlined" style={{ marginTop: "20px" }} fullWidth>
                                    <InputLabel>Select Objection Category*</InputLabel>
                                    <Select
                                        value={newObjectionDetails?.category}
                                        onChange={(e) => {
                                            if (e.target.value !== newObjectionDetails.category)
                                                setNewObjectionDetails({ ...newObjectionDetails, objection_type: null });
                                            setNewObjectionDetails({ ...newObjectionDetails, category: e.target.value })
                                        }}
                                        label="Select Objection Category*"
                                    >
                                        <MenuItem value={"Explicit"}> Explicit </MenuItem>
                                        <MenuItem value={"Implicit"}> Implicit </MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                            <div className="w-1/2 ml-5">
                                <FormControl variant="outlined" style={{ marginTop: "20px" }} fullWidth disabled={!newObjectionDetails.category}>
                                    <InputLabel>Select Objection Type*</InputLabel>
                                    {
                                        newObjectionDetails.category === "Explicit" ? (
                                            <Select
                                                value={newObjectionDetails?.objection_type}
                                                onChange={(e) => {
                                                    setNewObjectionDetails({ ...newObjectionDetails, objection_type: e.target.value });
                                                }}
                                                label="Select Objection Type*"
                                            >
                                                {
                                                    Object.keys(EXPLICIT_OBJECTIONS).map((key: string) => (
                                                        <MenuItem value={key} key={key}> {key} </MenuItem>
                                                    ))
                                                }
                                            </Select>
                                        ) : (
                                            <Select
                                                value={newObjectionDetails?.objection_type}
                                                onChange={(e) => {
                                                    setNewObjectionDetails({ ...newObjectionDetails, objection_type: e.target.value });
                                                }}
                                                label="Select Objection Type*"
                                            >
                                                {
                                                    Object.keys(IMPLICIT_OBJECTIONS).map((key: string) => (
                                                        <MenuItem value={key} key={key}> {key} </MenuItem>
                                                    ))
                                                }
                                            </Select>
                                        )
                                    }
                                </FormControl>
                            </div>
                        </div>
                        <TextField
                            label="Objection Summary*"
                            variant="outlined"
                            fullWidth
                            style={{ marginTop: "20px" }}
                            multiline
                            rows={2}
                            value={newObjectionDetails.description || ""}
                            onChange={(e) => { setNewObjectionDetails({ ...newObjectionDetails, description: e.target.value }) }}
                        />
                        <TextField
                            label="Ideal Answer (Optional)"
                            variant="outlined"
                            fullWidth
                            style={{ marginTop: "20px" }}
                            multiline
                            rows={2}
                            value={newObjectionDetails.ideal_answer || ""}
                            onChange={(e) => { setNewObjectionDetails({ ...newObjectionDetails, ideal_answer: e.target.value }) }}
                        />
                        <div className='flex justify-center'>
                            {
                                creatingNewObjection ? (
                                    <CircularProgress />
                                ) : (
                                    <Button
                                        variant='contained'
                                        style={{ background: "#393E46", borderRadius: "100px", marginTop: "20px" }}
                                        onClick={() => {
                                            handleCreateCustomObjection()
                                        }}
                                    >
                                        Submit
                                    </Button>
                                )
                            }
                        </div>
                    </Box>
                </Modal>
            </div >
        )}
        </>
    );
};

export default ProductObjection;
