import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { REACT_APP_BACKEND_URL } from '../../constants';
import Button from '@mui/material/Button';
import { Upload } from '@aws-sdk/lib-storage';
import { S3Client } from '@aws-sdk/client-s3/';
import { v4 as uuidv4 } from 'uuid';
import { CircularProgress, IconButton, LinearProgress, Modal, Tooltip, Box } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { toast } from 'react-toastify';
import RefreshIcon from '@mui/icons-material/Refresh';
import DragAndDrop, { FileWithPreview } from '../DragAndDrop';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import moment from 'moment';
import { DataGrid, GridColDef, GridRenderCellParams, GridToolbar } from '@mui/x-data-grid';
import VideoPlayer from '../VideoPlayer';
import CancelIcon from '@mui/icons-material/Cancel';

interface UploadButtonsProps {
    productID: string;
    changeToObjectionTab: () => void;
}

interface FileStatus {
    fileName: string;
    status: string;
    progress_percentage?: number;
    status_last_modified_at: string;
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "70vw",
    bgcolor: 'background.paper',
    p: 4,
    borderRadius: 5,
    outline: null,
    overflowY: 'auto'
};

const fetchFilesRefreshInterval = 15000;
const UploadButtons: React.FC<UploadButtonsProps> = ({ productID, changeToObjectionTab }) => {
    const [openUploadModal, setOpenUploadModal] = useState(false);
    const [files, setFiles] = useState<FileWithPreview[]>([]);
    const [uploading, setUploading] = useState(false);
    const [fileStatusLoading, setFileStatusLoading] = useState(false);
    const [isAnyFileProcessing, setIsAnyFileProcessing] = useState(false);
    const [callRecordingModal, setCallRecordingModal] = useState('');
    const [callDocType, setCallDocType] = useState('');
    const [callRecordingContent, setCallRecordingContent] = useState('');
    const inputFileRef = useRef<HTMLInputElement>(null);
    const [fileStatuses, setFileStatuses] = useState<FileStatus[]>([]);
    const tempCredsRef = useRef<any>({});
    const [uploadProgress, setUploadProgress] = useState<{ [key: string]: number }>({}); // Track upload progress for each file
    const fileStatusIntervalRef = useRef<any>();
    // Function to calculate the file type
    const getFileType = (file: File): string => {
        const extension = file.name.split('.').pop()?.toLowerCase();
        if (extension === 'mp4') {
            return '.mp4';
        } else if (extension === 'mp3') {
            return '.mp3';
        } else if (extension === 'txt') {
            return '.txt';
        } else {
            return 'unknown';
        }
    };


    // Function to get file upload status
    const getFileStatus = async () => {
        setFileStatusLoading(true);
        const responses = await axios.get(REACT_APP_BACKEND_URL + `/organization/${localStorage.getItem('org_id')}/product/${productID}/ingestion_status`, {
            headers: { 'Authorization': `Bearer ${localStorage.getItem('google_access_token')}` }
        });
        const updatedFileStatuses: FileStatus[] = responses.data.map((response: any) => ({
            id: response.call_recording_id,
            fileName: response.file_name,
            progress_percentage: response.progress_percentage,
            status: response.status === "Transcripted" ? "Processing" : response.status,
            status_last_modified_at: response.status_last_modified_at
        }));
        setFileStatusLoading(false);
        setFileStatuses(updatedFileStatuses);
    };

    // Function to get temporary credentials
    const getTempCreds = async () => {
        try {
            const response = await axios.get(REACT_APP_BACKEND_URL + `/organization/${localStorage.getItem('org_id')}/product/${productID}/temp_credentials`, {
            headers: { 'Authorization': `Bearer ${localStorage.getItem('google_access_token')}` }
        });
            tempCredsRef.current = response.data;
            return;
        } catch (error) {
            console.error('Error getting temporary credentials:', error);
            toast.error('Error getting temporary credentials. Please try again.');
        }
    };

    // Function to upload a single file
    const upload = async (file: any) => {
        const uuid = uuidv4();
        const target = {
            Bucket: tempCredsRef.current.Bucket,
            Key: `${localStorage.getItem('org_id')}/${productID}/$$${file.repUsername.replace('$', '').replace('/', '')}$$${file.name.replace('$', '').replace('/', '').split('.')[0] + uuid.slice(-10)}.${getFileType(file)}`,
            Body: file,
        };
        const creds = {
            accessKeyId: tempCredsRef.current.AccessKeyId,
            secretAccessKey: tempCredsRef.current.SecretAccessKey,
            sessionToken: tempCredsRef.current.SessionToken
        };

        return new Promise((resolve, reject) => {
            const uploadResponse = new Upload({
                client: new S3Client({ region: "us-east-1", credentials: creds, useAccelerateEndpoint: true }),
                leavePartsOnError: false,
                params: target,
            });

            uploadResponse.on('httpUploadProgress', (progress: any) => {
                // Store the progress for this file
                setUploadProgress(prevProgress => ({
                    ...prevProgress,
                    [file.name]: Math.round((progress.loaded / progress.total) * 100)
                }));
            });

            uploadResponse.done()
                .then((data) => {
                    if (!fileStatusLoading) {
                        setFileStatusLoading(true);
                        setTimeout(() => {
                            getFileStatus();
                            if (fileStatuses[0].status !== "Processing") {
                                setTimeout(() => {
                                    getFileStatus();
                                }, 2000)
                            }
                        }, 5000);
                    }
                    resolve(data);
                })
                .catch((err) => {
                    console.error(err);
                    reject(err);
                });
        });
    };

    // Function to upload all files
    const uploadFiles = async () => {
        for (const file of files) {
            if (!file.repUsername) {
                toast.error("Please enter the salesperson's name for all files")
                return
            }
        }

        setUploading(true);
        setOpenUploadModal(false);
        toast.info("Uploading files... Please do not close this page until the upload is complete.")
        try {
            await getTempCreds();
            const uploadPromises = files.map((file) => upload(file));
            await Promise.all(uploadPromises);
            toast.success('Files uploaded successfully!');
        } catch (error) {
            console.error('Error uploading files:', error);
            toast.error('Error uploading files. Please try again.');
        } finally {
            setUploading(false);
            setUploadProgress({}); // Reset upload progress
            setFiles([]); // Clear the files
            if (inputFileRef.current) {
                inputFileRef.current.value = ''; // Clear the value
            }
        }
    };
    useEffect(() => {
        getFileStatus();
    }, []);
    const openCallRecordingModalTrigger = (call_recording_id: string, doc_type: string) => {
        setCallRecordingModal(call_recording_id);
        setCallDocType(doc_type);
        axios.post(REACT_APP_BACKEND_URL + `/organization/${localStorage.getItem('org_id')}/generate_presigned_call_url`,
            {
                call_recording_id: call_recording_id,
                doc_type
            },
            {
                headers: {
                    "Authorization": `Bearer ${localStorage.getItem('google_access_token')}`
                }
            }
        ).then((response) => {
            if (!response.data.url) {
                toast.error('Error getting call recording presigned URL. Please try again later.');
                setCallRecordingModal('');
                return;
            }
            if (doc_type === "transcript") { 
                axios.get(response.data.url).then((res) => {
                    setCallRecordingContent(res.data);
                    console.log("transcript is", res.data)                    
                }).catch((error) => {
                    console.error('Error getting call recording transcript:', error);
                    toast.error('Error getting call recording transcript. Please try again later.');
                    setCallRecordingModal('');
                    setCallDocType('');
                })
            }
            else {
                setCallRecordingContent(response.data.url);
            }
        }).catch((error) => {
            console.error('Error getting call recording presigned URL:', error);
            toast.error('Error getting call recording presigned URL. Please try again later.');
            setCallRecordingModal('');
            setCallDocType('');
        })
    }
    const columns: GridColDef[] = [
        {
            field: 'call_recording_id', flex: 1.4,
            renderHeader: () => (
                <strong>Recording Name</strong>
            ),
            renderCell: (params: GridRenderCellParams) => (
                <>{params.row.fileName.includes('$$') ? params.row.fileName.split('$$')?.[2] : params.row.fileName}</>
            ),
        },
        {
            field: 'ic_name', flex: 0.9,
            renderHeader: () => (
                <strong>IC Name</strong>
            ),
            renderCell: (params: GridRenderCellParams) => (
                <>{params.row.fileName.includes('$$') ? params.row.fileName.split('$$')?.[1] : "Unknown"}</>
            ),
        },
        {
            field: 'status', flex: 0.7,
            renderHeader: () => (
                <strong>Status</strong>
            ),
            renderCell: (params: GridRenderCellParams) => (
                <>{params.row.status}{params.row.status === "Processing" && ` (${params.row.progress_percentage}%)`}</>
            ),
        },
        {
            field: 'status_last_modified_at', flex: 1,
            renderHeader: () => (
                <strong>Last Modified Time</strong>
            ),
            renderCell: (params: GridRenderCellParams) => (
                <>{moment(params.row.status_last_modified_at, 'YYYY-MM-DD HH:mm:ss').format('MMMM Do YYYY, h:mm a')}</>
            ),
        },
        {
            field: 'view_objection',
            headerName: 'view_objection',
            flex: 0.6,
            sortable: false,
            renderCell: (params: GridRenderCellParams) => (
                <div className="flex flex-col padding-3">
                    <Button size='small' variant='outlined' disabled={params.row.status !== "Success"} onClick={() => { openCallRecordingModalTrigger(params.row.id, "recording") }}>View Recording</Button>
                    <Button size='small' variant='outlined' onClick={() => { openCallRecordingModalTrigger(params.row.id, "transcript") }} sx={{marginTop: "5px"}}>View Transcript</Button>
                </div>
            ),
            renderHeader: () => (
                <strong>View Call</strong>
            ), 
        },
    ];
    useEffect(() => {
        let isProcessing = false;
        let isAnyFileSuccessfullyProcessed = false
        for (const fileStatus of fileStatuses) {
            if (fileStatus.status === 'Processing') {
                isProcessing = true;
                setIsAnyFileProcessing(true)
            }
            else if (fileStatus.status === 'Success') {
                isAnyFileSuccessfullyProcessed = true
            }
        }
        if (isProcessing) {
            fileStatusIntervalRef.current = setInterval(() => {
                getFileStatus();
            }, fetchFilesRefreshInterval);
        }
        else {
            if (isAnyFileProcessing) { // check if any file was previously being processed and now all files are in a terminal state
                if (isAnyFileSuccessfullyProcessed) {
                    toast.success('Files processed successfully!');
                    changeToObjectionTab()
                }
                else {
                    toast.error('Files processing failed!');
                }
            }
            setIsAnyFileProcessing(false)
            clearInterval(fileStatusIntervalRef.current);
        }
        return () => {
            clearInterval(fileStatusIntervalRef.current);
        };
    }, [fileStatuses]);

    return (
        <div className="flex flex-col relative">
            <Modal
                open={openUploadModal}
                onClose={() => {
                    setOpenUploadModal(false)
                }}
            >
                <Box sx={style}>
                    <DragAndDrop files={files} setFiles={setFiles} uploading={uploading} />
                    <div className="flex justify-center w-full mt-4 items-center">
                        <Button
                            disabled={files.length === 0 || uploading}
                            variant="contained"
                            style={{ background: files.length === 0 ? '#D3DBDE' : "#393E46" }}
                            onClick={() => {
                                uploadFiles();
                            }}
                        >
                            <CloudUploadIcon /> &nbsp;&nbsp;
                            {
                                uploading ? "Uploading..." : "Upload"
                            }
                        </Button>
                    </div>
                </Box>
            </Modal>
            <div
                style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                    // alignItems: "center",
                    height: (fileStatuses.length === 0 && !fileStatusLoading && !uploading) ? "60vh" : "fit-content",
                    marginTop: "10px"
                }}
            >
                <div style={{ width: "70px" }}>
                    {
                        fileStatusLoading ? (
                            <CircularProgress />
                        ) : (
                            <Tooltip title={<span style={{ fontSize: "16px" }}>Refresh file status</span>}>
                                <IconButton onClick={() => getFileStatus()} style={{ width: "fit-content" }}>
                                    <RefreshIcon style={{ width: "40px", height: "40px", color: "#393E46" }} />
                                </IconButton>
                            </Tooltip>
                        )
                    }
                </div>
                <Button
                    variant="contained"
                    disabled={uploading}
                    style={{ background: "#393E46", height: "fit-content", marginLeft: "20px", minWidth: "fit-content", borderRadius: "100px", color: "white" }}
                    onClick={() => setOpenUploadModal(true)}
                >
                    <AddIcon /> &nbsp;
                    {
                        uploading ? "Uploading..." : "Add calls"
                    }
                </Button>
            </div>
            {uploading && (
                <div className="mt-10">
                    {
                        files.map((file, index) => (
                            <div key={index} style={{ display: "flex", flexDirection: "row" }}>
                                <div style={{ width: "30%" }}><p>{file.name}</p></div>
                                <div style={{ width: "70%" }}><LinearProgress
                                    variant="determinate"
                                    value={uploadProgress[file.name] || 0}
                                    style={{ width: '100%', marginTop: '10px', marginLeft: '10px' }}
                                />
                                </div>
                            </div>
                        ))
                    }
                </div>
            )}
            <div className="flex mt-5">
                {(fileStatuses.length > 0 || fileStatusLoading) ? (
                    <div className="flex w-full">
                        {

                            fileStatuses.length > 0 && (
                                <>
                                    <DataGrid
                                        rows={fileStatuses}
                                        columns={columns}
                                        rowSelection={false}
                                        initialState={{
                                            pagination: {
                                                paginationModel: { page: 0, pageSize: 10 },
                                            },
                                            sorting:{
                                                sortModel: [{field: 'status_last_modified_at', sort: 'desc'}]
                                            }
                                        }}
                                        style={{ backgroundColor: "white", textAlign: "center" }}
                                        disableColumnFilter={true}
                                        disableColumnMenu={true}
                                        pageSizeOptions={[10, 20, 50]}
                                        getRowHeight={() => 75}
                                        components={{ Toolbar: GridToolbar }}
                                        slotProps={{
                                            toolbar: {
                                              showQuickFilter: true,
                                            },
                                          }}
                                        disableDensitySelector
                                        disableColumnSelector
                                    />
                                    <Modal
                                        open={callRecordingModal !== ''}
                                        onClose={() => {
                                            setCallRecordingModal('');
                                            setCallDocType('');
                                            setCallRecordingContent('');
                                        }}
                                    >
                                        <Box sx={{...style}}>
                                            {
                                                !callRecordingContent ? (
                                                    <div className="flex justify-center items-center">
                                                        <CircularProgress />
                                                    </div>
                                                ) : (
                                                <div style={{display:"flex",flexDirection:"column"}}>
                                                    <IconButton onClick={() => {setCallRecordingModal('');setCallDocType('');setCallRecordingContent('');}} style={{color: "black", fontSize: "25px", position: "absolute", cursor: "pointer", top: "5px", right: "5px", background: "#EEEEEE", borderRadius: "100%", marginBottom:"10px"}}>
                                                        <CancelIcon  
                                                        />
                                                    </IconButton>
                                                    {
                                                        callDocType === "recording" ? (
                                                            <VideoPlayer url={callRecordingContent} sourceType="video/mp4" />
                                                        ) : (
                                                            <pre style={{padding: "10px", overflowY: "auto", maxHeight: "70vh"}}>
                                                                {callRecordingContent}
                                                            </pre>
                                                        )
                                                    }
                                                </div>
                                                )
                                            }
                                        </Box>
                                    </Modal>
                                </>
                            )
                        }
                    </div>
                ) : (
                    <>
                        {
                            fileStatuses.length === 0 && !fileStatusLoading && !uploading && (
                                <div className="flex justify-center items-center w-full">
                                    <div className="text-xl absolute" style={{ top: "50%", color: "gray" }}>No call recordings found for this product. </div>
                                    <div className="w-full flex justify-center mt-3"> <strong>Pro Tip:&nbsp;</strong>You can set your organization&#39;s sales rules to improve objection handling evaluation in <i>&nbsp;Org Settings</i></div>    
                               </div>
                            )
                        }
                    </>
                )}
            </div>
        </div>
    );
};

export default UploadButtons;
