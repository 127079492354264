import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export const currentSimulationSlice = createSlice({
  name: 'currentSimulation',
  initialState: {
    messages: []
  },
  reducers: {
    addSimulationMessage: (state: any, action: PayloadAction<any>) => {
      if (action.payload.role === "backend") {
        if (state.messages.length > 0){
          if (state.messages[state.messages.length - 1].role === "backend") {
            state.messages[state.messages.length - 1].content = action.payload.content
          }
          else {
            state.messages.push(action.payload)
          }
        }
        else {
          state.messages.push(action.payload)
        }
      }
      else {
        state.messages.push(action.payload)
      }
    }
  },
})

// Action creators are generated for each case reducer function
export const { addSimulationMessage } = currentSimulationSlice.actions

export default currentSimulationSlice.reducer