import * as React from 'react';
import { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import { Navigate } from "react-router-dom";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import CircularProgress from '@mui/material/CircularProgress';
import { REACT_APP_BACKEND_URL, REACT_APP_GOOGLE_CLIENT_ID, REACT_APP_GOOGLE_REDIRECT_URI } from '../../constants';

export default function GoogleLoginButton() {
  const [displayMessage, setDisplayMessage] = useState("");
  const client_id = REACT_APP_GOOGLE_CLIENT_ID || '';
  const redirect_uri = REACT_APP_GOOGLE_REDIRECT_URI || '';
  const scope = 'https://www.googleapis.com/auth/userinfo.profile+https://www.googleapis.com/auth/userinfo.email'
  const google_oauth_url = `https://accounts.google.com/o/oauth2/v2/auth?response_type=code&client_id=${client_id}&redirect_uri=${redirect_uri}&scope=${scope}&state=google_login&prompt=consent&access_type=offline`;
  const [loggedIn, setLoggedIn] = useState(false);
  const [isLoggingIn, setIsLoggingIn] = useState(false)

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const code = queryParams.get('code');
    const state = queryParams.get('state');

    if (!code) return;
    if (!(state === 'google_login')) return;
    setIsLoggingIn(true)

    const data = {
      "provider": "google-oauth2",
      "code": code,
      redirect_uri,
      org_id: localStorage.getItem('org_id') || null
    }

    axios.post(REACT_APP_BACKEND_URL + `/auth/google/login/`, data)
      .then((response: any) => {
        console.log("response", response.data)
        localStorage.setItem('google_access_token', response.data.access_token)
        localStorage.setItem('google_refresh_token', response.data.refresh_token)
        localStorage.setItem('google_token_expires_at', (Date.now() + response.data.expires_in * 1000).toString())
        localStorage.setItem('userProfile', JSON.stringify(response.data.user_profile))
        localStorage.setItem('is_manager', response.data.user_profile.role.toLowerCase() === "vp" ? 'true' : 'false')
        if (!localStorage.getItem('org_id')){
          localStorage.setItem('org_id', response.data.user_profile.orgs[0].id)
        }
        window.dispatchEvent(new Event("orgChange"));
        setLoggedIn(true);
        setDisplayMessage("Logged in successfully")
        setIsLoggingIn(false)
      })
      .catch((error) => { 
        toast.error("Something went wrong while logging in. Please try again later.")
        console.log("error", error)
        setIsLoggingIn(false)
      })
  }, []);

  return loggedIn ? (<Navigate to="/" replace={true} />) : (
    <div className="flex justify-center items-center h-full" style={{backgroundColor:"#F6F6F6"}}>
      {
        isLoggingIn ? (
          <CircularProgress />
        ) : (
          <>
            <div className="flex flex-col items-center">
              <Button href={google_oauth_url} variant="contained" style={{backgroundColor: "black"}}>Login with Google</Button>
              <div className="mt-5">{displayMessage}</div>
            </div>
            <ToastContainer
              position="bottom-right"
              autoClose={4000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="colored"
            />
          </>
        )
      }
    
    </div >
  );
}
