import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { REACT_APP_BACKEND_URL } from '../constants';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Button, CircularProgress, Modal, Box, TextField, FormControl, Select, InputLabel, MenuItem, Typography } from '@mui/material';
import { toast } from 'react-toastify';
import DeleteWithConfirmation from './DeleteWithConfirmation';
import AddIcon from '@mui/icons-material/Add';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    p: 4,
    borderRadius: 5,
    outline: null,
    maxHeight: '90vh',
    overflowY: 'auto'
};

const Members = () => {
    const [members, setMembers] = useState<Record<string, any>[]>([])
    const [loading, setLoading] = useState(true)
    const [deleteLoadingForMember, setDeleteLoadingForMember] = useState<Record<string, boolean>>({})
    const [openModal, setOpenModal] = useState(false)
    const [addMemberName, setAddMemberName] = useState("")
    const [addMemberEmail, setAddMemberEmail] = useState("")
    const [addMemberRole, setAddMemberRole] = useState("")
    const [addMemberLoading, setAddMemberLoading] = useState(false)

    const handleAddMember = () => {
        if(addMemberName === "" || addMemberEmail === "" || addMemberRole === ""){
            toast.error("All fields are required")
            return
        }
        setAddMemberLoading(true)
        axios.post(`${REACT_APP_BACKEND_URL}/organization/${localStorage.getItem('org_id')}/${addMemberRole}`, {
            name: addMemberName,
            email: addMemberEmail
        }, {
            headers: { 'Authorization': `Bearer ${localStorage.getItem('google_access_token')}` }
        })
            .then(() => {
                setMembers([...members, {name: addMemberName, email: addMemberEmail, role: addMemberRole}])
                toast.success("Member added successfully")
                setOpenModal(false)
                setAddMemberName("")
                setAddMemberEmail("")
                setAddMemberRole("")
            })
            .catch(err => {
                console.log(err)
                toast.error("Something went wrong while adding member")
            })
            .finally(() => {
                setAddMemberLoading(false)
            })
    }

    useEffect(() => {
        axios.get(`${REACT_APP_BACKEND_URL}/organization/${localStorage.getItem('org_id')}/person`, {
            headers: { 'Authorization': `Bearer ${localStorage.getItem('google_access_token')}` }
        })
            .then(res => {
                setMembers(res.data.persons)
            })
            .catch(err => {
                console.log(err)
                toast.error("Something went wrong while fetching members")
            })
            .finally(() => {
                setLoading(false)
            })
    }, [])
    const handleDeleteMember = (memberRole: string, memberID: string) => {
        setDeleteLoadingForMember({...deleteLoadingForMember, [memberID]: true})
        axios.delete(`${REACT_APP_BACKEND_URL}/organization/${localStorage.getItem('org_id')}/${memberRole.toLowerCase()}/${memberID}`, {
            headers: { 'Authorization': `Bearer ${localStorage.getItem('google_access_token')}` }
        })
            .then(() => {
                setMembers(members.filter(member => member.email !== memberID))
                toast.success("Member deleted successfully")
            })
            .catch(err => {
                console.log(err)
                toast.error("Something went wrong while deleting member")
            })
            .finally(() => {
                setDeleteLoadingForMember({...deleteLoadingForMember, [memberID]: false})
            })
    }
    return (
        <div className="w-full flex justify-center h-full flex-col items-center m-3"
        >
            {
                loading ? (
                    <div style={{ minHeight: "90vh" }} className="flex justify-center items-center"> <CircularProgress /> </div>
                    ) : (
                    <div className="w-full flex flex-col items-center" style={{maxHeight: "65vh", overflowY: "auto", position: "relative"}}>
                        <Typography variant='h3' style={{marginBottom: "20px"}}>Manage Members</Typography>
                        <div className="absolute top-0 right-0">
                            <Button
                                style={{
                                    marginBottom: "20px",
                                    backgroundColor: '#393E46',
                                    color: 'white',
                                    // width: "100%",
                                    padding: "10px 20px 10px 20px",
                                    borderRadius: "100px"
                                }}
                                onClick={() => setOpenModal(true)}
                            >
                                <AddIcon />
                                &nbsp;Add new Member
                            </Button>
                        </div>
                        {/* <Typography variant='h4' style={{paddingBottom: "20px"}}>Members</Typography> */}
                        <TableContainer component={Paper} style={{border: "2px solid #393E46"}}>
                            <Table aria-label="simple table">
                                <TableHead style={{ background: "#393E46" }}>
                                <TableRow>
                                    <TableCell align="center" style={{color: "white"}}><strong>Name</strong></TableCell>
                                    <TableCell align="center" style={{color: "white"}}><strong>Email</strong></TableCell>
                                    <TableCell align="center" style={{color: "white"}}><strong>Zoom status</strong></TableCell>
                                    <TableCell align="center" style={{color: "white"}}><strong>Role</strong></TableCell>
                                    <TableCell align="center" style={{color: "white"}}></TableCell>
                                </TableRow>
                                </TableHead>
                                <TableBody>
                                {members.map((member) => (
                                    <TableRow
                                    key={member.email}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 }, height: "50px", width: "100px !important"}}
                                    >
                                        <TableCell component="th" scope="row" align="center">
                                            {member.name}
                                        </TableCell >
                                        <TableCell align="center">{member.email}</TableCell>
                                        <TableCell align="center">{member.has_connected_zoom ? "Connected" : "Not Connected"}</TableCell>
                                        <TableCell align="center">{member.role.toLowerCase() === 'vp' ? "Admin" : "IC"}</TableCell>
                                        <div className="flex items-center justify-center" style={{ height: "50px"}}>
                                        {
                                            deleteLoadingForMember[member.email] ? (
                                                <CircularProgress />
                                            ) : (
                                                <DeleteWithConfirmation 
                                                    handleDelete={() => {handleDeleteMember(member.role, member.email)}}
                                                    subject="Delete Member"
                                                    description={`Are you sure you want to delete the member ${member.name}?`}
                                                />
                                            )
                                        }
                                        </div>
                                    </TableRow>
                                ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Modal
                            open={openModal}
                            onClose={() => {
                                setOpenModal(false)
                            }}
                        >
                            <Box sx={style}>
                                <div className="flex flex-col items-center">
                                    <Typography variant='h5' style={{marginBottom: "20px", fontWeight: "600"}}>Add new Member</Typography>
                                    <TextField
                                        label="Name"
                                        variant="outlined"
                                        fullWidth
                                        value={addMemberName}
                                        onChange={(e)=>setAddMemberName(e.target.value)}
                                        required
                                    />
                                    <TextField
                                        label="Email"
                                        variant="outlined"
                                        style={{ marginTop: "20px" }}
                                        fullWidth
                                        value={addMemberEmail}
                                        onChange={(e)=>setAddMemberEmail(e.target.value)}
                                    />
                                    <FormControl variant="outlined" style={{ marginTop: "20px" }} fullWidth>
                                        <InputLabel>Select Role</InputLabel>
                                        <Select
                                            value={addMemberRole}
                                            onChange={(e)=>setAddMemberRole(e.target.value)}
                                            label="Select Role"
                                        >
                                            <MenuItem value={"rep"}> IC </MenuItem>
                                            <MenuItem value={"vp"}> Admin </MenuItem>
                                        </Select>
                                    </FormControl>
                                    {
                                       addMemberLoading ? (
                                            <CircularProgress style={{ marginTop: "20px" }} />
                                        ) : (
                                            <Button
                                                style={{
                                                    marginTop: "20px",
                                                    backgroundColor: '#393E46',
                                                    color: 'white',
                                                    width: "100%",
                                                    padding: "10px 20px 10px 20px"
                                                }}
                                                onClick={() => handleAddMember()}
                                            >
                                                Add Member
                                            </Button>
                                        )
                                    }
                                </div>
                            </Box>
                        </Modal>
                    </div>
                )
            }
        </div>
    )
}
export default Members;