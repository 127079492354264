import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import { useNavigate } from "react-router-dom";

interface MultiActionAreaCardProps {
  imageUrl: string;
  imageAltText: string;
  cardTitle: string;
  cardText: string;
  navigateTo: string;
}

const MultiActionAreaCard: React.FC<MultiActionAreaCardProps> = ({
  imageUrl,
  imageAltText,
  cardTitle,
  cardText,
  navigateTo
}) => {
  const navigate = useNavigate();
  const truncatedText = cardText.length > 100 ? `${cardText.substring(0, 100)}...` : cardText;

  return (
    <div onClick={() => navigate(navigateTo)} style={{ cursor: 'pointer', width: 'fit-content', margin: "10px" }}>
      <Card sx={{ maxWidth: 300 }}>
        <CardActionArea>
          <CardMedia
            component="img"
            height="140"
            image={imageUrl} // Use the imageUrl from props
            alt={imageAltText} // Use the imageAltText from props
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              {cardTitle} {/* Use the cardTitle from props */}
            </Typography>
            <Typography variant="body2" color="text.secondary" style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
              {truncatedText} {/* Use the truncatedText */}
            </Typography>
          </CardContent>
        </CardActionArea>
        {/* <CardActions>
          <Button size="small" color="primary">
            Share
          </Button>
        </CardActions> */}
      </Card>
    </div>
  );
}

export default MultiActionAreaCard;
