
import React, { useEffect, useState, useRef } from 'react';

interface CircleProps {
  color: string;
  size?: number; // Optional size prop, with a default size if not specified
}

const Circle: React.FC<CircleProps> = ({ color }) => {
  const style = {
    backgroundColor: color,
    width: 10,
    height: 10,
    borderRadius: '50%',
    display: 'inline-block',
  };

  return <div style={style} />;
};

export const cleanUpAudio = (base64Audio: string) => {
  let audio = base64Audio;
  // remove padding from last
  audio = audio.replaceAll(/q+(\/\/NI.{14}q+pMQU1FMy4xMDCq+)+\/\/NI.{14}q+$/g, "");
  audio = audio.replaceAll(/V+(\/\/NI.{14}V+MQU1FMy4xMDBV+)+\/\/NI.{14}V+$/g, "");
  return audio;
};

function isValidBase64(str: string) {
  const regex = /^data:audio\/([a-zA-Z0-9]+);base64,([a-zA-Z0-9+/]+={0,2})$/;
  return regex.test(str);
}
interface AudioPlayerProps {
  mediaQueue: string[];
  isAwaitingCustomerReply: boolean;
  setTranscript: (transcript: string) => void;
  canRepSpeak: boolean;
  setCanRepSpeak: (canRepSpeak: boolean) => void;
}
const AudioPlayer: React.FC<AudioPlayerProps> = ({ mediaQueue, isAwaitingCustomerReply, setTranscript, canRepSpeak, setCanRepSpeak }) => {
  const [audioUrlToPlay, setAudioUrlToPlay] = useState<string>(); 
  // const [mediaQueue, setMediaQueue] = useState<string[]>([]); 
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const isAwaitingCustomerReplyRef = useRef(isAwaitingCustomerReply);
  const mediaQueueRef = useRef(mediaQueue);

  const processQueue = () => {
    if (mediaQueueRef.current?.length && !audioUrlToPlay) {
      const audio = mediaQueueRef.current[0];
      if (audio) {
        setAudioUrlToPlay("data:audio/mp3;base64," + cleanUpAudio(audio));

      }
    }
  }
  useEffect(() => {
    mediaQueueRef.current = mediaQueue;
    processQueue();
  }, [mediaQueue, audioUrlToPlay]);

  const handleRepCanSpeak = () => {
    setTranscript("")
    setCanRepSpeak(true)
    console.log("rep can speak now")
  }

  useEffect(() => {
    if(mediaQueue.length === 0 && !isAwaitingCustomerReply){
      handleRepCanSpeak()
    }
  }, [mediaQueue, isAwaitingCustomerReply])

  const onAudioEnd = () => {
    setAudioUrlToPlay(undefined);
    console.log("audio ended, media queue length,", mediaQueueRef.current?.length, "isAwaitingCustomerReply", isAwaitingCustomerReplyRef.current)
    mediaQueueRef.current?.shift();
    if (mediaQueueRef.current?.length === 0 && !isAwaitingCustomerReplyRef.current){
      handleRepCanSpeak()
    }
  }

  useEffect(() => {
    isAwaitingCustomerReplyRef.current = isAwaitingCustomerReply;
    if(isAwaitingCustomerReply)
      setCanRepSpeak(false)
    else if(mediaQueueRef.current?.length === 0)
      handleRepCanSpeak()
  }, [isAwaitingCustomerReply])

  useEffect(() => {
    if (audioUrlToPlay) {
      // console.log("playin")
      const audio = new Audio(audioUrlToPlay);
      audioRef.current = audio;
      audio.onended = onAudioEnd;
      // audio.muted = muted;
      if (isValidBase64(audioUrlToPlay)){
        audio.play();
      } else {
        console.log("Current audio is of invalid format", audioUrlToPlay);
        const event = new Event("ended");
        audio.dispatchEvent(event);
      }
      return () => onAudioEnd && audio.removeEventListener("ended", onAudioEnd);
    }
  }, [audioUrlToPlay]);

  return (
    <div className="flex justify-end">
      <Circle color={canRepSpeak ? 'green' : 'orange'} />
    </div>
  )
};

export default AudioPlayer;
