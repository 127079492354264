import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Navbar from './components/navbar/navbar';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ToastContainer } from "react-toastify"

import Signin from './pages/signin';


import { Provider } from 'react-redux';
import store, {persistor} from './redux/store';
import { PersistGate } from 'redux-persist/integration/react';

import reportWebVitals from './reportWebVitals'

import "./index.css"
import App from './App';

const theme = createTheme({      
    typography: {
      fontFamily: `sans-serif`,
      button: {
        textTransform: 'none',
        fontSize: "18px"
      }
    },
    components: {
        MuiCircularProgress: {
          styleOverrides: {
            colorPrimary: {
              color: "#393E46"
            }
          }
        },
        // change textfields outline color to black
        MuiOutlinedInput: {
          styleOverrides: {
            root: {
                '& label.Mui-focused': {
                    color: 'white',
                  },
                  '& .MuiInput-underline:after': {
                    borderBottomColor: 'yellow',
                  },
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: 'white',
                    },
                    '&:hover fieldset': {
                      borderColor: 'white',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: 'yellow',
                    },
                    '&$cssFocused $notchedOutline': {
                        borderColor: `black !important`,
                      }
                }
            }
          }
        }
    }
  });
  

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(

    
<Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                <Navbar />
                    <Routes>
                        <Route path="/signin" element={<Signin mode="signin" />} />
                        <Route path="/signup" element={<Signin mode="signup" />} />
                        <Route path="/*" element={<App />} />
                    </Routes>
                {/* <Footer /> */}
            </BrowserRouter>
            <ToastContainer
                    position="top-right"
                    autoClose={4000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="colored"
                    />
        </ThemeProvider>
    </PersistGate>
</Provider>

)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
