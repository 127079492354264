import { createSlice } from '@reduxjs/toolkit'

export const moduleSlice = createSlice({
  name: 'modules',
  initialState: {
    value: {},
  },
  reducers: {
    setModules: (state, action) => {
        state.value = action.payload
    },
    setModule: (state: any, action: any) => {
        state.value[action.payload.id] = action.payload
    }
  },
})

// Action creators are generated for each case reducer function
export const { setModules, setModule } = moduleSlice.actions

export default moduleSlice.reducer