import React, { useEffect, useState } from 'react'
import { REACT_APP_BACKEND_URL } from '../constants';
import CircularProgress from '@mui/material/CircularProgress';
import { IconButton, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

export default function ModuleResult() {
    const [moduleData, setModuleData] = useState<any>({})
    const [fetchingResults, setFetchingResults] = useState<boolean>(true)
    const { moduleId, rep_email } = useParams()
    useEffect(() => {
        if (moduleId === undefined) return
        axios.get(`${REACT_APP_BACKEND_URL}/organization/${localStorage.getItem('org_id')}/module/${moduleId}/results`, {
            headers: { 'Authorization': `Bearer ${localStorage.getItem('google_access_token')}` },
            params: { rep_email }
        })
            .then((response) => {
                console.log("module data is", response.data)
                response.data.result_simulations.sort((a: any, b: any) => {
                    return new Date(b.simulation_ended_at).getTime() - new Date(a.simulation_ended_at).getTime()
                })
                setModuleData(response.data)
            })
            .catch((error) => {
                console.error(error)
            })
            .finally(() => {
                setFetchingResults(false)
            })
    }, [moduleId])

    const handleViewSimulation = (scenarioId: string, simulationId: string, simulationRepEmail: string) => {
        window.open(`/product/${moduleData.product_id}/module/${moduleId}/scenario/${scenarioId}/rep_email/${simulationRepEmail}/simulation/${simulationId}/view`, '_blank')
    }

    return (
        <div style={{ display: "flex", flexDirection: "column", paddingLeft: "20px", maxHeight: "70%", marginBottom: "5%" }}>
            {
                fetchingResults ? (
                    <div style={{ minHeight: "90vh" }} className="flex justify-center items-center">
                        <CircularProgress />
                    </div>
                ) : (
                    <>
                        <Typography variant="h4" style={{ width: "100%", display: "flex", justifyContent: "center" }}>Results for {moduleData.module_name}</Typography>
                        <TableContainer component={Paper} style={{ border: "2px solid #393E46", marginTop: "20px" }}>
                            <Table aria-label="simple table">
                                <TableHead style={{ background: "#393E46" }}>
                                    <TableRow>
                                        <TableCell align="center" style={{ color: "white" }}><strong>Scenario Title</strong></TableCell>
                                        <TableCell align="center" style={{ color: "white" }}><strong>Simulation ID</strong></TableCell>
                                        <TableCell align="center" style={{ color: "white" }}><strong>IC Email</strong></TableCell>
                                        <TableCell align="center" style={{ color: "white" }}><strong>End Timestamp (UTC)</strong></TableCell>
                                        <TableCell align="center" style={{ color: "white" }}><strong>View Report</strong></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {moduleData?.result_simulations?.map((simulation: any) => (
                                        <TableRow
                                            key={simulation.id}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 }, height: "50px", width: "100px !important" }}
                                        >
                                            <TableCell component="th" scope="row" align="center">
                                                {simulation.scenario_title}
                                            </TableCell >
                                            <TableCell component="th" scope="row" align="center">
                                                {simulation.id}
                                            </TableCell >
                                            <TableCell align="center">{simulation.rep_email}</TableCell>
                                            <TableCell align="center">{simulation.simulation_ended_at}</TableCell>
                                            <TableCell align="center">
                                                <IconButton onClick={() => handleViewSimulation(simulation.module_scenario_id, simulation.id, simulation.rep_email)}>
                                                    <OpenInNewIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </>
                )
            }
        </div>
    )
}
