import React, { useRef, useEffect } from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';

const VideoJS = (props: any) => {
  const videoRef = useRef<HTMLDivElement>(null);
  const playerRef = useRef<any | null>(null);
  const { options, onReady } = props;

  useEffect(() => {
    // Make sure Video.js player is only initialized once
    if (!playerRef.current) {
      // The Video.js player needs to be _inside_ the component el for React 18 Strict Mode.
      const videoElement = document.createElement("video");
      videoElement.className = 'video-js';
      videoElement.classList.add('vjs-big-play-centered');
      if (videoRef.current) {
        videoRef.current.appendChild(videoElement);
      }

      const player = playerRef.current = videojs(videoElement, options, () => {
        videojs.log('player is ready');
        onReady && onReady(player);
      });

      // You could update an existing player in the `else` block here
      // on prop change, for example:
    } else {
      const player = playerRef.current;

      if (player) {
        player.currentTime(options.startTime);
        player.autoplay(options.autoplay);
        player.src(options.sources);
      }
    }
  }, [options, onReady, videoRef]);

  // Dispose the Video.js player when the functional component unmounts
  useEffect(() => {
    const player = playerRef.current;
    playerRef.current.currentTime(options.startTime);
    return () => {
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  return (
    <div data-vjs-playe style={props.style}>
      <div ref={videoRef} />
    </div>
  );
}

interface PlayerProps {
  url: string;
  sourceType: string;
  startTime?: number;
  style?: any;
}

const Player: React.FC<PlayerProps> = ({url, sourceType, startTime = 0, style={}}) => {
  const playerRef = React.useRef(null);

  const videoJsOptions = {
    autoplay: true,
    controls: true,
    responsive: true,
    fluid: true,
    sources: [{
      src: url,
      type: sourceType
    }],
    startTime: startTime,
    playbackRates: [0.5, 0.75, 1, 1.25, 1.5, 1.75, 2]
  };

  const handlePlayerReady = (player: any) => {
    playerRef.current = player;

    // You can handle player events here, for example:
    player.on('waiting', () => {
      videojs.log('player is waiting');
    });

    player.on('dispose', () => {
      videojs.log('player will dispose');
    });
  };

  return (
    <>
      <VideoJS style={style} options={videoJsOptions} onReady={handlePlayerReady} />
    </>
  );
}
export default Player;