import React from 'react';
import { TextField, Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

const DynamicTextBoxes = ({ title, values, setValues }: any) => {
    const handleAdd = () => {
        setValues([...values, '']);
    };

    const handleDelete = (index: any) => {
        const newValues = [...values];
        newValues.splice(index, 1);
        setValues(newValues);
    };

    const handleChange = (index: any, event: any) => {
        const newValues = [...values];
        newValues[index] = event.target.value;
        setValues(newValues);
    };

    return (
        <div>
            <Typography variant='h6'>{title}</Typography>
            {values.map((value: any, index: any) => (
                <div key={index} style={{ padding: "5px", display: "flex", alignItems: "center" }}>
                    <TextField
                        value={value}
                        onChange={(e) => handleChange(index, e)}
                        placeholder={`https://product.com/${title.toLowerCase()}`}
                        required={true}
                        fullWidth
                    />
                    <IconButton onClick={() => handleDelete(index)} style={{ color: "red" }}><DeleteIcon /></IconButton>
                </div>
            ))}
            <Button onClick={handleAdd}>Add More URLs</Button>
        </div>
    );
};

export default DynamicTextBoxes;
