import React, {useEffect, useState} from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { REACT_APP_BACKEND_URL } from "../constants";
import { toast } from "react-toastify";
import { Button, CircularProgress, Typography } from "@mui/material";
import { ScenarioDetails, TranscriptPanel } from "./simulation";
import ObjectionEvaluationComponent from "../components/objection/ObjectionEvaluationComponent";

export default function ViewSimulation() {
    const { productId, moduleId, scenarioId, simulationId, simulationRepEmail } = useParams()
    const [simulationDetails, setSimulationDetails] = useState<any>({})
    const [loading, setLoading] = useState<boolean>(true)
    const [isReevaluateLoading, setIsReevaluateLoading] = useState<boolean>(false)

    useEffect(() => {
        console.log("params", productId, moduleId, scenarioId, simulationId)
        if(productId !== undefined && moduleId !== undefined && scenarioId !== undefined && simulationId !== undefined && simulationRepEmail !== undefined){
            axios.get(`${REACT_APP_BACKEND_URL}/organization/${localStorage.getItem('org_id')}/product/${productId}/module/${moduleId}/scenario/${scenarioId}/simulation/${simulationId}?rep_email=${simulationRepEmail}`, {
                headers: { 'Authorization': `Bearer ${localStorage.getItem('google_access_token')}` }
            })
                .then((response) => {
                    setSimulationDetails(response.data.simulation)
                })
                .catch((error) => {
                    console.error(error)
                    toast.error("Failed to fetch simulations")
                })
                .finally(() => {
                    setLoading(false)
                })
        }
    }, [productId, moduleId, scenarioId, simulationId, simulationRepEmail])

    const handleReevaluateSimulation = () => {
        setIsReevaluateLoading(true)
        axios.post(`${REACT_APP_BACKEND_URL}/organization/${localStorage.getItem('org_id')}/product/${productId}/module/${moduleId}/scenario/${scenarioId}/simulation/${simulationId}/evaluate`, {
            rep_email: simulationRepEmail
          }, {
            headers: { 'Authorization': `Bearer ${localStorage.getItem('google_access_token')}` }
          })
            .then(() => {
                setSimulationDetails({...simulationDetails, evaluation_status: "in_progress"})
            })
            .catch((error) => {
                console.error(error)
                toast.error("Failed to reevaluate simulation")
            })
            .finally(() => {
                setIsReevaluateLoading(false)
            })
    }
    return (
        <div className="p-4">
            {
                loading ? (
                    <div style={{minHeight: "90vh"}} className="flex justify-center items-center">
                        <CircularProgress />
                    </div>
                ) : (
                    <div className="flex -mt-4" style={{height: "80vh"}}>
                        <div className="w-1/3">
                        <TranscriptPanel messages={simulationDetails?.messages} rep_name={"I C"} />
                        </div>
                        <div className="w-2/3 h-full">
                            <div className="bg-white p-6 rounded-lg shadow-md flex flex-col justify-center items-center space-x-6 mt-6" style={{borderRadius: "20px", overflowY: "scroll"}}>
                                <div className="flex w-full justify-center relative mb-7 items-center">
                                    <Typography variant='h5' sx={{display: "flex"}}>Objection Evaluation</Typography>
                                    <div style={{position: "absolute", right: "0"}}>
                                        {
                                            isReevaluateLoading ? (
                                                <CircularProgress />
                                            ) : (
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    style={{ backgroundColor: simulationDetails?.evaluation_status === "in_progress" ? "#EDF1F4" : "#45474B"}}
                                                    onClick={() => {handleReevaluateSimulation()}}
                                                    disabled = {simulationDetails?.evaluation_status === "in_progress"}
                                                >
                                                    Re-evaluate Simulation
                                                </Button>
                                            )
                                        }
                                    </div>
                                </div>
                                {
                                    simulationDetails?.evaluation_status === "failed" ? (
                                        <>Something went wrong while evaluating this simulation.</>
                                    ) : (
                                        <>
                                            {
                                                simulationDetails?.evaluation_status !== "in_progress" ? (
                                                    <ObjectionEvaluationComponent data={simulationDetails?.evaluated_objections} />
                                                ) : (
                                                    <>This Simulation is being evaluated. This can take a few minutes. Refresh the page to view latest changes.</>
                                                )
                                            }
                                        </>
                                    )
                                }

                            </div>
                            <ScenarioDetails scenarioDetails={simulationDetails?.parent_scenario}/>
                        </div>
                    </div>
                )
            }
        </div>
    )
}