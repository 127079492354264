// const urlElements = window.location.href.split('/')
// const HOST_URL = urlElements[0] + '//' + urlElements[2];
export const REACT_APP_BACKEND_URL = (process.env.REACT_APP_BACKEND_URL || '').replace(/\/$/, "")

export const REACT_APP_GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID
export const REACT_APP_GOOGLE_REDIRECT_URI = `${window.location.protocol}//${window.location.host}/signin`


export const MAX_SIMULATION_DURATION_IN_MINUTES = 20

export const BACKEND_WS_URL = (process.env.REACT_APP_BACKEND_WS_URL || '').replace(/\/$/, "")

export const GMEET_REDIRECT_URI = `${window.location.origin}/gmeet/callback`;
const GMEET_SCOPE = "https://www.googleapis.com/auth/drive+https://www.googleapis.com/auth/userinfo.email";
export const GMEET_AUTHORIZATION_URL = `https://accounts.google.com/o/oauth2/v2/auth?response_type=code&client_id=${REACT_APP_GOOGLE_CLIENT_ID}&redirect_uri=${GMEET_REDIRECT_URI}&scope=${GMEET_SCOPE}&state=google_login&prompt=consent&access_type=offline`;


export const ZOOM_REDIRECT_URI = `${window.location.origin}/zoom/callback`;
export const ZOOM_AUTHORIZATION_URL =  `https://zoom.us/oauth/authorize?response_type=code&client_id=${process.env.REACT_APP_ZOOM_CLIENT_ID}&redirect_uri=${ZOOM_REDIRECT_URI}`;

export const CALL_DESCRIPTION: { [key: string]: { [key: string]: any } } = {
    "Cold call": {
        "Description": "Cold calling is basically contacting potential customers without any prior contact or relationship. It\"s usually done in an effort to transform the prospect into a possible lead for the business.",
        "Customer Mindset": "Skeptical, cautious, busy. \"Why should I trust you?\" \"I don't have time for this.\"",
        "Customer Knowledge": "Little to no knowledge about the product or company.",
        "Customer Behavior": "Short attention span, polite dismissiveness, quick to end the call.",
        "Identifying Features": "The sales person delivers a short sales pitch to the customer whom he has never interacted with to buy a product.",
        "Customer Product Knowledge": []
    },
    "Warm call": {
        "Description": "When a sales professional connects with a prospect, who has already shown some interest in the brand\"s products or services via multiple mediums such as the website cold calling, or something else, it can be identified as a warm call.",
        "Customer Mindset": "Curious, somewhat interested, open to hearing more. \"Tell me more about how you can help.\"",
        "Customer Knowledge": "Some awareness of the product or company from prior interaction.",
        "Customer Behavior": "Engaged in conversation, asks questions, seeks clarification.",
        "Identifying Features": "The sales person tries to convince a customer with whom he has already interacted with to buy the product",
        "Customer Product Knowledge": ["name", "description"]
    },
    "Demo call": {
        "Description": "A sales appointment call is made to finally have an open discussion on the prospect\"s stress points and how the brand can help solve them.",
        "Customer Mindset": "Focused on solving their problems, and evaluating the product's fit. \"Can this really help me?\"",
        "Customer Knowledge": "Specific pain points and needs identified through prior conversations.",
        "Customer Behavior": "Actively participates in the demo, asks detailed questions, and compares to alternatives.",
        "Identifying Features": "The sales person tries to demonstrate the product to the customer and convince them to buy the product. In such types of calls, it should be explicitly mentioned that the customer has bought the product, or is willing to buy the product.",
        "Customer Product Knowledge": ["name", "description"]
    },
    "Follow-up call": {
        "Description": "Follow-up calls are made to the potential prospects to know about their decision regarding the purchase. In addition, these are calls made to know if the prospect with whom you are in the conversation has any purchasing plans soon.",
        "Customer Mindset": "Varies depending on their decision stage. Could be ready to buy, undecided, or no longer interested.",
        "Customer Knowledge": "A deeper understanding of the product and its value proposition.",
        "Customer Behavior": "Provides updates on their decision timeline, asks clarifying questions, and negotiates terms (if ready to buy).",
        "Identifying Features": "The sales person tries to convince the customer to buy the product",
        "Customer Product Knowledge": ['name','description','features','limitations','pricing_plans']
    },
    "Renewal call": {
        "Description": "A renewal call is made to existing customers to discuss renewing their subscription, contract, or service agreement. You demonstrate the value they've received from your product or service and address any concerns they may have.",
        "Customer Mindset": "Focuses on the continued value and potential risks of letting the product/service lapse. \"Is it worth renewing?\"",
        "Customer Knowledge": "Comprehensive understanding of the product/service's benefits and impact on their business.",
        "Customer Behavior": "Asks about renewal options and incentives, and negotiates renewal terms if necessary.",
        "Identifying Features": "The sales person tries to convince the customer to renew the product or the license of the product. If the product renewal or license renewal for a product is discussed, it is a renewal call",
        "Customer Product Knowledge": ['name','description','features','limitations','pricing_plans','renewal_terms']
    }
}

export const SAMPLE_CUSTOMER_PERSONA = [
    {
        "customer_name": "Megan Clark",
        "job_title": "IT Manager",
        "organization": "Acme Corporation",
        "org_industry": "Manufacturing",
        "behaviour": "Undecided and cautious, but open to discussion. Shows interest in understanding the full scope and benefits of the product.",
        "way_of_speaking": "Professional and direct, with a tendency to ask detailed questions for clarity."
    },
    {
        "customer_name": "Audrey Miller",
        "job_title": "IT Director",
        "organization": "RiverTech Inc.",
        "org_industry": "E-commerce",
        "behaviour": "Analytical and cautious, prefers to gather all the necessary information before making a decision.",
        "way_of_speaking": "Direct and to the point, but polite. Expects clear, concise answers."
    },
    {
        "customer_name": "Alex Rivera",
        "job_title": "Executive Director",
        "organization": "RP Inc.",
        "org_industry": "Advertisement",
        "behaviour": "Analytical and detail-oriented, occasionally skeptical",
        "way_of_speaking": "Direct and to the point, uses technical jargon"
    },
    {
        "customer_name": "Mariana Torres",
        "job_title": "Executive Director",
        "organization": "Hope and Aid Direct",
        "org_industry": "Non-Profit Organization",
        "behaviour": "Cautious and budget-conscious, seeks value in investments",
        "way_of_speaking": "Polite but firm, seeks clarity and reassurance on costs and benefits"
    }
]
export const EXPLICIT_OBJECTIONS = {
    "Price Objection": "This is one of the most common objections. Customers often feel that the product or service is too expensive or not worth the cost.",
    "Need Objection": "The customer does not believe they need the product or service. They might not see its value or relevance to their situation.",
    "Trust Objection": "This occurs when the customer does not trust the salesperson or the company they represent. Building trust is crucial for overcoming this objection.",
    "Competition Objection": "The customer might be considering or preferring a competitor's product or service.",
    "Authority Objection": "The person you are speaking to might not have the decision-making power or authority to make the purchase.",
    "Change Resistance Objection": "Some customers are resistant to change and prefer to stick with what they know or have used in the past.",
    "Source or Origin Objection": "The customer might have concerns about where or how the product is made or the service is provided.",
    "Quality or Performance Objection": "Concerns about the quality, durability, or performance of the product or service.",
    "Implementation Objection": "Concerns about the complexities or challenges in implementing or integrating the product or service.",
    "Post-Purchase Support Objection": "Worries about the kind of support and service that will be provided after the purchase.",
    "Not the Right Time Objection": "The customer is currently busy or preoccupied with other priorities and feels overwhelmed.",
    "Compatibility Objection": "The customer doubts whether the product or service will work with their existing systems, tools, or processes.",
    "Contract Terms Objection": "The customer expresses concerns about the terms and conditions of the contract of the deal being discussed.",
}

export const IMPLICIT_OBJECTIONS = {
    "Repeated Questions or Concerns": "If the customer repeatedly asks about a specific aspect of the product or service, it could indicate underlying worries about that feature.",
    "Minimal Responses": "Short, non-committal responses to proposals or suggestions, such as \"okay,\" \"I see,\" or \"right,\" might indicate indifference or lack of excitement.",
    "Hypothetical Questions":"Be mindful of questions like \"What if this doesn't work?\" or \"How would this impact X in the future?\" These could be roundabout ways to express uncertainty.",
}

export const allowedFileTypes = ['.doc','.docx','.txt','.pdf','.csv','.html','.json']

export const explicitObjectionDefinition = "Explicit objections in sales calls are direct concerns raised by prospects during conversations, typically regarding the product, service, price, or other aspects of the pitch."
export const implicitObjectionDefinition = "Implicit objections in sales calls are unspoken concerns conveyed through tone, body language, or subtle cues. These stem from underlying reservations that impact the prospect's decision-making, even if not directly expressed."

export const getDefaultModuleId=(product_id: string)=>{
    return `DefaultModule_${product_id}`
}