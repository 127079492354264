import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export const simulationSlice = createSlice({
  name: 'simulation',
  initialState: {
    value: {
        "productId": "",
        "scenarioId": "",
        "simulationId": ""    
    },
  },
  reducers: {
    setCurrentSimulation: (state: any, action: PayloadAction<any>) => {
        state.value = action.payload
    }
  },
})

// Action creators are generated for each case reducer function
export const { setCurrentSimulation } = simulationSlice.actions

export default simulationSlice.reducer