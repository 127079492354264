import React, { useState, useEffect } from 'react';
import MultiActionAreaCard from '../components/MultiActionAreaCard';
import { useDispatch, useSelector } from 'react-redux'
import { setModule } from '../redux/moduleSlice';
import axios from 'axios';
import { Button, CircularProgress, Typography } from '@mui/material';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import { REACT_APP_BACKEND_URL } from '../constants';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import { setScenarios } from '../redux/scenariosSlice';

const Module = () => {
    const [loading, setLoading] = useState(true)
    // const [scenarios, setScenarios] = useState<Record<string, any>[]>([])
    const dispatch = useDispatch()
    const scenarios = useSelector((state: any) => state.scenarios.value)
    const modules = useSelector((state: any) => state.modules.value)
    const { moduleId } = useParams()
    const navigate = useNavigate();

    useEffect(() => {
        if (moduleId !== undefined) {
            console.log("moduleID", moduleId, modules, modules[moduleId])
        }
        if (moduleId !== undefined && modules[moduleId]?.product_id) {
            axios.get(`${REACT_APP_BACKEND_URL}/organization/${localStorage.getItem('org_id')}/product/${modules[moduleId]?.product_id}/module/${moduleId}`, {
                headers: { 'Authorization': `Bearer ${localStorage.getItem('google_access_token')}` }
            })
                .then((response) => {
                    console.log("module is", response.data)
                    dispatch(setModule(response.data))
                    const scenarios_data: Record<string, any> = {}
                    response.data.scenarios.forEach((scenario: any) => {
                        scenarios_data[scenario.id] = {
                            ...scenario,
                            module_id: moduleId,
                            product_id: response.data.product_id,
                            product_name: response.data.product_name,
                            product_description: response.data.product_description,
                            product_keywords: response.data.product_keywords
                        }
                    })
                    dispatch(setScenarios(scenarios_data))
                })
                .catch((error) => {
                    console.log("error fetching module", error)
                    toast.error("Error fetching module")
                })
                .finally(() => {
                    setLoading(false)
                })
        }
    }, [moduleId])

    return (
        <div>
            {
                loading ? <div style={{ minHeight: "90vh" }} className="flex justify-center items-center"><CircularProgress /></div> :
                    (
                        <div>
                            {
                                moduleId !== undefined && (
                                    <div className="p-4 flex flex-col items-center">
                                        <div className="flex">
                                            <div className="w-full flex justify-center mb-3">
                                                <Typography variant='h3'>Select a Scenario</Typography>
                                            </div>
                                            {
                                                localStorage.getItem('is_manager') === 'true' ? (
                                                    <Button
                                                        onClick={() => { navigate(`/module/${moduleId}/results`) }}
                                                        style={{
                                                            position: "absolute",
                                                            right: "2%",
                                                            backgroundColor: '#45474B',
                                                            borderRadius: "100px",
                                                            color: 'white',
                                                            width: "fit-content",
                                                            padding: "10px 20px 10px 20px"
                                                        }}
                                                    >
                                                        <AssignmentTurnedInIcon />&nbsp;
                                                        View Results
                                                    </Button>
                                                ) : <></>
                                            }
                                        </div>
                                        <div className='flex mt-3'>
                                            {
                                                Object.entries(scenarios).map(([key, scenario]: [string, any]) => {
                                                    return (
                                                        <MultiActionAreaCard
                                                            key={key}
                                                            imageUrl={"https://www.salesforce.com/blog/wp-content/uploads/sites/2/2023/10/Sales_Quotas.jpg?w=889&h=500"} // TODO: replace with actual image
                                                            imageAltText={scenario.title}
                                                            cardTitle={scenario.title}
                                                            cardText={""}
                                                            navigateTo={`/scenario/${scenario.id}`}
                                                        />
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    )
            }
        </div>
    )
}

export default Module;