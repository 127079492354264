import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { REACT_APP_BACKEND_URL, allowedFileTypes } from '../constants';
import { useNavigate, useParams } from 'react-router-dom';
import UploadButtons from '../components/upload/uploadFile';
import CircularProgress from '@mui/material/CircularProgress';
import ProductObjection from '../components/objection/product_objection';
import ProductScenario from '../components/scenario/ProductScenario';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import { toast } from 'react-toastify';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ProductModules from '../components/modules/modules';
import VideoCallIcon from '@mui/icons-material/VideoCall';
import InfoIcon from '@mui/icons-material/Info';
import BookIcon from '@mui/icons-material/Book';
import SchoolIcon from '@mui/icons-material/School';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import { Modal, Box } from '@mui/material';
import DynamicTextBoxes from '../components/DynamicTextBoxes'; // Import the DynamicTextBoxes component
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Tooltip } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import DeleteWithConfirmation from '../components/DeleteWithConfirmation';
import { useDispatch, useSelector } from 'react-redux';
import { setAllProducts, setNavbarProducts } from '../redux/productSlice';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    p: 5,
    borderRadius: 5,
    outline: null,
    maxHeight: '90vh',
    overflowY: 'auto'
};

const metadataKeys = {
    features: 'Features',
    limitations: 'Limitations',
    pricing_plans: 'Pricing',
    renewal_terms: 'Sales Terms',
    keywords: 'Keywords',
};

const ListComponent = ({ editedProductDetails, handleEditProduct, metadataKey }: any) => {
    const addListItem = (key: string) => {
        const updatedProductDetails = { ...editedProductDetails };
        if (!updatedProductDetails[key]) {
            updatedProductDetails[key] = [''];
        } else {
            updatedProductDetails[key].push('');
        }
        handleEditProduct(updatedProductDetails);
    };

    const removeListItem = (key: string, index: number) => {
        const updatedProductDetails = { ...editedProductDetails };
        updatedProductDetails[key].splice(index, 1);
        handleEditProduct(updatedProductDetails);
    };

    const handleInputChange = (e: any, key: string, index: number) => {
        const updatedProductDetails = { ...editedProductDetails };
        updatedProductDetails[key][index] = e.target.value;
        handleEditProduct(updatedProductDetails);
    };
    return (
        <div className="mt-5">
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", marginTop: "20px", marginBottom: "10px" }}>
                <Typography variant="h5" style={{ marginLeft: "10px", fontWeight: "500" }}>{metadataKeys[metadataKey as keyof typeof metadataKeys]}</Typography>
                <Button variant="contained" style={{ backgroundColor: "#393E46" }} onClick={() => addListItem(metadataKey)}>
                    <AddIcon style={{ color: 'white' }} />
                </Button>
            </div>
            {editedProductDetails[metadataKey]?.map((item: string, idx: number) => (
                <div key={`${metadataKey}-${idx}`} style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                    <TextField
                        fullWidth
                        value={item}
                        style={{ backgroundColor: "white" }}
                        onChange={(e) => handleInputChange(e, metadataKey, idx)}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                addListItem(metadataKey);
                            }
                        }}
                        InputProps={{
                            endAdornment: (
                                <IconButton onClick={() => removeListItem(metadataKey, idx)}>
                                    <DeleteIcon style={{ color: 'red' }} />
                                </IconButton>
                            ),
                        }}
                    />
                </div>
            ))}
        </div>
    )
}

const BasicProductDetails = ({ productDetails, setProductDetails }: any) => {
    const [editedProductDetails, setEditedProductDetails] = useState<any>(productDetails);
    const [hasChanges, setHasChanges] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);
    const [productUpdateLoading, setProductUpdateLoading] = useState<boolean>(false);
    const [showUploadModal, setShowUploadModal] = useState<boolean>(false); // New state variable for upload modal
    const [featureValues, setFeatureValues] = useState<string[]>(['']); // State for feature values
    const [pricingValues, setPricingValues] = useState<string[]>(['']); // State for pricing values
    const [descriptionValues, setDescriptionValues] = useState<string[]>(['']);
    const [productFiles, setProductFiles] = useState<File[]>([]);
    const [fetchingProductDetails, setFetchingProductDetails] = useState<boolean>(false);
    const inputFileRef = useRef<HTMLInputElement>(null);
    const [openDeletePopup, setOpenDeletePopup] = useState<boolean>(false);
    const [deletingProduct, setDeletingProduct] = useState<boolean>(false);
    const products = useSelector((state: any) => state.products.navbarProducts);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const getFileType = (file: File): string => {
        const extension = file.name.split('.').pop()?.toLowerCase() || '';
        return '.' + extension;
    };
    const getPresignedUrls = async (productID: string) => {
        try {
            const urls: any[] = [];
            for (const file of productFiles) {
                const response = await axios.get(REACT_APP_BACKEND_URL + '/ingestion/presigned_url/product_docs', {
                    params: {
                        file_type: getFileType(file),
                        orgID: localStorage.getItem('org_id'),
                        productID: productID,
                        file_name: file.name
                    },
                    headers: { 'Authorization': `Bearer ${localStorage.getItem('google_access_token')}` }
                });
                urls.push(response.data);
            }
            return urls;
        } catch (error) {
            console.error('Error getting presigned URLs:', error);
            toast.error('Error getting presigned URLs. Please try again.');
            // setOpen(true);
        }
    };
    const uploadFiles = async (presignedUrls: any) => {
        try {
            const uploadPromises = presignedUrls.map((url: any, index: any) => {
                const formData = new FormData();
                Object.entries(url.fields).forEach(([key, value]: any) => {
                    formData.append(key, value);
                });
                formData.append('file', productFiles[index]);
                return axios.post(url.url, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
            });
            await Promise.all(uploadPromises);
        }
        catch (error) {
            console.error('Error uploading files:', error);
            toast.error('Error uploading files. Please try again.');
        }
        finally {
            setProductFiles([]);
            if (inputFileRef.current) {
                inputFileRef.current.value = ''; // Clear the value
            }
        }
    }
    const handleUploadSubmit = async (productID: string) => {
        try {
            setProductUpdateLoading(true);
            const data = []
            if (featureValues.filter(value => value.trim() !== '').length > 0) {
                data.push({
                    title: "Features",
                    urls: featureValues.filter(value => value.trim() !== '')
                })
            }
            if (pricingValues.filter(value => value.trim() !== '').length > 0) {
                data.push({
                    title: "Pricing",
                    urls: pricingValues.filter(value => value.trim() !== '')
                })
            }
            if (descriptionValues.filter(value => value.trim() !== '').length > 0) {
                data.push({
                    title: "Description",
                    urls: descriptionValues.filter(value => value.trim() !== '')
                })
            }
            if (productFiles.length > 0) {
                const presignedUrls = await getPresignedUrls(productID);
                await uploadFiles(presignedUrls);
            }
            // console.log(data);
            // Send data to backend
            await axios.post(`${REACT_APP_BACKEND_URL}/organization/${localStorage.getItem('org_id')}/product/${productID}/onboard`, {
                product_info_urls: data,
                skip_processing_uncategorized_calls: true
            }, { headers: { "Authorization": `Bearer ${localStorage.getItem('google_access_token')}` } });
            // Reset values
            setFeatureValues(['']);
            setPricingValues(['']);
            setDescriptionValues(['']);
            setShowUploadModal(false);
            setProductUpdateLoading(false);
            toast.info("It takes a few minutes to extract the product information. You will receive an email once the product details are updated")
        } catch (error) {
            console.error('Error uploading data:', error);
        }
    };
    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const fileList = event.target.files;
        const maxFiles = 5;
        if (fileList && fileList.length > maxFiles) {
            toast.error(`You can only upload ${maxFiles} files at a time.`);
            // setOpen(true);
            if (inputFileRef.current) {
                inputFileRef.current.value = ''; // Clear the value
            }
            return;
        }
        if (fileList) {
            setProductFiles(Array.from(fileList));
        }
    };

    const fetchProductDetails = async () => {
        try {
            setFetchingProductDetails(true);
            const response = await axios.get(`${REACT_APP_BACKEND_URL}/organization/${localStorage.getItem('org_id')}/product/${productDetails.id}`, {
                headers: { 'Authorization': `Bearer ${localStorage.getItem('google_access_token')}` }
            });
            setProductDetails(response.data);
            setFetchingProductDetails(false);
        } catch (error) {
            console.error('Error fetching product details:', error);
        }
    };

    useEffect(() => {
        setEditedProductDetails(productDetails);
    }, [productDetails]);

    const handleEditProduct = (updatedProductDetails: any) => {
        setEditedProductDetails(updatedProductDetails);
        setHasChanges(true);
    }

    useEffect(() => {
        console.log(editedProductDetails);
    }, [editedProductDetails])

    const handleSubmit = async () => {
        try {
            const filteredProductDetails: any = {};
            for (const key in editedProductDetails) {
                if (!Array.isArray(editedProductDetails[key])) {
                    filteredProductDetails[key] = editedProductDetails[key]
                    continue;
                }
                const filteredList = editedProductDetails[key].filter((item: any) => item.trim() !== '');
                if (filteredList.length > 0) {
                    filteredProductDetails[key] = filteredList;
                }
            }
            console.log("filtered product details", filteredProductDetails);
            setIsUpdating(true);
            await axios.patch(`${REACT_APP_BACKEND_URL}/organization/${localStorage.getItem('org_id')}/product/${productDetails.id}`, filteredProductDetails, {
                headers: { 'Authorization': `Bearer ${localStorage.getItem('google_access_token')}` }
            });
            setProductDetails(filteredProductDetails);
            toast.success('Product details updated successfully');
            setHasChanges(false);
            setIsUpdating(false);
        } catch (error) {
            console.error('Error updating product details:', error);
            toast.error('Error updating product details');
        }
    };

    const handleDeleteProduct = async () => {
        setDeletingProduct(true);
        try{
            axios.delete(`${REACT_APP_BACKEND_URL}/organization/${localStorage.getItem('org_id')}/product/${productDetails.id}`, {
                headers: { 'Authorization': `Bearer ${localStorage.getItem('google_access_token')}` }
            });
            toast.success(`${productDetails.name} has been deleted successfully`);
            const products_data: Record<string, any> = {}
            products.forEach((product: any) => {
                products_data[product.id] = product
            })
            dispatch(setAllProducts(products_data));
            dispatch(setNavbarProducts(products.filter((product: any) => product.id !== productDetails.id)));
            navigate('/manager/products');
        }
        catch(error){
            console.error("Error deleting product", error)
            toast.error(`Something went wrong while deleting ${productDetails.name}. Please try again later.`)
        }
        setDeletingProduct(false);
    }

    return (
        <div className='flex flex-col items-center w-full'>
            <div className="flex flex-col" style={{ width: "100%" }}>
                <div className="flex justify-between">
                    <div style={{width: "70px"}}>
                        {
                            fetchingProductDetails ? (
                                <CircularProgress />
                            ) : (
                                <Tooltip title={<span style={{fontSize: "16px"}}>Refresh Product Details</span>}>
                                    <IconButton onClick={() => {fetchProductDetails()}} style={{width: "fit-content"}}>
                                        <RefreshIcon style={{width: "40px", height: "40px", color: "#393E46"}} /> 
                                    </IconButton>
                                </Tooltip>
                            )
                        }
                    </div>
                    <div className="flex items-center">
                        {
                            deletingProduct ? (
                                <CircularProgress />
                            ) : (
                                <DeleteWithConfirmation
                                    subject={`Delete Product?`}
                                    description={`Are you sure you want to delete this product? This will delete all objections, scenarios, and modules associated with this product. This action cannot be undone.`}
                                    handleDelete={() => handleDeleteProduct()}
                                />
                            )
                        }
                        <Button
                            onClick={() => setShowUploadModal(true)}
                            style={{backgroundColor: "#393E46", color: "white", width: "fit-content", padding: "10px", fontWeight: "600", borderRadius: "100px", marginLeft: "10px"}}
                        >Auto-generate Product Details</Button>
                    </div>
                </div>
                <Modal
                    open={showUploadModal}
                    onClose={() => {setShowUploadModal(false);}}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <div className="flex justify-center items-center">
                            <Typography variant='h4' sx={{ "marginLeft": "10px" }}>
                                Product Information
                            </Typography>
                        </div>
                        <div className="flex flex-col">

                            <div>
                                <DynamicTextBoxes
                                    title="Description"
                                    values={descriptionValues}
                                    setValues={setDescriptionValues}
                                />
                                <DynamicTextBoxes
                                    title="Features"
                                    values={featureValues}
                                    setValues={setFeatureValues}
                                />
                                <DynamicTextBoxes
                                    title="Pricing"
                                    values={pricingValues}
                                    setValues={setPricingValues}
                                />
                            </div>
                            <input
                                ref={inputFileRef}
                                type="file"
                                accept={allowedFileTypes.join(',')}
                                onChange={handleFileChange}
                                multiple
                                style={{ padding: "10px", margin: "10px" }}
                                disabled={productUpdateLoading}
                            />
                            <Dialog
                                open={openDeletePopup}
                                onClose={() => setOpenDeletePopup(false)}
                            >
                                <DialogTitle id="alert-dialog-title">
                                    This action will overwrite all existing product information. 
                                </DialogTitle>
                                <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    This action will overwrite all existing product information with information extracted <strong>only</strong> from the selected documents. This action cannot be undone. Are you sure you want to proceed?
                                </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button 
                                        onClick={() => {
                                            handleUploadSubmit(productDetails.id)
                                            setOpenDeletePopup(false)
                                        }}
                                    >
                                        Yes
                                    </Button>
                                    <Button onClick={() => setOpenDeletePopup(false)} autoFocus>
                                        No
                                    </Button>
                                </DialogActions>
                            </Dialog>
                            {productUpdateLoading ? (
                                <div className="w-full flex justify-center">
                                    <CircularProgress />
                                </div>
                            ) : (
                                <>
                                    <Button
                                        onClick={() => setOpenDeletePopup(true)}
                                        style={{
                                            backgroundColor: '#393E46',
                                            color: 'white',
                                            padding: "10px 20px 10px 20px",
                                            marginTop: "20px"
                                        }}
                                    >
                                        Update
                                    </Button>
                                    <div className="text-center mt-2"> <strong>Note:&nbsp;</strong>You will receive an email when the product details get onboarded if your email is verified. Contact the CallCoach developer team to verify your email.</div>
                                </>
                            )}
                        </div>
                    </Box>
                </Modal>
                <Typography variant="h5" style={{ marginLeft: "10px", margin: "20px 0 10px 10px" }}>Description</Typography>
                <TextField
                    multiline
                    value={editedProductDetails.description || ''}
                    onChange={(e) => handleEditProduct({ ...editedProductDetails, description: e.target.value })}
                    style={{ width: "100%" }}
                    fullWidth
                    minRows={3}
                />
                <ListComponent editedProductDetails={editedProductDetails} handleEditProduct={handleEditProduct} metadataKey={"features"} />
                <ListComponent editedProductDetails={editedProductDetails} handleEditProduct={handleEditProduct} metadataKey={"limitations"} />
                <ListComponent editedProductDetails={editedProductDetails} handleEditProduct={handleEditProduct} metadataKey={"pricing_plans"} />
                <ListComponent editedProductDetails={editedProductDetails} handleEditProduct={handleEditProduct} metadataKey={"renewal_terms"} />
                <ListComponent editedProductDetails={editedProductDetails} handleEditProduct={handleEditProduct} metadataKey={"keywords"} />
            </div>
            {
                isUpdating ? (
                    <CircularProgress style={{ marginTop: "20px" }} />
                ) : (
                    <Button
                        variant="contained"
                        disabled={!hasChanges}
                        style={{ width: "fit-content", marginTop: "20px" }}
                        onClick={handleSubmit}
                    >Update</Button>
                )
            }
        </div>
    );
}

const Product = () => {
    const { id } = useParams();
    const [selectedTab, setSelectedTab] = useState<any>('1');
    const [productDetails, setProductDetails] = useState<any>(null);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const fetchProductDetails = async () => {
        try {
            const response = await axios.get(`${REACT_APP_BACKEND_URL}/organization/${localStorage.getItem('org_id')}/product/${id}`, {
                headers: { 'Authorization': `Bearer ${localStorage.getItem('google_access_token')}` }
            });
            setProductDetails(response.data);
            setLoading(false);
        } catch (error) {
            toast.error('Couldn\'t find the product. Please make sure you have access to the product or change organization.');
            console.error('Error fetching product details:', error);
            navigate('/manager/products',{replace: true});
        }
    };

    useEffect(() => {
        fetchProductDetails();
    }, [id]);

    return (
        <div>
            {loading ? (
                <div style={{ minHeight: '80vh' }} className="flex justify-center items-center">
                    <CircularProgress />
                </div>
            ) : (
                <>
                    <div className="flex">
                        <Tabs
                            value={selectedTab}
                            onChange={(e, value) => { console.log(e); setSelectedTab(value); }}
                            TabIndicatorProps={{ style: { display: "none" } }}
                            orientation="vertical"
                            sx={{ border: "3px solid #393E46", height: "fit-content", borderRadius: "15px", width: "14%" }}
                            centered={true}
                        >
                            <Tab value="0" label="Product Details"
                                style={{
                                    backgroundColor: selectedTab !== '0' ? "white" : "#D4D4D4",
                                    fontWeight: selectedTab !== '0' ? "400" : "600",
                                    color: "black",
                                    justifyContent: "flex-start"
                                }}
                                icon={<InfoIcon />}
                                iconPosition='start'
                            />
                            <Tab value="1" label="Call Recordings"
                                style={{
                                    backgroundColor: selectedTab !== '1' ? "white" : "#D4D4D4",
                                    fontWeight: selectedTab !== '1' ? "400" : "600",
                                    color: "black",
                                    justifyContent: "flex-start"
                                }}
                                icon={<VideoCallIcon />}
                                iconPosition='start'
                            />
                            <Tab value="2" label="Objections Catalog"
                                style={{
                                    backgroundColor: selectedTab !== '2' ? "white" : "#D4D4D4",
                                    fontWeight: selectedTab !== '2' ? "400" : "600",
                                    color: "black",
                                    justifyContent: "flex-start"
                                }}
                                icon={<AnalyticsIcon />}
                                iconPosition='start'
                            />
                            <Tab value="3" label="Scenarios Catalog"
                                style={{
                                    backgroundColor: selectedTab !== '3' ? "white" : "#D4D4D4",
                                    fontWeight: selectedTab !== '3' ? "400" : "600",
                                    color: "black",
                                    justifyContent: "flex-start"

                                }}
                                icon={<BookIcon/>}
                                iconPosition='start'
                            />
                            <Tab value="4" label="Modules Catalog"
                                style={{
                                    backgroundColor: selectedTab !== '4' ? "white" : "#D4D4D4",
                                    fontWeight: selectedTab !== '4' ? "400" : "600",
                                    color: "black",
                                    justifyContent: "flex-start"
                                }}
                                icon={<SchoolIcon />}
                                iconPosition='start'
                            />
                        </Tabs>

                        <div style={{
                            marginLeft: "20px",
                            width: "87%",
                            border: "3px solid #393E46",
                            backgroundColor: "white",
                            borderRadius: "20px",
                            transition: "border-radius 0.1s",
                            position: "relative",
                            top: "-2.78px"
                        }}>
                            <div className="m-8 mt-4" style={{ minHeight: "70vh", position: "relative" }}>
                                {selectedTab === '0' && <BasicProductDetails productDetails={productDetails} setProductDetails={setProductDetails} />}
                                {selectedTab === '1' && <UploadButtons productID={id || ''} changeToObjectionTab={() => setSelectedTab("2")} />}
                                {selectedTab === '2' && <ProductObjection productID={id || ''} />}
                                {selectedTab === '3' && <ProductScenario productID={id || ''} description={productDetails?.description || ''} />}
                                {selectedTab === '4' && <ProductModules productID={id || ''} />}
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default Product;
