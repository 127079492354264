import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { CALL_DESCRIPTION, REACT_APP_BACKEND_URL } from '../../constants';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';

import { toast } from 'react-toastify';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import ScenarioCard from '../scenarioCard';
import TableContainer from '@mui/material/TableContainer';
import FormControl from '@mui/material/FormControl';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
// import Tooltip from '@mui/material/Tooltip';
import Table from '@mui/material/Table';
import Slider from '@mui/material/Slider';
import InfoIcon from '@mui/icons-material/Info';
import { InputLabel, Link, MenuItem, Select, Tab, Tabs, TextField, Tooltip } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import { SAMPLE_CUSTOMER_PERSONA } from '../../constants'
import { DataGrid, GridColDef, GridRenderCellParams, GridToolbar} from '@mui/x-data-grid';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DeleteWithConfirmation from '../DeleteWithConfirmation';

interface ProductScenarioProps {
    productID: string;
    description: string;
}

const scenarioRefreshInterval = 15000; // 15 seconds

const ProductScenario: React.FC<ProductScenarioProps> = ({ productID, description }) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [fetchingScenarios, setFetchingScenarios] = useState(false);
    const [scenarios, setScenarios] = useState<any[]>([]);
    const [createScenarioSelectedTab, setCreateScenarioSelectedTab] = useState<string>("auto");
    const [selectedCallTypes, setSelectedCallTypes] = useState<any>({}); // State to manage selected call types
    const [open, setOpen] = useState(false);
    const [isAnyScenarioUnderCreation, setIsAnyScenarioUnderCreation] = useState(false);
    const [selectedCalls, setSelectedCalls] = useState<any>({}); // State to manage selected calls
    const [customScenarioDetails, setCustomScenarioDetails] = useState<any>({});
    const fileStatusIntervalRef = useRef<any>();
    const [fetchingObjections, setFetchingObjections] = useState(false);
    const [explicitObjections, setExplicitObjections] = useState<any[]>([]);
    const [implicitObjections, setImplicitObjections] = useState<any[]>([]);
    const [selectedExplicit, setSelectedExplicit] = useState<any[]>([]);
    const [selectedImplicit, setSelectedImplicit] = useState<any[]>([]);
    const [isSelecting, setIsSelecting] = useState<boolean>(false);
    const [selectedScenarioIds, setSelectedScenarioIds] = useState<string[]>([]);
    const [deleteScenariosLoading, setDeleteScenariosLoading] = useState<boolean>(false);

    const handleClose = () => { setOpen(false); setSelectedCallTypes({}); setSelectedCalls({}); };
    const Loader = () => {
        return (
            <div style={{ minHeight: "90vh" }} className="flex justify-center items-center">
                <CircularProgress />
            </div>
        );
    }
    const fetchScenarios = async () => {
        try {
            setFetchingScenarios(true);

            const response = await axios.get(`${REACT_APP_BACKEND_URL}/organization/${localStorage.getItem('org_id')}/product/${productID}/scenarios`, {
                headers: { 'Authorization': `Bearer ${localStorage.getItem('google_access_token')}` }
            });
            setScenarios(response.data.scenarios)
        } catch (error) {
            console.error('Error fetching scenarios:', error);
            toast.error('Something went wrong!\n' + error)
        }
        setFetchingScenarios(false);
    }
    const handleAutoScenarioSubmission = async () => {
        try {
            if (Object.keys(selectedCallTypes).length === 0) {
                toast.error('Please select at least one call type');
                return;
            }
            // console.log('Submitting call types:', selectedCallTypes);
            //TODO: Add logic to submit call types to backend
            setLoading(true);
            await axios.post(`${REACT_APP_BACKEND_URL}/organization/${localStorage.getItem('org_id')}/product/${productID}/scenarios`, {
                call_types: selectedCallTypes,
                product_details: description
            }, {
                headers: { 'Authorization': `Bearer ${localStorage.getItem('google_access_token')}` }
            });
            toast.info("Scenarios will be added in a few minutes")
            await fetchScenarios();
            setLoading(false);
            // console.log('Response:', response);
            handleClose();
            setSelectedCallTypes({});

        } catch (error) {
            console.error('Error submitting call types:', error);
            toast.error('Something went wrong while generating scenarios')
            // Optionally, you can add logic to handle errors
        }
    };
    
    const fetchAggregatedObjections = async () => {
        try {
            setFetchingObjections(true);
            const response = await axios.get(`${REACT_APP_BACKEND_URL}/organization/${localStorage.getItem('org_id')}/product/${productID}/objections`, {
                headers: { 'Authorization': `Bearer ${localStorage.getItem('google_access_token')}` }
            });
            const explicit: any = [];
            const implicit: any = [];
            for (const obj of response.data.objections) {
                if (obj['category'] === "Explicit") {
                    explicit.push(obj)
                }
                else {
                    implicit.push(obj)
                }
            }
            if (explicit.length === 0){
                toast.error("There should be at least one explicit objection to create a scenario")
                handleClose()
                return
            }
            setExplicitObjections(explicit);
            setImplicitObjections(implicit);
        } catch (error) {
            console.error('Error fetching aggregated objections:', error);
            toast.error('Something went wrong while fetching objections')
        } finally {
            setFetchingObjections(false);
        }
    }

    const handleOpen = () => {
        if (!description){
            toast.error("Please add product description first")
            return
        }
        const customer_details = SAMPLE_CUSTOMER_PERSONA[Math.floor(Math.random() * SAMPLE_CUSTOMER_PERSONA.length)]
        setCustomScenarioDetails({ ...customer_details, qna: [{question: "", answer: ""}], agenda: {goals: [""], topics: [""] } })
        setOpen(true);
        fetchAggregatedObjections();
    }

    const handleCheckboxChange = (call: string) => {
        setSelectedCalls((prevState: any) => ({
            ...prevState,
            [call]: !prevState[call]
        }));
        setSelectedCallTypes((prevState: any) => {
            const newState = { ...prevState };
            if (!prevState[call]) {
                newState[call] = 1; // Initialize to some default value
            } else {
                delete newState[call];
                const calltype = { ...selectedCallTypes };
                delete calltype[call]
                setSelectedCallTypes(calltype);
            }
            return newState;
        });
    };
    const handleCallTypeChange = (call: string, value: number) => {
        setSelectedCallTypes((prevState: any) => ({
            ...prevState,
            [call]: value
        }));
    };

    const handleCustomScenarioSubmission = async () => {
        try {
            const missingFields: string[] = []
            const requiredKeys = ['title', 'callType', 'agenda', 'previous_interaction_context', 'previous_interaction_customer_message', 'customer_name', 'organization', 'job_title', 'org_industry', 'way_of_speaking', 'behaviour']
            requiredKeys.forEach(key => {
                if (!customScenarioDetails[key]) {
                    missingFields.push(key)
                }
            })
            if (missingFields.length > 0) {
                toast.error(`Please fill the following fields: ${missingFields.join(', ')}`)
                return
            }
            if (selectedExplicit.length==0) {
                toast.error('Please select at least one explicit objection')
                return
            }
            setLoading(true);
            console.log(selectedExplicit)

            await axios.post(`${REACT_APP_BACKEND_URL}/organization/${localStorage.getItem('org_id')}/product/${productID}/scenario`, {
                title: customScenarioDetails.title,
                explicit_objections: selectedExplicit.map((id)=>{
                    const objection = explicitObjections.find((obj: any) => obj.id === id);
                    return {
                        "id":id,
                        "objection": objection.description,
                        "objection_type": objection.objection_type,
                        "possible_contexts": objection.children.map((child:any) => child.context).sort(() =>Math.random() - 0.5).slice(0, Math.min(4, objection.children.length)),
                    }
                }),
                implicit_objections: selectedImplicit.map((id)=>{
                    const objection = implicitObjections.find((obj: any) => obj.id === id);
                    return {
                        "id":id,
                        "objection": objection.description,
                        "objection_type": objection.objection_type,
                        "possible_contexts": objection.children.map((child:any) => child.context).sort(() =>Math.random() - 0.5).slice(0, Math.min(4, objection.children.length)),
                    }
                }),
                call_type: customScenarioDetails.callType,
                customer_metadata: {
                    name: customScenarioDetails.customer_name,
                    job_title: customScenarioDetails.job_title,
                    organization: customScenarioDetails.organization,
                    org_industry: customScenarioDetails.org_industry,
                    behaviour: customScenarioDetails.behaviour,
                    gender: "Female",
                    way_of_speaking: customScenarioDetails.way_of_speaking
                },
                customers_product_knowledge: CALL_DESCRIPTION[customScenarioDetails.callType]['Customer Product Knowledge'],
                previous_interaction: {
                    context: customScenarioDetails.previous_interaction_context,
                    customer_message: customScenarioDetails.previous_interaction_customer_message
                },
                agenda: {
                    goals: customScenarioDetails.agenda.goals.filter((goal: string) => goal),
                    topics: customScenarioDetails.agenda.topics.filter((topic: string) => topic)
                },
                qna: customScenarioDetails.qna.filter((qna: any) => qna.question && qna.answer).map((qna: any) => ({ question: qna.question, answer: qna.answer }))
            }, {
                headers: { 'Authorization': `Bearer ${localStorage.getItem('google_access_token')}` }
            });
            toast.success("Scenario has been added successfully")
            await fetchScenarios()
            // setScenarios([...scenarios, response.data]);
            handleClose();
            setCustomScenarioDetails({});
        } catch (error) {
            console.error('Error submitting custom scenario:', error);
            toast.error('Something went wrong while generating scenarios')
        } finally {
            setLoading(false);
        }
    }
    useEffect(() => {
        fetchScenarios();
    }, [])

    useEffect(() => {
        console.log('Scenarios:', scenarios);
    }, [scenarios])

    useEffect(() => {
        let isProcessing = false;
        for (const scenario of scenarios) {
            if (scenario.status === 'Creating') {
                isProcessing = true;
                setIsAnyScenarioUnderCreation(true)
                break
            }
        }
        if (isProcessing) {
            fileStatusIntervalRef.current = setInterval(() => {
                fetchScenarios();
            }, scenarioRefreshInterval);
        }
        else {
            if (isAnyScenarioUnderCreation) {
                toast.success("Scenarios have been createed successfully!")
            }
            setIsAnyScenarioUnderCreation(false)
            clearInterval(fileStatusIntervalRef.current);
        }
        return () => {
            clearInterval(fileStatusIntervalRef.current);
        };
    }, [scenarios]);
    function ExpandableCell({ value }: GridRenderCellParams) {
        const [expanded, setExpanded] = React.useState(false);

        return (
            <div>
                {expanded ? value : value.slice(0, 100)}&nbsp;
                {value.length > 100 && (
                    <Link
                        type="button"
                        component="button"
                        sx={{ fontSize: 'inherit' }}
                        onClick={() => setExpanded(!expanded)}
                    >
                        {expanded ? ' shrink' : ' expand'}
                    </Link>
                )}
            </div>
        );
    }
    const handleSelectedScenarios = async () => {
        setDeleteScenariosLoading(true)
        try{
            await axios.post(`${REACT_APP_BACKEND_URL}/organization/${localStorage.getItem('org_id')}/product/${productID}/delete_scenarios`, {
                scenario_ids: selectedScenarioIds
            },
            {
                headers: { 'Authorization': `Bearer ${localStorage.getItem('google_access_token')}` }
            })
            setScenarios(scenarios.filter((scenario) => !selectedScenarioIds.includes(scenario.id)))
            setSelectedScenarioIds([])
            toast.success("Scenarios have been deleted successfully")
            setIsSelecting(false)
        }
        catch(error){
            console.error('Error deleting scenarios:', error);
            toast.error('Something went wrong while deleting scenarios')
        }
        setDeleteScenariosLoading(false)
    }
    const objectionColumns: GridColDef[] = [
        {
            field: 'objection_type', flex: 0.5, renderHeader: () => (
                <div>
                    <strong>Objection Type</strong>
                </div>
            ),
            // renderCell: (params: GridRenderCellParams) => { return params.value != null ? <ExpandableCell {...params} /> : <></> },
        },
        {
            field: 'description', flex: 1, renderHeader: () => (
                <div>
                    <strong>Description</strong>
                    <Tooltip style={{ marginLeft: "10px", fontSize: "20px" }} title={<span style={{ fontSize: "16px" }}>Summary of the objection raised by the customer during sales call</span>}>
                        <HelpOutlineIcon />
                    </Tooltip>
                </div>
            ),
            renderCell: (params: GridRenderCellParams) => <ExpandableCell {...params} />,
        },
        {
            field: 'ideal_answer', flex: 1, renderHeader: () => (
                <div>
                    <strong>Ideal Answer</strong>
                    <Tooltip style={{ marginLeft: "10px", fontSize: "20px" }} title={<span style={{ fontSize: "16px" }}>This shows the ideal way to handle the objection, you can edit this by clicking on {'View Details'}. This will help improve the evalaution of your sales rep.</span>}>
                        <HelpOutlineIcon />
                    </Tooltip>
                </div>
            ),
            renderCell: (params: GridRenderCellParams) => { return params.value != null ? <ExpandableCell {...params} /> : <></> },
        },

    ];
    const handleSelectionExplicit = (data: any) => {
        setSelectedExplicit(data);
    }
    const handleSelectionImplicit = (data: any) => {
        setSelectedImplicit(data);
        console.log(data)
    }

    const handleScenarioSelection = (selectionId: string) => {
        setSelectedScenarioIds((prevState) => {
            if (prevState.includes(selectionId)) {
                return prevState.filter((id) => id !== selectionId);
            }
            return [...prevState, selectionId];
        })
    }
    return (
        <div className="flex flex-col items-center">
            {(fetchingScenarios && scenarios.length === 0) ? <Loader /> :
                (
                    <>
                        <div className="flex relative w-full justify-between items-center mt-3 mb-6">
                            <div >
                                <div style={{ width: "70px" }}>
                                    {
                                        fetchingScenarios ? (
                                            <CircularProgress />
                                        ) : (
                                            <Tooltip title={<span style={{ fontSize: "16px" }}>Refresh scenarios</span>}>
                                                <IconButton onClick={() => fetchScenarios()} style={{ width: "fit-content" }}>
                                                    <RefreshIcon style={{ width: "40px", height: "40px", color: "#393E46" }} />
                                                </IconButton>
                                            </Tooltip>
                                        )
                                    }
                                </div>
                            </div>
                            <div className="flex items-center">
                                {
                                    isSelecting && (
                                        <>
                                        {
                                            deleteScenariosLoading ? <CircularProgress /> : 
                                                <DeleteWithConfirmation 
                                                    subject={`Delete ${selectedScenarioIds.length} scenarios`}
                                                    description={"Are you sure you want to delete the selected scenarios?"}
                                                    handleDelete={() => {handleSelectedScenarios()}}
                                                />
                                        }
                                        </>
                                    )
                                }
                                <Button 
                                    variant='contained' 
                                    style={{ background: "#393E46", borderRadius: "100px", marginRight: "10px", marginLeft: "10px"}} 
                                    onClick={() => {setIsSelecting(prevIsSelecting => !prevIsSelecting); setSelectedScenarioIds([])}}>
                                    Select Scenarios
                                </Button>
                                <Button variant='contained' style={{ background: "#393E46", borderRadius: "100px", }} onClick={handleOpen}>
                                    <AddIcon /> &nbsp;
                                    Add Scenario
                                </Button>
                            </div>
                        </div>
                        {
                            scenarios.length === 0 && !fetchingScenarios && (
                                <div className="flex justify-center items-center">
                                    <div className="text-xl absolute" style={{ top: "50%", color: "gray" }}>No scenarios found for this product</div>
                                </div>
                            )
                        }
                        <div className="flex flex-wrap justify-center">
                            {scenarios.map((scenario: any) => (
                                <div key={`${scenario.id}${scenario.title}`} className="m-2">
                                    <ScenarioCard data={scenario} isSelectionOn={isSelecting} onSelect={() => handleScenarioSelection(scenario.id)} isSelected={selectedScenarioIds.includes(scenario.id)} />
                                </div>
                            ))}
                        </div>
                        <Modal
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box sx={{ position: 'absolute', top: "50%", left: "50%", transform: 'translate(-50%, -50%)', width: 1000, bgcolor: 'background.paper', border: "none", borderRadius: 5, boxShadow: 24, p: 4, }}>
                                {
                                    fetchingObjections ? <Loader /> : (
                                        <>
                                            <Tabs
                                                value={createScenarioSelectedTab}
                                                onChange={(e, value) => { console.log(e); setCreateScenarioSelectedTab(value); }}
                                                sx={{ marginBottom: "10px" }}
                                            >
                                                <Tab value="auto" label="Auto-Gen" />
                                                <Tab value="custom" label="Custom" />
                                            </Tabs>
                                            {
                                                createScenarioSelectedTab === "auto" ? (
                                                    <div className='flex flex-col items-center'>
                                                        <Typography id="modal-modal-title" variant="h6" component="h2" style={{ marginBottom: "10px" }}>
                                                            Choose scenario types and number of scenarios to create
                                                        </Typography>
                                                        <FormControl component="fieldset">
                                                            <FormGroup>
                                                                <TableContainer component={Paper}>
                                                                    <Table aria-label="simple table">
                                                                        <TableHead>
                                                                            <TableRow >
                                                                                <TableCell style={{ fontWeight: 600, fontSize: "medium" }}>Scenario Type</TableCell>
                                                                                <TableCell style={{ fontWeight: 600, fontSize: "medium" }} align="right">Number of Scenarios for each type</TableCell>
                                                                            </TableRow>
                                                                        </TableHead>
                                                                        {Object.keys(CALL_DESCRIPTION).map((call, index) => (
                                                                            <TableRow key={call}>
                                                                                <TableCell>
                                                                                    <div className="flex pl-3">
                                                                                        <FormControlLabel
                                                                                            key={index}
                                                                                            control={<input type="checkbox" checked={selectedCalls[call] || false} onChange={() => handleCheckboxChange(call)} style={{ fontSize: "18px" }} />}
                                                                                            label={""}
                                                                                        />
                                                                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                                                            <div style={{ fontSize: "18px", marginRight: "5px" }}>{call}</div>
                                                                                            <Tooltip title={CALL_DESCRIPTION[call].Description}>
                                                                                                <InfoIcon />
                                                                                            </Tooltip>
                                                                                        </div>
                                                                                    </div>
                                                                                </TableCell>
                                                                                <TableCell>
                                                                                    <Slider
                                                                                        defaultValue={0}
                                                                                        step={1}
                                                                                        marks
                                                                                        min={0}
                                                                                        max={4}
                                                                                        disabled={!selectedCalls[call]}
                                                                                        value={selectedCallTypes[call] || (!selectedCalls[call] ? 0 : 1)}
                                                                                        onChange={(event, value) => handleCallTypeChange(call, value as number)}
                                                                                        valueLabelDisplay="auto"
                                                                                        aria-label="Always visible"
                                                                                    />
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        ))}
                                                                    </Table>
                                                                </TableContainer>
                                                            </FormGroup>
                                                        </FormControl>
                                                        <div style={{ marginRight: "10px", marginTop: "10px", display: "flex", justifyContent: "center", width: "100%" }}>
                                                            {
                                                                loading ?
                                                                    <CircularProgress />
                                                                    :
                                                                    <Button onClick={handleAutoScenarioSubmission} variant="contained" style={{ backgroundColor: "#45474B" }}>Submit</Button>
                                                            }
                                                        </div>
                                                        {/* {openChild ? <ChildModal open={openChild} handleClose={handleCloseChildModal} /> : null} */}
                                                    </div>
                                                ) : (
                                                    <div style={{ maxHeight: "80vh", overflowY: "scroll" }}>
                                                        <div className="flex justify-between">
                                                            <TextField
                                                                label="Scenario Title"
                                                                variant="outlined"
                                                                required
                                                                value={customScenarioDetails.title}
                                                                onChange={(e) => setCustomScenarioDetails({ ...customScenarioDetails, title: e.target.value })}
                                                                style={{ width: "49%", marginTop: "20px" }}
                                                            />
                                                            <FormControl variant="outlined" style={{ marginTop: "20px", width: "49%" }} fullWidth required> 
                                                                <InputLabel>Select Call Type</InputLabel>
                                                                <Select
                                                                    value={customScenarioDetails.callType}
                                                                    onChange={(e) => setCustomScenarioDetails({ ...customScenarioDetails, callType: e.target.value })}
                                                                    label="Select Call Type"
                                                                    required
                                                                >
                                                                    {Object.keys(CALL_DESCRIPTION).map((call, index) => (
                                                                        <MenuItem key={index} value={call}>{call}</MenuItem>
                                                                    ))}
                                                                </Select>
                                                            </FormControl>
                                                        </div>
                                                        <TextField
                                                            label="Scenario context"
                                                            variant="outlined"
                                                            placeholder='Example: You have just given a pitch to the customer and the customer thinks...'
                                                            required
                                                            value={customScenarioDetails.previous_interaction_context}
                                                            onChange={(e) => setCustomScenarioDetails({ ...customScenarioDetails, previous_interaction_context: e.target.value })}
                                                            style={{ width: "100%", marginTop: "20px" }}
                                                            multiline
                                                            rows={2}
                                                        />
                                                        <TextField
                                                            label="Customer's first statement"
                                                            variant="outlined"
                                                            placeholder='The first statement that the customer speaks when a simulation starts. Avoid keeping this an objection.'
                                                            required
                                                            multiline
                                                            rows={2}
                                                            value={customScenarioDetails.previous_interaction_customer_message}
                                                            onChange={(e) => setCustomScenarioDetails({ ...customScenarioDetails, previous_interaction_customer_message: e.target.value })}
                                                            style={{ width: "100%", marginTop: "20px" }}
                                                        />
                                                        {/* <Typography variant='h6' style={{ marginTop: "20px", width: "100%", display: "flex", justifyContent: "center" }}>Call&#39;s Agenda</Typography> */}
                                                        <div className="flex justify-center relative mt-5 items-center">
                                                            <Typography variant='h6'>Goals (Optional)</Typography>
                                                            <Button 
                                                                variant="contained" style={{ backgroundColor: "#393E46", position: "absolute", right: "0" }} 
                                                                onClick={() => setCustomScenarioDetails({ ...customScenarioDetails, agenda: {...customScenarioDetails.agenda, goals: [...customScenarioDetails.agenda.goals, ""] } })}>
                                                                <AddIcon style={{ color: 'white' }} />
                                                            </Button>
                                                        </div>
                                                        {
                                                            customScenarioDetails.agenda?.goals?.map((goal: string, index: number) => (
                                                                <div className="flex" key={index}>
                                                                    <TextField
                                                                        label={`Goal ${index + 1}`}
                                                                        variant="outlined"
                                                                        value={goal}
                                                                        onChange={(e) => {
                                                                            const goalsCopy = [...customScenarioDetails.agenda.goals];
                                                                            goalsCopy[index] = e.target.value;
                                                                            setCustomScenarioDetails({ ...customScenarioDetails, agenda: { ...customScenarioDetails.agenda, goals: goalsCopy } });
                                                                        }}
                                                                        style={{ width: "100%", marginTop: "10px" }}
                                                                    />
                                                                    <Button
                                                                        sx={{ backgroundColor: "#FF0000", color: "white", marginTop: "10px", marginLeft: "5px" }}
                                                                        onClick={() => {
                                                                            const goalsCopy = [...customScenarioDetails.agenda.goals];
                                                                            goalsCopy.splice(index, 1);
                                                                            setCustomScenarioDetails({ ...customScenarioDetails, agenda: { ...customScenarioDetails.agenda, goals: goalsCopy } });
                                                                        }}
                                                                    >
                                                                        <DeleteOutlineIcon />
                                                                    </Button>
                                                                </div>
                                                            ))
                                                        }
                                                        <div className="flex justify-center relative mt-5 items-center">
                                                            <Typography variant='h6'>Topics to cover (Optional)</Typography>
                                                            <Button 
                                                                variant="contained" style={{ backgroundColor: "#393E46", position: "absolute", right: "0" }} 
                                                                onClick={() => setCustomScenarioDetails({ ...customScenarioDetails, agenda: {...customScenarioDetails.agenda, topics: [...customScenarioDetails.agenda.topics, ""] } })}>
                                                                <AddIcon style={{ color: 'white' }} />
                                                            </Button>
                                                        </div>
                                                        {
                                                            customScenarioDetails.agenda?.topics?.map((topic: string, index: number) => (
                                                                <div className="flex" key={index}>
                                                                    <TextField
                                                                        label={`Topic ${index + 1}`}
                                                                        variant="outlined"
                                                                        value={topic}
                                                                        onChange={(e) => {
                                                                            const topicsCopy = [...customScenarioDetails.agenda.topics];
                                                                            topicsCopy[index] = e.target.value;
                                                                            setCustomScenarioDetails({ ...customScenarioDetails, agenda: { ...customScenarioDetails.agenda, topics: topicsCopy } });
                                                                        }}
                                                                        style={{ width: "100%", marginTop: "10px" }}
                                                                    />
                                                                    <Button
                                                                        sx={{ backgroundColor: "#FF0000", color: "white", marginTop: "10px", marginLeft: "5px" }}
                                                                        onClick={() => {
                                                                            const topicsCopy = [...customScenarioDetails.agenda.topics];
                                                                            topicsCopy.splice(index, 1);
                                                                            setCustomScenarioDetails({ ...customScenarioDetails, agenda: { ...customScenarioDetails.agenda, topics: topicsCopy } });
                                                                        }}
                                                                    >
                                                                        <DeleteOutlineIcon />
                                                                    </Button>
                                                                </div>
                                                            ))
                                                        }


                                                        {/* <Typography variant='h5' style={{ marginTop: "20px", display: "flex", justifyContent: "center" }}>Select Objections</Typography> */}
                                                        <Typography variant='h6' style={{ marginTop: "20px", width: "100%", display: "flex", justifyContent: "center" }}>Choose Explicit Objections</Typography>
                                                        <DataGrid
                                                            rows={explicitObjections}
                                                            columns={objectionColumns}
                                                            initialState={{
                                                                pagination: {
                                                                    paginationModel: { page: 0, pageSize: 5 },
                                                                },
                                                            }}
                                                            style={{ backgroundColor: "white", textAlign: "center" }}
                                                            disableColumnMenu={true}
                                                            components={{ Toolbar: GridToolbar }}
                                                            pageSizeOptions={[5, 10, 20, 50]}
                                                            getRowHeight={() => 'auto'}
                                                            disableColumnFilter
                                                            disableColumnSelector
                                                            checkboxSelection
                                                            disableRowSelectionOnClick
                                                            disableDensitySelector
                                                            slotProps={{
                                                                toolbar: {
                                                                    showQuickFilter: true,
                                                                },
                                                                }}
                                                            onRowSelectionModelChange={handleSelectionExplicit}
                                                        />
                                                        <Typography variant='h6' style={{ marginTop: "20px", width: "100%", display: "flex", justifyContent: "center" }}>Choose Implicit Objections</Typography>
                                                        <DataGrid
                                                            rows={implicitObjections}
                                                            columns={objectionColumns}
                                                            initialState={{
                                                                pagination: {
                                                                    paginationModel: { page: 0, pageSize: 5 },
                                                                },
                                                            }}
                                                            style={{ backgroundColor: "white", textAlign: "center" }}
                                                            disableColumnMenu={true}
                                                            components={{ Toolbar: GridToolbar }}
                                                            pageSizeOptions={[5, 10, 20, 50]}
                                                            getRowHeight={() => 'auto'}
                                                            disableColumnFilter
                                                            disableColumnSelector
                                                            checkboxSelection
                                                            disableRowSelectionOnClick
                                                            disableDensitySelector
                                                            slotProps={{
                                                                toolbar: {
                                                                    showQuickFilter: true,
                                                                },
                                                                }}
                                                            onRowSelectionModelChange={handleSelectionImplicit}
                                                        />
                                                        <Typography variant='h6' style={{ marginTop: "20px", display: "flex", justifyContent: "center" }}>Customer Persona Details</Typography>
                                                        <div className="flex justify-between">
                                                            <TextField
                                                                label="Name"
                                                                variant="outlined"
                                                                required
                                                                value={customScenarioDetails.customer_name}
                                                                onChange={(e) => setCustomScenarioDetails({ ...customScenarioDetails, customer_name: e.target.value })}
                                                                style={{ width: "49%", marginTop: "20px" }}
                                                            />
                                                            <TextField
                                                                label="Job Title"
                                                                variant="outlined"
                                                                required
                                                                value={customScenarioDetails.job_title}
                                                                onChange={(e) => setCustomScenarioDetails({ ...customScenarioDetails, job_title: e.target.value })}
                                                                style={{ width: "49%", marginTop: "20px" }}
                                                            />
                                                        </div>
                                                        <div className="flex justify-between">
                                                            <TextField
                                                                label="Organization name"
                                                                variant="outlined"
                                                                required
                                                                value={customScenarioDetails.organization}
                                                                onChange={(e) => setCustomScenarioDetails({ ...customScenarioDetails, organization: e.target.value })}
                                                                style={{ width: "49%", marginTop: "20px" }}
                                                            />
                                                            <TextField
                                                                label="Organization Industry"
                                                                variant="outlined"
                                                                required
                                                                value={customScenarioDetails.org_industry}
                                                                onChange={(e) => setCustomScenarioDetails({ ...customScenarioDetails, org_industry: e.target.value })}
                                                                style={{ width: "49%", marginTop: "20px" }}
                                                            />
                                                        </div>
                                                        <div className="flex justify-between">
                                                            <TextField
                                                                label="Way of Speaking"
                                                                variant="outlined"
                                                                required
                                                                value={customScenarioDetails.way_of_speaking}
                                                                onChange={(e) => setCustomScenarioDetails({ ...customScenarioDetails, way_of_speaking: e.target.value })}
                                                                style={{ width: "49%", marginTop: "20px" }}
                                                            />
                                                            <TextField
                                                                label="Behavior"
                                                                variant="outlined"
                                                                required
                                                                value={customScenarioDetails.behaviour}
                                                                onChange={(e) => setCustomScenarioDetails({ ...customScenarioDetails, behaviour: e.target.value })}
                                                                style={{ width: "49%", marginTop: "20px" }}
                                                            />
                                                        </div>
                                                        <div className="flex justify-center relative mt-5 items-center">
                                                            <Typography variant='h6'>Customer QnA (Optional)</Typography>
                                                            <Button 
                                                                variant="contained" style={{ backgroundColor: "#393E46", position: "absolute", right: "0" }} 
                                                                onClick={() => setCustomScenarioDetails({ ...customScenarioDetails, qna: [...customScenarioDetails.qna, { question: "", answer: "" }] })}>
                                                                <AddIcon style={{ color: 'white' }} />
                                                            </Button>
                                                        </div>

                                                        {
                                                            customScenarioDetails.qna?.map((qna: any, index: number) => (
                                                                <div key={index} className="flex justify-between items-center mt-3">
                                                                    <TextField
                                                                        label={`Question ${index + 1}`}
                                                                        variant="outlined"
                                                                        value={qna.question}
                                                                        onChange={(e) => {
                                                                            const qnaCopy = [...customScenarioDetails.qna];
                                                                            qnaCopy[index].question = e.target.value;
                                                                            setCustomScenarioDetails({ ...customScenarioDetails, qna: qnaCopy });
                                                                        }}
                                                                        style={{ width: "49%", marginRight: "5px" }}
                                                                    /> :
                                                                    <TextField
                                                                        label={`Answer ${index + 1}`}
                                                                        variant="outlined"
                                                                        value={qna.answer}
                                                                        onChange={(e) => {
                                                                            const qnaCopy = [...customScenarioDetails.qna];
                                                                            qnaCopy[index].answer = e.target.value;
                                                                            setCustomScenarioDetails({ ...customScenarioDetails, qna: qnaCopy });
                                                                        }}
                                                                        style={{ width: "49%", marginLeft: "5px" }}
                                                                    />
                                                                    <Button
                                                                        sx={{ backgroundColor: "#FF0000", color: "white", marginLeft: "5px", height: "100%" }}
                                                                        onClick={() => {
                                                                            const qnaCopy = [...customScenarioDetails.qna];
                                                                            qnaCopy.splice(index, 1);
                                                                            setCustomScenarioDetails({ ...customScenarioDetails, qna: qnaCopy });
                                                                        }}
                                                                    >
                                                                        <DeleteOutlineIcon />
                                                                    </Button>
                                                                </div>
                                                            ))
                                                        }

                                                        <div style={{ marginRight: "10px", marginTop: "20px", display: "flex", justifyContent: "center", width: "100%" }}>
                                                            {
                                                                loading ?
                                                                    <CircularProgress />
                                                                    :
                                                                    <Button onClick={handleCustomScenarioSubmission} variant="contained" style={{ backgroundColor: "#45474B" }}>Submit</Button>
                                                            }
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        </>
                                    )
                                }
                            </Box>
                        </Modal>
                    </>
                )
            }
        </div>
    );
};

export default ProductScenario;
