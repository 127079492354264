import { useEffect, useState } from "react";
import useWebSocket from "react-use-websocket";
// import { Reference } from "../../Chat";
import { BACKEND_WS_URL } from "../constants";
// import { getValidAccessToken } from "../../../lib/auth"
// import { handleLogout } from "../../../lib/login";
// import { v4 as uuidv4 } from "uuid";
import { useDispatch } from "react-redux";
import {ObjectionTypeComponentProps} from "../components/objection/ObjectionTypeComponent";
import { addSimulationMessage } from "../redux/currentSimulationSlice";

const useLlmWebsocket = () => {
  const [ isWebsocketConnected, setIsWebsocketConnected ] = useState<boolean>(false);
  const [ evaluationDetails, setEvaluationDetails ] = useState<ObjectionTypeComponentProps[] | null>(null)
  const [isAwaitingCustomerReply, setIsAwaitingCustomerReply] = useState<boolean>(true)
  const [ customerReply, setCustomerReply ] = useState<string>("")
  const [mediaQueue, setMediaQueue] = useState<string[]>([]); 

  const dispatch = useDispatch();
  
  
  const { sendMessage, lastMessage } = useWebSocket(
    `${BACKEND_WS_URL}?google_access_token=${localStorage.getItem('google_access_token')}`,
    {
      // Ensure that the ws connection isn't closed due to API gateway ws conn timeout (10 mins).
      // TODO: Switch to the optimal approach mentioned here: https://github.com/trilogy-group/transformers-ediscovery-kb/issues/65
      shouldReconnect: (closeEvent) => {
        console.log("Received closeEvent: ", closeEvent)
        console.log("Reconnecting...")
        return true;
      },
      async onOpen() {
        console.log("Connected to Backend websocket.")
        sendMessage(JSON.stringify({request_type: "WARMER_PING"}))
      }
    }
  );
  useEffect(() => {
    if (lastMessage === null) return;
    const data = JSON.parse(lastMessage.data);
    if(data.data?.request_type === "SIMULATION"){
      if(data.data.chunk === "END"){
        setIsAwaitingCustomerReply(false) 
        dispatch(addSimulationMessage({role: "backend", content: customerReply, "loading": false}))
        setCustomerReply("")
      }
      else if (data.data.chunk !== "START") {
        if (data.data.text){
          console.log("dispatching text", customerReply, "|", data.data.text)
          dispatch(addSimulationMessage({role: "backend", content: customerReply + " " + data.data.text, "loading": true}))
          setCustomerReply((prevReply) => prevReply + " " + data.data.text);
        }
        else setMediaQueue((prevQueue) => [...prevQueue, data.data?.chunk]);
      }
    }
    else if (data.data?.request_type === "EVALUATION"){
      console.log("Evaluated objections are: ", data.data?.evaluated_objections)
      setEvaluationDetails(data.data?.evaluated_objections)
    }
    else if (data.data?.request_type === "WARMER_PING"){
      console.log("Received warmer ping.")
      setIsWebsocketConnected(true)
    }
  }, [lastMessage]);

  const handleMessageSend = (request_type: string, request_data: Record<string, any>) => {
    if(request_type === "SIMULATION"){
      setIsAwaitingCustomerReply(true)
      setMediaQueue([])
    }
    const query = {
      request_type,
      ...request_data
    }
    const queryMessage = query;
    sendMessage(JSON.stringify(queryMessage));
  };

  return {
    mediaQueue,
    evaluationDetails,
    isWebsocketConnected,
    isAwaitingCustomerReply,
    handleMessageSend
  };
};

export default useLlmWebsocket;
