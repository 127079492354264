import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { REACT_APP_BACKEND_URL, allowedFileTypes } from '../constants';
import MultiActionAreaCard from '../components/MultiActionAreaCard';
import { Button, Modal, Box, TextField, CircularProgress } from '@mui/material';
import DynamicTextBoxes from '../components/DynamicTextBoxes'; // Import the DynamicTextBoxes component
import { toast } from 'react-toastify';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import { setNavbarProducts, addNavbarProduct, setAllProducts } from '../redux/productSlice';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    p: 5,
    borderRadius: 5,
    outline: null,
    maxHeight: '90vh',
    overflowY: 'auto'
};

const Products = () => {
    const [loading, setLoading] = useState<boolean>(true);
    const [showOverlay, setShowOverlay] = useState<boolean>(false);
    const [productName, setProductName] = useState<string>('');
    const [aliases, setAliases] = useState<string[]>(['']); // Object to hold multiple alias values
    const [productCreateLoading, setProductCreateLoading] = useState<boolean>(false);
    const [showUploadModal, setShowUploadModal] = useState<boolean>(false); // New state variable for upload modal
    const [featureValues, setFeatureValues] = useState<string[]>(['']); // State for feature values
    const [pricingValues, setPricingValues] = useState<string[]>(['']); // State for pricing values
    const [descriptionValues, setDescriptionValues] = useState<string[]>(['']);
    const [productFiles, setProductFiles] = useState<File[]>([]);
    const inputFileRef = useRef<HTMLInputElement>(null);
    const products = useSelector((state: any) => state.products?.navbarProducts);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const fetchProducts = async () => {
        try {
            console.log("fetching products")
            const response = await axios.get(`${REACT_APP_BACKEND_URL}/organization/${localStorage.getItem('org_id')}/product`,{
                headers: { "Authorization": `Bearer ${localStorage.getItem('google_access_token')}` }
            });
            console.log("response products are", response.data.products)
            dispatch(setNavbarProducts(response.data.products));
            const products_data: Record<string, any> = {}
            response.data.products.forEach((product: any) => {
                products_data[product.id] = product
            })
            dispatch(setAllProducts(products_data));
            if (response.data.products.length === 0) {
                setShowOverlay(true);
            }
        } catch (error) {
            console.error('Error fetching products:', error);
        }
        setLoading(false);
    };
    useEffect(() => {
        fetchProducts();
    }, []);
    const handleCreateProduct = async () => {
        try {
            setProductCreateLoading(true);
            // Remove empty aliases
            const aliasesWithoutEmpty = aliases.filter(alias => alias.trim() !== '');
            const response = await axios.post(`${REACT_APP_BACKEND_URL}/organization/${localStorage.getItem('org_id')}/product`, {
                name: productName,
                aliases: aliasesWithoutEmpty
            }, { headers: { "Authorization": `Bearer ${localStorage.getItem('google_access_token')}` } });
            setShowOverlay(false);
            toast.success("Product created successfully");
            dispatch(addNavbarProduct(response.data));
            navigate(`/manager/product/${response.data.id}`);
        } catch (error) {
            console.error('Error adding product:', error);
            toast.error("Error adding product");
        }
        setProductCreateLoading(false);
    };
    const getFileType = (file: File): string => {
        const extension = file.name.split('.').pop()?.toLowerCase() || '';
        return '.' + extension;
    };
    const getPresignedUrls = async (productID: string) => {
        try {
            const urls: any[] = [];
            for (const file of productFiles) {
                const response = await axios.get(REACT_APP_BACKEND_URL + '/ingestion/presigned_url/product_docs', {
                    params: {
                        file_type: getFileType(file),
                        orgID: localStorage.getItem('org_id'),
                        productID: productID,
                        file_name: file.name
                    },
                    headers: { 'Authorization': `Bearer ${localStorage.getItem('google_access_token')}` }
                });
                urls.push(response.data);
            }
            return urls;
        } catch (error) {
            console.error('Error getting presigned URLs:', error);
            toast.error('Error getting presigned URLs. Please try again.');
            // setOpen(true);
        }
    };
    const uploadFiles = async (presignedUrls: any) => {
        try {
            const uploadPromises = presignedUrls.map((url: any, index: any) => {
                const formData = new FormData();
                Object.entries(url.fields).forEach(([key, value]: any) => {
                    formData.append(key, value);
                });
                formData.append('file', productFiles[index]);
                return axios.post(url.url, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
            });
            await Promise.all(uploadPromises);
        }
        catch (error) {
            console.error('Error uploading files:', error);
            toast.error('Error uploading files. Please try again.');
        }
        finally {
            setProductFiles([]);
            if (inputFileRef.current) {
                inputFileRef.current.value = ''; // Clear the value
            }
        }
    }
    const handleUploadSubmit = async () => {
        try {
            setProductCreateLoading(true);
            const aliasesWithoutEmpty = aliases.filter(alias => alias.trim() !== '');
            const productCreateResponse = await axios.post(`${REACT_APP_BACKEND_URL}/organization/${localStorage.getItem('org_id')}/product`, {
                name: productName,
                aliases: aliasesWithoutEmpty
            }, { headers: { "Authorization": `Bearer ${localStorage.getItem('google_access_token')}` } });
            const productID = productCreateResponse.data.id;

            const data = []
            if (featureValues.filter(value => value.trim() !== '').length > 0) {
                data.push({
                    title: "Features",
                    urls: featureValues.filter(value => value.trim() !== '')
                })
            }
            if (pricingValues.filter(value => value.trim() !== '').length > 0) {
                data.push({
                    title: "Pricing",
                    urls: pricingValues.filter(value => value.trim() !== '')
                })
            }
            if (descriptionValues.filter(value => value.trim() !== '').length > 0) {
                data.push({
                    title: "Description",
                    urls: descriptionValues.filter(value => value.trim() !== '')
                })
            }
            if (productFiles.length > 0) {
                const presignedUrls = await getPresignedUrls(productID);
                await uploadFiles(presignedUrls);
            }
            // console.log(data);
            // Send data to backend
            await axios.post(`${REACT_APP_BACKEND_URL}/organization/${localStorage.getItem('org_id')}/product/${productID}/onboard`, {
                product_info_urls: data
            }, { headers: { "Authorization": `Bearer ${localStorage.getItem('google_access_token')}` } });
            // Reset values
            setProductName('');
            setFeatureValues(['']);
            setPricingValues(['']);
            setDescriptionValues(['']);
            setShowUploadModal(false);
            setProductCreateLoading(false);
            setShowOverlay(false);
            fetchProducts();
            navigate(`/manager/product/${productID}`);
            toast.info("It takes a few minutes to extract the product information. You will receive an email once the product details are updated")
        } catch (error) {
            console.error('Error uploading data:', error);
        }
    };
    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const fileList = event.target.files;
        const maxFiles = 5;
        if (fileList && fileList.length > maxFiles) {
            toast.error(`You can only upload ${maxFiles} files at a time.`);
            // setOpen(true);
            if (inputFileRef.current) {
                inputFileRef.current.value = ''; // Clear the value
            }
            return;
        }
        if (fileList) {
            setProductFiles(Array.from(fileList));
        }
    };
    const handleInputChange = (e: any, index: number) => {
        const updatedAliases: any = [...aliases];
        updatedAliases[index] = e.target.value;
        setAliases(updatedAliases);
    };
    const addListItem = () => {
        const updatedAliases = [...aliases, ''];
        setAliases(updatedAliases);
    };
    const removeListItem = (index: number) => {
        const updatedAliases = [...aliases];
        updatedAliases.splice(index, 1);
        setAliases(updatedAliases);
    };

    return (
        <div className="p-6">
            {
                loading ? (
                    <div style={{ minHeight: "90vh" }} className="flex justify-center items-center">
                        <CircularProgress />
                    </div>
                ) : (
                    <div>
                        <div className="flex">
                            <div className="w-full flex justify-center mb-3">
                                <Typography variant='h3'>Select a Product</Typography>
                            </div>
                            {
                                productCreateLoading ? <CircularProgress /> : (
                                    <Button
                                        onClick={() => setShowOverlay(true)}
                                        style={{
                                            position: "absolute",
                                            right: "2%",
                                            backgroundColor: '#45474B',
                                            borderRadius: "100px",
                                            color: 'white',
                                            width: "fit-content",
                                            padding: "10px 20px 10px 20px"
                                        }}
                                    >
                                        <AddIcon />&nbsp;
                                        Add new Product
                                    </Button>
                                )
                            }
                        </div>
                        <Modal
                            open={showOverlay}
                            onClose={() => {
                                if (products.length !== 0) {
                                    setShowOverlay(false)
                                }
                            }}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box sx={style}>
                                <div style={{position: "relative", width: "100%"}}>
                                    <CloseIcon style={{position: "absolute", right: "0", cursor: "pointer"}} onClick={() => setShowOverlay(false)}/>
                                </div>
                                <div className="flex flex-col">
                                    {
                                        products.length === 0 ? (
                                            <h2 className="flex justify-center text-center mb-3">Create your first product to get started! </h2>
                                        ) : (
                                            <h2 className="flex justify-center text-center mb-3">Create a new product</h2>
                                        )
                                    }
                                    <div style={{ display: "flex", flexDirection: "row", marginTop: "10px", paddingBottom: "10px" }}>
                                        <Typography variant="h5">Product Name </Typography><Typography variant="h5" style={{color: "red"}}>&nbsp;*</Typography>
                                    </div>
                                    <TextField
                                        variant="outlined"
                                        value={productName}
                                        onChange={(e) => setProductName(e.target.value)}
                                    />
                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: "20px" }}>
                                        <Typography variant="h5">Product Aliases</Typography>
                                        <IconButton onClick={() => { addListItem() }} style={{ color: "#393E46" }}>
                                            <AddIcon />
                                        </IconButton>
                                    </div>
                                    {aliases.map((alias: string, index: number) => (
                                        <div key={index} className="flex items-center mt-2">
                                            <TextField
                                                label={`Alias ${index + 1}`}
                                                value={alias}
                                                onChange={(e) => handleInputChange(e, index)}
                                                fullWidth
                                            />
                                            <IconButton onClick={() => { removeListItem(index) }} style={{ color: "red" }}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </div>
                                    ))}
                                    <div className="flex w-full justify-center mt-3">
                                        {
                                            productCreateLoading ? <CircularProgress /> :
                                                <div className="flex flex-col w-full text-l mt-3">
                                                    <div>
                                                        Do you want to add other product information?
                                                    </div>
                                                    <div className="flex justify-between mt-2">
                                                        <Button
                                                            onClick={() => handleCreateProduct()}
                                                            variant="outlined"
                                                            style={{
                                                                // backgroundColor: productName ? 'green' : 'gray',
                                                                // color: 'white',
                                                                width: "fit-content",
                                                                padding: "10px 20px 10px 20px",
                                                            }}
                                                            disabled={productName === ''}
                                                        >
                                                            No, I&#39;ll add it later
                                                        </Button>
                                                        <Button
                                                            onClick={() => setShowUploadModal(true)}
                                                            style={{
                                                                backgroundColor: productName ? '#393E46' : 'gray',
                                                                color: 'white',
                                                                width: "fit-content",
                                                                padding: "10px 20px 10px 20px",
                                                            }}
                                                            disabled={productName === ''}
                                                        >
                                                            Yes
                                                        </Button>
                                                    </div>
                                                </div>
                                        }
                                    </div>
                                </div>
                            </Box>
                        </Modal>
                        <Modal
                            open={showUploadModal}
                            onClose={() => {
                                if (products.length !== 0) {
                                    setProductName('');
                                    setShowUploadModal(false);
                                    setShowOverlay(false);
                                }
                            }}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box sx={style}>
                                <div className="flex justify-center items-center">
                                    <ArrowBackIcon
                                        style={{ position: "absolute", left: "3%", cursor: "pointer", color: "gray", fontSize: "30px" }}
                                        onClick={() => setShowUploadModal(false)}
                                    />
                                    <Typography variant='h4' sx={{ "marginLeft": "10px" }}>
                                        Product Information (Optional)
                                    </Typography>
                                </div>
                                <div className="flex flex-col">

                                    <div>
                                        <DynamicTextBoxes
                                            title="Description"
                                            values={descriptionValues}
                                            setValues={setDescriptionValues}
                                        />
                                        <DynamicTextBoxes
                                            title="Features"
                                            values={featureValues}
                                            setValues={setFeatureValues}
                                        />
                                        <DynamicTextBoxes
                                            title="Pricing"
                                            values={pricingValues}
                                            setValues={setPricingValues}
                                        />
                                    </div>
                                    <input
                                        ref={inputFileRef}
                                        type="file"
                                        accept={allowedFileTypes.join(',')}
                                        onChange={handleFileChange}
                                        multiple
                                        style={{ padding: "10px", margin: "10px" }}
                                        disabled={productCreateLoading}
                                    />
                                    {productCreateLoading ? (
                                        <div className="w-full flex justify-center">
                                            <CircularProgress />
                                        </div>
                                    ) : (
                                        <>
                                            <Button
                                                onClick={handleUploadSubmit}
                                                style={{
                                                    backgroundColor: '#393E46',
                                                    color: 'white',
                                                    padding: "10px 20px 10px 20px",
                                                    marginTop: "20px"
                                                }}
                                            >
                                                Create
                                            </Button>
                                            <div className="text-center mt-2"> <strong>Note:&nbsp;</strong>You will receive an email when the product gets onboarded if your email is verified. Contact the CallCoach developer team to verify your email.</div>
                                        </>
                                    )}
                                </div>
                            </Box>
                        </Modal>
                        <div className="flex flex-wrap justify-center">
                            {products.map((product: any) => (
                                <MultiActionAreaCard
                                    key={product.id}
                                    imageUrl={"https://www.salesforce.com/blog/wp-content/uploads/sites/2/2023/10/Sales_Quotas.jpg?w=889&h=500"}
                                    imageAltText={product.name}
                                    cardTitle={product.name}
                                    cardText={product.description}
                                    navigateTo={`/manager/product/${product.id}`}
                                />
                            ))}
                        </div>
                    </div>
                )
            }
        </div>
    );
};

export default Products;
