import React, { useEffect, useState, useRef } from 'react';
import CanvasJSReact from '@canvasjs/react-charts';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { toast } from 'react-toastify';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import { REACT_APP_BACKEND_URL } from '../../constants';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Button, IconButton, TextField, Tooltip, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DoneIcon from '@mui/icons-material/Done';
import { DataGrid, GridColDef, GridFilterModel, GridRenderCellParams, GridToolbar } from '@mui/x-data-grid';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import VideoPlayer from '../VideoPlayer'
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteWithConfirmation from '../DeleteWithConfirmation';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "95vw",
    bgcolor: 'background.paper',
    p: 4,
    borderRadius: 5,
    outline: null,
    overflowY: 'auto',
    maxHeight: "90vh"
};


const CanvasJSChart = CanvasJSReact.CanvasJSChart;
export const coreOptions: Record<string, any> = {
    backgroundColor: "transparent",
    toolTip: {
        fontSize: 16
    },
    title: {
        text: "Objection Types",
        fontFamily: "sans-serif",
        fontSize: 36,
        margin: 20
    },
    data: [{
        type: "pie",
        cursor: "pointer",
        explodeOnClick: false,
        indexLabelFontSize: 16,
        startAngle: 90,
    }]
}
interface ObjectionChartProps {
    options: any;
    productID: string;
    filterUsername: string | null;
    rootObjections: any;
    nextLevelObjections: any;
    fetchAggregatedObjections: () => void;
}
function truncateString(str: string, num: number) {
    if (str?.length <= num) {
        return str
    }
    return str.slice(0, num) + '...'
}

function getStringWithLineBreaks(str: string, num: number) {
    let result = '';
    let insertBreak = false;
    for (let i = 0; i < str?.length; i++) {
        if (insertBreak && str[i] === ' ') {
            result += '<br />';
            insertBreak = false;
        }
        else result += str?.[i];
        if (i % num === 0 && i !== 0) {
            insertBreak = true;
        }
    }
    return result;
}

function timestampToSeconds(timestamp: string) {
    console.log("start time stamp", timestamp)
    const [hours, minutes, seconds] = timestamp.split(':').map(Number);
    const totalSeconds = hours * 3600 + minutes * 60 + parseFloat(seconds.toString());
    console.log("START TIME", totalSeconds)
    return Math.floor(totalSeconds);
}

const ObjectionChart: React.FC<ObjectionChartProps> = ({ options, productID, filterUsername, rootObjections, nextLevelObjections, fetchAggregatedObjections }) => {
    const [currentOptions, setCurrentOptions] = useState<Record<string, any>>({})
    const [currentAggregatedObjection, setCurrentAggregatedObjection] = useState<string>('')
    const [currentObjectionIdealAns, setCurrentObjectionIdealAns] = useState<string>('')
    const [updatedObjectionIdealAns, setUpdatedObjectionIdealAns] = useState<string>('')
    const [depth, setDepth] = useState<number>(0)
    const [callRecordingModalIndex, setCallRecordingModalIndex] = useState<number | null>(null);
    const [callDocType, setCallDocType] = useState('');
    const [callRecordingContent, setCallRecordingContent] = useState('');
    const [callRecordingObjectionTimestamp, setCallRecordingObjectionTimestamp] = useState('');
    const [childrenObjections, setChildrenObjections] = useState<any[]>([]);
    const [openChildrenModal, setOpenChildrenModal] = useState(false);
    const [loadingChildren, setLoadingChildren] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const optionsRef = useRef(options)
    const [editIdealAnswer, setEditIdealAnswer] = useState(false)
    const [currentObjectionId, setCurrentObjectionId] = useState<string>('')
    const [l1ObjectionType, setL1ObjectionType] = useState<string>('');
    const [filterModalL0, setFilterModalL0] = useState<GridFilterModel>({
        items: [
            { field: 'objection_type', operator: 'contains', value: '' },
        ],
    });
    const [filterModalL1, setFilterModalL1] = useState<GridFilterModel>({
        items: [
            { field: 'description', operator: 'contains', value: '' },
        ],
    });

    const handleBack = () => {
        setCurrentOptions(optionsRef.current.previousOptions)
        optionsRef.current = optionsRef.current.previousOptions
        setDepth(prevDepth => prevDepth - 1)
    }
    const fetchObjectionChildren = async (objectionId: string) => {
        try {
            // console.log("fetching children for objection", objectionId)
            setLoadingChildren(true);
            setOpenChildrenModal(true);
            const response = await axios.get(`${REACT_APP_BACKEND_URL}/organization/${localStorage.getItem('org_id')}/product/${productID}/objection/${objectionId}/children`, {
                headers: { 'Authorization': `Bearer ${localStorage.getItem('google_access_token')}` }
            });
            // console.log('Response:', response);
            //Sort the children by score score: "Bad" | "Average" | "Good"; First should be all good, then average, then bad
            response.data.children.sort((a: any, b: any) => {
                if (a.score === "Good") return -1;
                if (a.score === "Average" && b.score === "Bad") return -1;
                if (a.score === "Average" && b.score === "Good") return 1;
                if (a.score === "Bad") return 1;
                return 0;
            });
            // setCurrentObjectionIdealAns('') // TODO
            setChildrenObjections(response.data.children);
            setLoadingChildren(false);
        } catch (error) {
            console.error('Error fetching aggregated objections:', error);
            toast.error('Something went wrong while fetching objections')
        }
    }
    const openChildrenModalTrigger = (description: string, objection_id: string, ideal_answer: string) => {
        setCurrentAggregatedObjection(description);
        fetchObjectionChildren(objection_id);
        setCurrentObjectionIdealAns(ideal_answer ? ideal_answer : "");
        setCurrentObjectionId(objection_id);
        setUpdatedObjectionIdealAns(ideal_answer ? ideal_answer : "")
    }
    const chartDrilldownHandlerD0 = (e: any) => {
        if (optionsRef.current.depth === 0) {
            setL1ObjectionType(e.dataPoint.label);
            const updatedOptions = {
                ...optionsRef.current,
                title: { ...optionsRef.current.title, text: e.dataPoint.label },
                data: [{
                    ...optionsRef.current.data[0],
                    dataPoints: optionsRef.current?.originalData[e.dataPoint.label]?.map((value: any) => (
                        {
                            y: value.children?.length === 0 ? (value.is_custom ? 1 : 0) : value.children?.length,
                            label: truncateString(value.description, 60),
                            description: getStringWithLineBreaks(value.description, 150),
                            objection: getStringWithLineBreaks(value.objection, 150),
                            click: () => { openChildrenModalTrigger(value.description, value.id, value.ideal_answer) },
                            toolTipContent: "<div style=\"display: flex; flex-direction: column; max-width: 50px;\"><div style=\"display: flex;\"><strong> Aggregated Objection:&nbsp;</strong><span> {description}</span></div><div style=\"display: flex;\"><br /><strong>Number of Raw Objections:&nbsp;</strong><span> {y}</span></div></div>"
                        }
                    ))
                }],
                previousOptions: optionsRef.current,
                depth: 1
            }
            setCurrentOptions(updatedOptions)
            optionsRef.current = updatedOptions
            setDepth(prevDepth => prevDepth + 1)
        }

    }

    useEffect(() => {
        const updatedOptions = {
            ...options,
            data: options?.data?.map((data: any) => {
                return {
                    ...data,
                    click: chartDrilldownHandlerD0
                }
            }),
            depth: 0
        }
        const currentAvailableUsernames = new Set<string>();
        if (updatedOptions?.data?.[0]?.dataPoints?.length > 0) {
            for (const dataPoint of updatedOptions.data[0].dataPoints) {
                for (const objection of dataPoint.objections) {
                    for (const child of objection.children) {
                        currentAvailableUsernames.add(child)
                    }
                }
            }
        }
        setCurrentOptions(updatedOptions)
        optionsRef.current = updatedOptions
    }, [options])

    useEffect(() => {
        setDepth(0)
    }, [filterUsername])

    const updateIdealAnswer = async (objectionId: string, idealAnswer: string) => {
        try {
            if (idealAnswer === currentObjectionIdealAns) return;
            axios.patch(`${REACT_APP_BACKEND_URL}/organization/${localStorage.getItem('org_id')}/product/${productID}/objection/${objectionId}/ideal_answer`, {
                ideal_answer: idealAnswer
            }, {
                headers: { 'Authorization': `Bearer ${localStorage.getItem('google_access_token')}` }
            });
            toast.info('Ideal answer and corresponding objection scores will be updated shortly.')
        } catch (error) {
            console.error('Error updating ideal answer:', error);
            toast.error('Something went wrong while updating ideal answer')
        } finally {
            setEditIdealAnswer(false);
        }
    }

    const openCallRecordingModalTrigger = (objection_index: number, call_recording_id: string, doc_type: string) => {
        setCallRecordingModalIndex(objection_index);
        setCallDocType(doc_type)
        axios.post(REACT_APP_BACKEND_URL + `/organization/${localStorage.getItem('org_id')}/generate_presigned_call_url`,
            {
                call_recording_id: call_recording_id,
                doc_type: doc_type
            },
            {
                headers: {
                    "Authorization": `Bearer ${localStorage.getItem('google_access_token')}`
                }
            }
        ).then((response) => {
            if (!response.data.url) {
                toast.error('Error getting call recording presigned URL. Please try again later.');
                setCallRecordingModalIndex(null);
                setCallDocType('');
                return;
            }
            if (doc_type === "transcript") {
                axios.get(response.data.url).then((res) => {
                    setCallRecordingContent(res.data);
                    console.log("transcript is", res.data)                    
                }).catch((error) => {
                    console.error('Error getting call recording transcript:', error);
                    toast.error('Error getting call recording transcript. Please try again later.');
                    setCallRecordingModalIndex(null);
                    setCallDocType('');
                })
            }
            else{
                setCallRecordingContent(response.data.url);
            }
        }).catch((error) => {
            console.error('Error getting call recording presigned URL:', error);
            toast.error('Error getting call recording presigned URL. Please try again later.');
            setCallRecordingModalIndex(null);
            setCallDocType('');
        })
    }

    const columns: GridColDef[] = [
        {
            field: 'objection_type', flex: 1,
            renderHeader: () => (
                <strong>Objection Type</strong>
            ),
        },
        {
            field: 'total_objections', flex: 1,
            renderHeader: () => (
                <div>
                    <strong>Total Objections</strong>
                    <Tooltip style={{ marginLeft: "10px", fontSize: "20px" }} title={<span style={{ fontSize: "16px" }}>Count of all the objections raised in all sales calls combined</span>}>
                        <HelpOutlineIcon />
                    </Tooltip>
                </div>
            ),
        },
        {
            field: 'distinct_objections', flex: 1,
            renderHeader: () => (
                <div>
                    <strong>Distinct Objections</strong>
                    <Tooltip style={{ marginLeft: "10px", fontSize: "20px" }} title={<span style={{ fontSize: "16px" }}>Of all the objections which were found in sales calls, this is the count which were unique in nature</span>}>
                        <HelpOutlineIcon />
                    </Tooltip>
                </div>
            ),
        },
        {
            field: 'details',
            flex: 0.3,
            sortable: false,
            renderCell: (params: GridRenderCellParams) => (
                <>
                    <IconButton onClick={() => {
                        chartDrilldownHandlerD0({ dataPoint: { label: params.row.objection_type } })
                    }}>
                        <ChevronRightIcon />
                    </IconButton>
                </>
            ),
            renderHeader: () => (
                <strong>Details</strong>
            ),
        },
    ];
    const moduleColumns: GridColDef[] = [
        {
            field: 'description', flex: 1.4, renderHeader: () => (
                <div>
                    <strong>Description</strong>
                    <Tooltip style={{ marginLeft: "10px", fontSize: "20px" }} title={<span style={{ fontSize: "16px" }}>Summary of the objection raised by the customer during sales call</span>}>
                        <HelpOutlineIcon />
                    </Tooltip>
                </div>
            ),
        },
        {
            field: 'ideal_answer', flex: 1.1, renderHeader: () => (
                <div>
                    <strong>Ideal Answer</strong>
                    <Tooltip style={{ marginLeft: "10px", fontSize: "20px" }} title={<span style={{ fontSize: "16px" }}>This shows the ideal way to handle the objection, you can edit this by clicking on {'View Details'}. This will help improve the evalaution of your sales rep.</span>}>
                        <HelpOutlineIcon />
                    </Tooltip>
                </div>
            ),
        },
        {
            field: 'total_objections',
            headerName: 'Total Objections',
            flex: 0.5,
            renderHeader: () => (
                <strong>Total Objections</strong>
            ),
            renderCell: (params: GridRenderCellParams) => (
                <>{params.row.children.length}</>
            ),
        },
        {
            field: 'details',
            headerName: 'Details',
            flex: 0.4,
            sortable: false,
            renderCell: (params: GridRenderCellParams) => (
                <Button size='small' onClick={() => { openChildrenModalTrigger(params.row.description, params.row.id, params.row.ideal_answer) }}>View Details</Button>
            ),
            renderHeader: () => (
                <strong>Details</strong>
            ), 
        },

    ];

    const handleDeleteObjectionGroup = async () => {
        setDeleteLoading(true)
        try{
             await axios.delete(`${REACT_APP_BACKEND_URL}/organization/${localStorage.getItem('org_id')}/product/${productID}/objection/${currentObjectionId}`, 
            {
                headers: { 'Authorization': `Bearer ${localStorage.getItem('google_access_token')}` }
            })
            toast.success("Objection group deleted successfully!")
            setOpenChildrenModal(false)
            fetchAggregatedObjections()
        }
        catch(error){
            console.log(error)
            toast.error("Something went wrong while deleting the objection group. Please try again later")
        }
        setDeleteLoading(false)
    }
    
    useEffect(() => {
        setDepth(0);
    }, [rootObjections, nextLevelObjections, productID, filterUsername, options]);
    
    return (
        <div >
            <div style={{ display: "flex", "flexDirection": "column", width: "100%", justifyContent: "center", marginBottom: "10px" }}>
                <div style={{ width: "100%" }}>
                    <CanvasJSChart options={currentOptions} />
                    {
                        depth > 0 && (
                            <Button
                                style={{
                                    borderRadius: '4px',
                                    padding: '8px',
                                    border: 'none',
                                    fontSize: '16px',
                                    color: 'white',
                                    position: 'absolute',
                                    top: '20px',
                                    left: '20px',
                                    cursor: 'pointer',
                                    backgroundColor: '#393E46'
                                }}
                                variant="contained"
                                onClick={() => handleBack()}
                            >
                                &lt; Back
                            </Button>
                        )
                    }

                </div>
                <div style={{ width: '100%', marginTop: "10px" }}>
                    <DataGrid
                        rows={depth == 0 ? (rootObjections) : nextLevelObjections[l1ObjectionType]}
                        columns={depth == 0 ? columns : moduleColumns}
                        rowSelection={false}
                        initialState={{
                            pagination: {
                                paginationModel: { page: 0, pageSize: 5 },
                            },
                        }}
                        style={{ backgroundColor: "white", textAlign: "center" }}
                        disableColumnMenu={true}
                        filterModel={depth == 0 ? filterModalL0 : filterModalL1}
                        onFilterModelChange={(model) => { depth == 0 ? setFilterModalL0(model) : setFilterModalL1(model) }}
                        components={{ Toolbar: GridToolbar }}
                        slotProps={{
                            toolbar: {
                              showQuickFilter: true,
                            },
                          }}
                        disableDensitySelector
                        disableColumnSelector
                        disableColumnFilter
                        pageSizeOptions={[5, 10, 20, 50]}
                        getRowHeight={() => 'auto'}
                    />
                </div>
            </div>
            <Modal
                open={openChildrenModal}
                onClose={() => { setOpenChildrenModal(false); setEditIdealAnswer(false); }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{ position: 'absolute', top: "50%", left: "50%", transform: 'translate(-50%, -50%)', display: "flex", justifyContent: "center", alignItems: "center", borderRadius: 5, outline: null, width: "95%", bgcolor: 'background.paper', boxShadow: 24, p: 4, padding: "20px", overflowY: "auto" }}>
                    {loadingChildren ? (<CircularProgress />) : (
                        <div style={{ maxHeight: "80vh", maxWidth:"100%" }}>
                            <div className="flex mb-3">
                                <div className="w-1/2 flex flex-col items-center text-center" style={{ paddingRight: "10px", borderRight: "2px solid #F6F6F6" }}>
                                    <div className="flex items-center">
                                        <Typography variant='h6' style={{ fontWeight: "600" }}>Aggregated Objection</Typography>
                                        <IconButton style={{ marginLeft: "20px", color: "transparent" }} disabled>
                                            <EditIcon />
                                        </IconButton>

                                    </div>
                                    {currentAggregatedObjection}
                                </div>
                                <div className="w-1/2 flex flex-col items-center text-center relative">

                                    <div className="flex items-center">
                                        <Typography variant='h6' style={{ fontWeight: "600" }}>Ideal Answer</Typography>
                                        <IconButton style={{ marginLeft: "20px" }} onClick={() => { setEditIdealAnswer(true) }}>
                                            <EditIcon />
                                        </IconButton>

                                    </div>
                                    <div className='flex flex-row' style={{ width: "100%" }}>
                                        <div style={{ width: editIdealAnswer ? "80%" : "95%", marginRight: "10px", marginLeft: "10px" }}>
                                            <TextField disabled={!editIdealAnswer}
                                                variant='standard'
                                                defaultValue={currentObjectionIdealAns}
                                                size='small'
                                                multiline
                                                maxRows={3}
                                                fullWidth
                                                onChange={(e) => setUpdatedObjectionIdealAns(e.target.value)}
                                            ></TextField>
                                        </div>

                                        {editIdealAnswer && <div style={{ width: "10%" }}>
                                            <IconButton onClick={() => { updateIdealAnswer(currentObjectionId, updatedObjectionIdealAns) }}>
                                                <DoneIcon />
                                            </IconButton>
                                        </div>}
                                    </div>
                                </div>
                                {
                                    deleteLoading ? (
                                        <CircularProgress />
                                    ) : (
                                        <DeleteWithConfirmation 
                                            subject={"Delete Objection Group"}
                                            description={"Are you sure you want to delete this objection group? This action will delete all objections inside this group."}
                                            handleDelete={()=> {handleDeleteObjectionGroup()}}    
                                        />
                                    )
                                }
                            </div>
                            {
                                childrenObjections.length === 0 ? (
                                    <div className="w-full flex justify-center items-center" style={{color: 'gray', height: "20vh"}}>
                                        No call recording objections are present in this aggregated objection
                                    </div>
                                ) : (
                                    <TableContainer sx={{ border: "2px solid #F6F6F6" }}>
                                        <Table aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="center"><strong className="text-xl">Objection Summary</strong></TableCell>
                                                    <TableCell align="center"><strong className="text-xl">Context</strong></TableCell>
                                                    <TableCell align="center"><strong className="text-xl">Customer&#39;s Statement</strong></TableCell>
                                                    <TableCell align="center"><strong className="text-xl">IC&#39;s Response</strong></TableCell>
                                                    <TableCell align="center"><strong className="text-xl">Performance</strong></TableCell>
                                                    <TableCell align="center"><strong className="text-xl">Mistake(s)</strong></TableCell>
                                                    {/* <TableCell align="center"><strong className="text-xl">Recording Name</strong></TableCell> */}
                                                    <TableCell align="center"><strong className="text-xl">Positive Feedback</strong></TableCell>
                                                    <TableCell align="center"><strong className="text-xl">IC&#39;s Name</strong></TableCell>
                                                    <TableCell align="center"><strong className="text-xl">Time Range</strong></TableCell>
                                                    <TableCell align="center"><strong className="text-xl">View Objection</strong></TableCell>
                                                    <TableCell align="center"></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>

                                                {childrenObjections.map((objection: any, index: any) => (
                                                    <TableRow
                                                        key={index}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 }, height: "50px", width: "100px !important" }}
                                                    >
                                                        <TableCell component="th" scope="row" align="center">
                                                            {objection.description}
                                                        </TableCell >
                                                        <TableCell align="center">
                                                            {objection.context || "Not Available"}
                                                        </TableCell >
                                                        <TableCell align="center">
                                                            {objection.objection}
                                                        </TableCell >
                                                        <TableCell align="center">{objection.rep_handling || "NA"}</TableCell>
                                                        <TableCell align="center">
                                                            <span
                                                                style={{
                                                                    backgroundColor: `${objection.score == 'Good' ? 'green' : (objection.score == 'Average' ? 'yellow' : (objection.score === 'Bad' ? 'red' : 'transparent'))}`, padding: "5px"
                                                                }}
                                                            >
                                                                {objection.score || "NA"}
                                                            </span>
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            {
                                                                objection.suggestions.length === 0 && "NA"
                                                            }
                                                            {objection.suggestions.map((obj: any, idx: any) => {
                                                                return <div key={idx}>{obj.mistake}</div>
                                                            })}
                                                        </TableCell>
                                                        <TableCell align="center">{objection.positive_feedback || "NA"}</TableCell>
                                                        {/* <TableCell align="center">{objection.call_recording_id.includes('$$') ? objection.call_recording_id.split('$$')?.[2] : objection.call_recording_id}</TableCell> */}
                                                        <TableCell align="center">{objection.rep_username}</TableCell>
                                                        <TableCell align="center">{objection.timestamp}</TableCell>
                                                        <TableCell align="center">
                                                            <>
                                                                <Button 
                                                                    size='small' 
                                                                    variant='outlined'
                                                                    onClick={() => {
                                                                        openCallRecordingModalTrigger(index, objection.call_recording_id, "recording"); 
                                                                        setCallRecordingObjectionTimestamp(objection.timestamp.split(' ')[0])
                                                                    }}
                                                                >
                                                                    View Recording
                                                                </Button>
                                                                <Button 
                                                                    size='small' 
                                                                    variant='outlined'
                                                                    onClick={() => {
                                                                        openCallRecordingModalTrigger(index, objection.call_recording_id, "transcript"); 
                                                                        setCallRecordingObjectionTimestamp(objection.timestamp.split(' ')[0])
                                                                    }}
                                                                    sx={{marginTop: "10px"}}
                                                                >
                                                                    View Transcript
                                                                </Button>
                                                            </>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                )
                            }
                            <Modal
                                open={callRecordingModalIndex !== null}
                                onClose={() => {
                                    setCallRecordingModalIndex(null);
                                    setCallRecordingContent('');
                                    setCallDocType('');
                                    setCallRecordingObjectionTimestamp('');
                                }}
                            >
                                <Box sx={style}>
                                    {
                                        !callRecordingContent ? (
                                            <div className="flex justify-center items-center">
                                                <CircularProgress />
                                            </div>
                                        ) : (
                                            <div style={{display:"flex",flexDirection:"column"}}>
                                                <IconButton onClick={() => {setCallRecordingModalIndex(null);setCallDocType('');setCallRecordingContent('');setCallRecordingObjectionTimestamp('');}} style={{color: "black", fontSize: "25px", position: "absolute", cursor: "pointer", top: "5px", right: "5px", background: "#EEEEEE", borderRadius: "100%", marginBottom:"10px"}}>
                                                    <CancelIcon  
                                                    />
                                                </IconButton>
                                                    {
                                                        callRecordingModalIndex !== null && (
                                                            <TableContainer sx={{ border: "2px solid #F6F6F6", marginBottom: "20px" }}>
                                                                <Table aria-label="simple table">
                                                                    <TableHead>
                                                                        <TableRow>
                                                                            <TableCell align="center"><strong className="text-xl">Objection Summary</strong></TableCell>
                                                                            <TableCell align="center"><strong className="text-xl">Context</strong></TableCell>
                                                                            <TableCell align="center"><strong className="text-xl">Customer&#39;s Statement</strong></TableCell>
                                                                            <TableCell align="center"><strong className="text-xl">IC&#39;s Response</strong></TableCell>
                                                                            <TableCell align="center"><strong className="text-xl">Performance</strong></TableCell>
                                                                            <TableCell align="center"><strong className="text-xl">Mistake(s)</strong></TableCell>
                                                                            <TableCell align="center"><strong className="text-xl">Positive Feedback</strong></TableCell>
                                                                            {/* <TableCell align="center"><strong className="text-xl">Recording Name</strong></TableCell> */}
                                                                            {/* <TableCell align="center"><strong className="text-xl">IC&#39;s Name</strong></TableCell> */}
                                                                            <TableCell align="center"><strong className="text-xl">Time Range</strong></TableCell>
                                                                            <TableCell align="center"></TableCell>
                                                                        </TableRow>
                                                                    </TableHead>
                                                                    <TableBody>
                                                                        <TableRow
                                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 }, height: "50px", width: "100px !important" }}
                                                                        >
                                                                            <TableCell component="th" scope="row" align="center">
                                                                                {childrenObjections[callRecordingModalIndex].description}
                                                                            </TableCell >
                                                                            <TableCell align="center">
                                                                                {childrenObjections[callRecordingModalIndex].context || "Not Available"}
                                                                            </TableCell >
                                                                            <TableCell align="center">
                                                                                {childrenObjections[callRecordingModalIndex].objection}
                                                                            </TableCell >
                                                                            <TableCell align="center">{childrenObjections[callRecordingModalIndex].rep_handling || "NA"}</TableCell>
                                                                            <TableCell align="center">
                                                                                <span
                                                                                    style={{
                                                                                        backgroundColor: `${childrenObjections[callRecordingModalIndex].score == 'Good' ? 'green' : (childrenObjections[callRecordingModalIndex].score == 'Average' ? 'yellow' : (childrenObjections[callRecordingModalIndex].score === 'Bad' ? 'red' : 'transparent'))}`, padding: "5px"
                                                                                    }}
                                                                                >
                                                                                    {childrenObjections[callRecordingModalIndex].score || "NA"}
                                                                                </span>
                                                                            </TableCell>
                                                                            <TableCell align="center">
                                                                                {
                                                                                    childrenObjections[callRecordingModalIndex].suggestions.length === 0 && "NA"
                                                                                }
                                                                                {childrenObjections[callRecordingModalIndex].suggestions.map((obj: any, idx: any) => {
                                                                                    return <div key={idx}>{obj.mistake}</div>
                                                                                })}
                                                                            </TableCell>
                                                                            <TableCell align="center">{childrenObjections[callRecordingModalIndex].positive_feedback || "NA"}</TableCell>
                                                                            {/* <TableCell align="center">{objection.call_recording_id.includes('$$') ? objection.call_recording_id.split('$$')?.[2] : objection.call_recording_id}</TableCell> */}
                                                                            {/* <TableCell align="center">{childrenObjections[callRecordingModalIndex].rep_username}</TableCell> */}
                                                                            <TableCell align="center">{childrenObjections[callRecordingModalIndex].timestamp}</TableCell>
                                                                        </TableRow>
                                                                    </TableBody>
                                                                </Table>
                                                            </TableContainer>
                                                        )
                                                    }
                                                {
                                                    callDocType === "recording" ? (
                                                        <VideoPlayer url={callRecordingContent} sourceType="video/mp4" startTime={timestampToSeconds(callRecordingObjectionTimestamp)} />
                                                    ) : (
                                                        <pre style={{maxHeight: "50vh", overflowY: "scroll", borderRadius: "20px", border: "3px solid #393E46", padding: "20px"}}>
                                                            {/* The call recording url will download a .txt file. I want to display the text in that .txt file on screen without downloading it. */}
                                                            {callRecordingContent}
                                                        </pre>
                                                    )
                                                }
                                            </div>
                                        )
                                    }
                                </Box>
                            </Modal>
                        </div>
                    )}
                </Box>
            </Modal>
        </div>
    );
}
export default ObjectionChart;
