import React, {useEffect} from 'react';
import { CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { REACT_APP_BACKEND_URL, ZOOM_REDIRECT_URI } from '../constants';
import { toast } from 'react-toastify';

export default function ZoomCallback() {
    const navigate = useNavigate()
    useEffect(() => {
        const authorization_code = new URLSearchParams(window.location.search).get("code")
        const headers = {
            "Authorization": `Bearer ${localStorage.getItem('google_access_token')}`
        }
        const data = {
            authorization_code,
            redirect_uri: ZOOM_REDIRECT_URI
        }
        axios.post(`${REACT_APP_BACKEND_URL}/organization/${localStorage.getItem('org_id')}/zoom/integrate`, data, {headers})
        .then((response) => {
            console.log("Zoom integration response", response.data)
            toast.success("Your zoom account has been connected successfully!")
        })
        .catch((error) => {
            console.error(error)
            toast.error("Failed to connect your zoom account")
        })
        .finally(() => {
            navigate('/')
        })
    }, [])
    return (
        <div style={{minHeight: "90vh"}} className="flex justify-center items-center">
            <CircularProgress />
        </div>
    )
}