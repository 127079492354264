import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import scenariosReducer from './scenariosSlice';
import simulationReducer from './simulationSlice';
import productReducer from "./productSlice";
import moduleSlice from "./moduleSlice";
import currentSimulationSlice from "./currentSimulationSlice";

// Create the persist config
const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['scenarios', 'simulation', 'products', 'modules'], // Add other reducers here
};

const rootReducer = combineReducers({
  scenarios: scenariosReducer,
  simulation: simulationReducer,
  products: productReducer,
  modules: moduleSlice,
  currentSimulation: currentSimulationSlice,
  // Add other reducers here
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
});

// Create a persistor instance
export const persistor = persistStore(store);

export default store;
