import React from 'react'


interface Suggestion {
  mistake: string;
  reasoning: string;
  how_to_improve: string;
}

export interface ObjectionData {
  timestamp: string;
  objection: string;
  objection_summary: string;
  rep_handling: string;
  suggestions: Suggestion[];
  positive_feedback: string | null;
  score: "Bad" | "Average" | "Good";
}
interface SingleObjectionProps {
  data: ObjectionData;
}

const SingleObjection : React.FC<SingleObjectionProps> = ({ data }) => {
  const borderColor = data.score === "Bad" ? "red" : data.score === "Good" ? "green" : "yellow";

  return (
    <div style={{width: "50%"}}>
      <div style={{ border: `4px solid ${borderColor}`, padding: '10px', margin: '10px', borderRadius: '10px' }}>
        <p><strong>Objection:</strong> {data.objection}</p>
        <p><strong>How it was handled:</strong> {data.rep_handling || "Could not determine how this objection was handled"}</p>
        {
          data.positive_feedback && (
            <>
              <h3 style={{fontSize: "20px", fontWeight: "600"}}>Positive Feedback:</h3>
              <p><strong>Feedback:</strong> {data.positive_feedback}</p>
            </>
          )
        }
        {
          data?.suggestions?.length > 0 && (
            <>
              <h3 style={{fontSize: "20px", fontWeight: "600"}}>Suggestion(s) for Improvement:</h3>
              <ul className="li-disc">
              {data.suggestions.map((suggestion, index) => (
                <li key={index} className="ml-3">
                  <p><strong>Mistake:</strong> {suggestion.mistake}</p>
                  <p><strong>Improvement:</strong> {suggestion.how_to_improve}</p>
                </li>
              ))}
              </ul>
            </>
          )
        }
      </div>
    </div>
  );
}

export default SingleObjection;